import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-beneficios-segmentacion',
  templateUrl: './beneficios-segmentacion.component.html',
  styleUrls: ['./beneficios-segmentacion.component.scss'],
})
export class BeneficiosSegmentacionComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}

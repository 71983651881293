import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-vitrina-slider-home',
  templateUrl: './vitrina-slider-home.component.html',
  styleUrls: ['./vitrina-slider-home.component.scss'],
})
export class VitrinaSliderHomeComponent implements OnInit {
  @ViewChild('mySlider') slides: IonSlides;
  mySlideOptions = {
    //autoplay: true,
    autoplay: {
      delay: 5000,
    },
    initialSlide: 0,
    slideShadows: true,
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
    spaceBetween: 0,
    speed: 1100,
    pager: true,
    paginationType: 'fraction',
    effect: 'slide',
    dir: 'rtl',
    //delay: 10000,
    slideinterval: 10000,
  };

  numero_slides: string;
  rutaIMG: string;
  XS: string;
  heightVitrina: string;
  heightImgVitrina: string;
  widthImgVitrina: string;
  maxWidthImg: string;

  sliderHomeList: any = [];
  constructor() { }

  ngOnInit() {
    this.inicializarVariables();
    this.getListaHomeSlider();
    // this.slides.startAutoplay();
  }
  inicializarVariables() {
    this.numero_slides = '5';
    this.rutaIMG = 'assets/img/home/slider/';
    this.XS = 'xs-';
    this.heightVitrina = '480px';
    this.heightImgVitrina = '100%';
    this.widthImgVitrina = '100%';
    this.maxWidthImg = '1190px';

  }

  slidePrev() {
    this.slides.slidePrev();
    this.slides.startAutoplay();
  }
  slideNext() {
    this.slides.slideNext();
    this.slides.startAutoplay();
  }
  getListaHomeSlider() {

    const item_sliderHomeList = [
      // vitrinaSlide 1: lanzamiento
      // {
      //   contenidoPrimerTexto_vitrina: '¡Llévate hasta 5,000 Puntos Go por tus compras en el Supermercado!',
      //   mobile_PrimerTexto_vitrina: '¡Llévate hasta 5,000 Puntos Go por tus compras en el Supermercado!',
      //   contenidoSegundoTexto_vitrina: '<br>Por cada compra desde S/150 cada sábado del mes llévate 1,000 Puntos Go con tu Tarjeta de Crédito Banco Ripley Mastercard.<br><br>',
      //   mobile_SegundoTexto_vitrina: '<br>Por cada compra desde S/150 cada sábado del mes llévate 1,000 Puntos Go con tu Tarjeta de Crédito Banco Ripley Mastercard.<br><br>',
      //   hrefBTN1_vitrina: 'https://bancoripley.com.pe/formularios/index.html?plantilla=compra-en-supermercados',
      //   //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
      //   href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
      //   verCondiciones_Mobile:'n',//s/n
      //   contenidoBTN1_vitrina: 'Inscríbete aquí',
      //   hrefBTN2_vitrina: '',
      //   contenidoBTN2_vitrina: '',
      //   imagen_vitrina: 'banner-home-lanzamiento-octubre.png',
      //   taggeo: 'banner-home-lanzamiento-octubre'
      // },
      // // vitrinaSlide 1: Cyber Acumulación
      // {
      //   contenidoPrimerTexto_vitrina: '\n\n\n',
      //   mobile_PrimerTexto_vitrina: '\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n',
      //   contenidoSegundoTexto_vitrina: '<br><br><br>',
      //   mobile_SegundoTexto_vitrina: '<br><br><br>',
      //   hrefBTN1_vitrina: 'https://bancoripley.com.pe/formularios/index.html?plantilla=rpgo-acumulacion-cyber',
      //   //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
      //   href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
      //   verCondiciones_Mobile:'n',//s/n
      //   contenidoBTN1_vitrina: 'Inscríbete aquí',
      //   hrefBTN2_vitrina: '',
      //   contenidoBTN2_vitrina: '',
      //   imagen_vitrina: 'banner-home-cyber-acum-nov.png',
      //   taggeo: 'banner-home-cyber-acum-nov'
      // },
      // // vitrinaSlide 1: Cyber Experiencias
      // {
      //   contenidoPrimerTexto_vitrina: '\n\n\n\n',
      //   mobile_PrimerTexto_vitrina: '',
      //   contenidoSegundoTexto_vitrina: '<br><br><br>',
      //   mobile_SegundoTexto_vitrina: '<br><br><br>',
      //   hrefBTN1_vitrina: 'https://bancaenlinea.bancoripley.com.pe/home/ripley-points',
      //   //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
      //   href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
      //   verCondiciones_Mobile:'n',//s/n
      //   contenidoBTN1_vitrina: 'Canjea aquí',
      //   hrefBTN2_vitrina: '',
      //   contenidoBTN2_vitrina: '',
      //   imagen_vitrina: 'banner-home-cyber-expe-nov.png',
      //   taggeo: 'banner-home-cyber-expe-nov'
      // },
      // vitrinaSlide 2: Acumulación
      {
        contenidoPrimerTexto_vitrina: '¡Llévate hasta 5,000 Puntos Go por tus compras en el Supermercado!',
        mobile_PrimerTexto_vitrina: '¡Llévate hasta 5,000 Puntos Go por tus compras en el Supermercado!',
        contenidoSegundoTexto_vitrina: '<br>Por cada compra desde S/150 cada sábado del mes llévate 1,000 Puntos Go con tu Tarjeta de Crédito Banco Ripley Mastercard.<br><br>',
        mobile_SegundoTexto_vitrina: '<br>Por cada compra desde S/150 cada sábado del mes llévate 1,000 Puntos Go con tu Tarjeta de Crédito Banco Ripley Mastercard.<br><br>',
        hrefBTN1_vitrina: 'https://bancoripley.com.pe/formularios/index.html?plantilla=compra-en-supermercados',
        //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
        href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
        verCondiciones_Mobile:'n',//s/n
        contenidoBTN1_vitrina: 'Inscríbete aquí',
        hrefBTN2_vitrina: '',
        contenidoBTN2_vitrina: '',
        imagen_vitrina: 'banner-home-acumulacion-nov.png',
        taggeo: 'banner-home-acumulacion-nov'
      },

      // vitrinaSlide 3: Super Canjes
      {        
        contenidoPrimerTexto_vitrina: '¡Ya llega la Navidad! Decora tu hogar con las mejores categorías',
        mobile_PrimerTexto_vitrina: '¡Ya llega la Navidad! Decora tu hogar con las mejores categorías',
        contenidoSegundoTexto_vitrina: '<br>Canjea tus Puntos Go en Tiendas Ripley por productos de Navidad, Cama, Baño, menaje y mucho más.<br><br>',
        mobile_SegundoTexto_vitrina: '<br>Canjea tus Puntos Go en Tiendas Ripley por productos de Navidad, Cama, Baño, menaje y mucho más.<br><br>',
        hrefBTN1_vitrina: 'https://firebasestorage.googleapis.com/v0/b/hbk-prd.appspot.com/o/RPGO%2FCATALOGOS%2FpdfCatalogoSC?alt=media',
        //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
        href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
        verCondiciones_Mobile:'n',//s/n
        contenidoBTN1_vitrina: 'Mira el catálogo',
        hrefBTN2_vitrina: '',
        contenidoBTN2_vitrina: '',
        imagen_vitrina: 'banner-home-supercanje-nov.png',
        taggeo: 'banner-home-supercanje-nov'
      },
      
      // vitrinaSlide 4: Experiencias
      {
        contenidoPrimerTexto_vitrina: '¡Adelanta la Navidad con los mejores canjes de experiencias!<br>',
        mobile_PrimerTexto_vitrina: '¡Adelanta la Navidad con los mejores canjes de experiencias!',
        contenidoSegundoTexto_vitrina: '<br>Canjea vales para fast food, entretenimiento, regalos y más desde tu App Banco Ripley.<br><br>',
        mobile_SegundoTexto_vitrina: '<br>Canjea vales para fast food, entretenimiento, regalos y más desde tu App Banco Ripley.<br><br>',
        hrefBTN1_vitrina: 'https://bancaenlinea.bancoripley.com.pe/home/ripley-points',
        //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
        href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
        verCondiciones_Mobile:'n',//s/n
        contenidoBTN1_vitrina: 'Canjea aquí',
        hrefBTN2_vitrina: '',
        contenidoBTN2_vitrina: '',
        imagen_vitrina: 'banner-home-experiencias-nov.png',
        taggeo: 'banner-home-experiencias-nov'
      },

      // vitrinaSlide 5: Gift Cards
      {
        contenidoPrimerTexto_vitrina: '¡Canjea una Gift Card y úsala en Tienda Ripley y la Ripley App!<br>',
        mobile_PrimerTexto_vitrina: '¡Canjea una Gift Card y úsala en Tienda Ripley y la Ripley App!<br>',
        contenidoSegundoTexto_vitrina: '<br>Hazlo desde tu App Banco Ripley y a partir de 3,000 Ripley Puntos Go.<br><br>',
        mobile_SegundoTexto_vitrina: '<br>Hazlo desde tu App Banco Ripley y a partir de 3,000 Ripley Puntos Go.<br><br>',
        hrefBTN1_vitrina: 'https://bancaenlinea.bancoripley.com.pe/home/ripley-points',
        //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
        href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
        verCondiciones_Mobile:'n',//s/n
        contenidoBTN1_vitrina: 'Canjea aquí',
        hrefBTN2_vitrina: '',
        contenidoBTN2_vitrina: '',
        imagen_vitrina: 'banner-home-giftcar-nov.png',
        taggeo: 'banner-home-giftcar-nov'
      },
      
      // // vitrinaSlide 6: LATAM
      // {
      //   contenidoPrimerTexto_vitrina: '¡Del 28 de Octubre al 03 de Noviembre!',
      //   mobile_PrimerTexto_vitrina: '¡Del 28 de Octubre al 03 de Noviembre!',
      //   contenidoSegundoTexto_vitrina: '<br>Canjea tus Puntos Go con 40% dscto. por millas LATAM PASS.<br><br>',
      //   mobile_SegundoTexto_vitrina: '<br>Canjea tus Puntos Go con 40% dscto. por millas LATAM PASS.<br><br>',
      //   hrefBTN1_vitrina: 'canje-latam-pass', 
      //   //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
      //   href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
      //   verCondiciones_Mobile:'n',//s/n
      //   contenidoBTN1_vitrina: 'Quiero canjear',
      //   hrefBTN2_vitrina: '',
      //   contenidoBTN2_vitrina: '',
      //   imagen_vitrina: 'banner-home-latam-oct28.png',
      //   taggeo: 'banner-home-latam-oct28'
      // },

      // vitrinaSlide 6: Lo quiero, Lo Canjeo
      {
        contenidoPrimerTexto_vitrina: 'Con mis \nRipley Puntos GO, \n¡lo quiero, lo canjeo!',
        mobile_PrimerTexto_vitrina: '',
        contenidoSegundoTexto_vitrina: '<br>Acumula Puntos Go con tu Tarjeta \nBanco Ripley y canjéalos \npor lo que más quieras.<br><br>',
        mobile_SegundoTexto_vitrina: '<br><br><br>',
        hrefBTN1_vitrina: 'canje-latam-pass', 
        //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
        href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
        verCondiciones_Mobile:'n',//s/n
        contenidoBTN1_vitrina: '',
        hrefBTN2_vitrina: '',
        contenidoBTN2_vitrina: '',
        imagen_vitrina: 'banner-home-lanzamiento-nov.png',
        taggeo: 'banner-home-lanzamiento-nov'
      },

      // vitrinaSlide 6: RPass
      {
        contenidoPrimerTexto_vitrina: '¡Ahora más fácil y rápido!',
        mobile_PrimerTexto_vitrina: '¡Ahora más fácil y rápido!',
        contenidoSegundoTexto_vitrina: '<br>Autoriza el canje de tus Ripley Puntos Go directo desde tu App.<br><br>',
        mobile_SegundoTexto_vitrina: '<br>Autoriza el canje de tus Ripley Puntos Go directo desde tu App.<br><br>',
        hrefBTN1_vitrina: 'https://www.bancoripley.com.pe/r-pass/index.html',
        //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
        href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
        verCondiciones_Mobile:'n',//s/n
        contenidoBTN1_vitrina: 'Actívalo aquí',
        hrefBTN2_vitrina: '',
        contenidoBTN2_vitrina: '',
        imagen_vitrina: 'banner-home-rpass-oct.png',
        taggeo: 'banner-home-rpass-oct'
      },
      
    ];

    this.sliderHomeList = item_sliderHomeList;

  }
}

import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';
import { CanjeMillasService } from '@services/canjes/canje-millas.service';
import { DeviceService } from '@services/device/device.service';

@Component({
  selector: 'app-confirma-paquete',
  templateUrl: './confirma-paquete.component.html',
  styleUrls: ['./confirma-paquete.component.scss'],
})
export class ConfirmaPaqueteComponent implements OnInit {
  //---parameter from paquete latam

  state: any;
  isValid = true;
  activarCanjear = false;
  codigoErroneo = false;
  flagNumeroIntentos = false;
  flagNumeroCanjeDoble = false;
  flagLimiteIntentosPorHora = false;
  flagContadorSegundo = false;
  tituloModal = '';
  cuerpoModal = '';
  mensajetextoBloqueado = '';
  mensajetextoIntentos = '';
  identidadForm: FormGroup;
  numeroIntentosFallidos = 0;
  maxnumeroIntentosFallidos = 3;
  tipoTimer: string;
  public intervalTimer: NodeJS.Timeout;
  pathCanjeLatamPassDNI = 'canje-latam-pass';
  //flagModaGenerico = false;
  showLoader = false;
  counter: { sec: number };
  intervalId: any;
  mensajeTooltip: string;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private canjeMillasService: CanjeMillasService,
    private router: Router,
    private deviceService: DeviceService,
    @Inject(DOCUMENT) private document: Document,
  ) {

  }

  ngOnInit() {
    if (history.state.ClientData === undefined) {
      this.document.location.href = '/' + this.pathCanjeLatamPassDNI;
    }
    else {
      this.mensajeTooltip = 'Ups, realizaste el máximo de intentos. Tu cuenta ha sido bloqueada por 24 hrs. Para más consultas puedes llamar a nuestro Fonocompras 6115959 opción 5 o acércate a una de nuestras tiendas Ripley a nivel nacional.';
      this.state = {
        ClientData: history.state.ClientData,
        DataOTP: { countNumber: 0, maxNumberAttemps: 0 }
      }
      this.counter = { sec: 0 }
      this.createForm();
      this.cargarOTP();
    }
  }
  createForm() {
    this.identidadForm = this.formBuilder.group({
      codigoSMS: [{ value: null, disabled: false }, [Validators.required]],
    });
  }
  onKeyup() {
    this.codigoErroneo = false;
    const codigoSMS = this.identidadForm.get('codigoSMS').value;
    if (codigoSMS !== '') {
      this.activarCanjear = true;

    } else {
      this.activarCanjear = false;
    }
  }

  startTimer() {
    this.counter = { sec: 0 }
    this.intervalId = setInterval(() => {
      if (this.counter.sec - 1 == -1) {
        this.counter.sec = 59
      }
      else
        this.counter.sec -= 1
      if (this.counter.sec == 0) {
        this.showLoader = false;
        clearInterval(this.intervalId)
        if (this.tipoTimer === 'ini') {
          this.flagContadorSegundo = true;
        }
        else {
          this.flagContadorSegundo = false;
        }
      }
    }, 1000)

  }
  stopTimer() {
    clearInterval(this.intervalId)
    this.counter.sec = 0;
  }
  onCanjear() {
    this.showLoader = true;
    const codigoSMS = this.identidadForm.get('codigoSMS').value;
    //--en una hora se debe permitir máximo 3 canjes entre correctos y fallidos, de lo contrario se bloquea 1 hora
    let numeroIntentosPorHora = this.state.DataOTP.countNumber;//4
    let maxNumeroIntentosPorHora = this.state.DataOTP.maxNumberAttemps;//3
    //Caso 3 (bloqueo 1 hora)
    if (Number(numeroIntentosPorHora) <= Number(maxNumeroIntentosPorHora)) {
      this.flagLimiteIntentosPorHora = false;
      //--Caso 1 (bloqueo 24 horas)
      if (this.numeroIntentosFallidos < this.maxnumeroIntentosFallidos) {
        this.flagNumeroIntentos = false;
        //Contador de 60 segundos
        if (this.counter.sec > 0) {
          this.stopTimer();
          this.flagContadorSegundo = false;
          this.identidadForm.get('codigoSMS').enable();

          this.authService.getAuthentication0().subscribe(
            async (response: any) => {
              const status = response.status;
              const bodyResponse = response.body;
              if (status) {
                const token = bodyResponse.access_token;
                //console.log('getAuthentication1:ok');
                const tokenCanje = {
                  accessToken: token,
                  expiresIn: 120,
                  fbSession: this.state.ClientData.sessionId,
                  refreshExpiresIn: 1800,
                  refreshToken: '',
                  userId: this.state.ClientData.userId
                }
                const body = {
                  sessionId: this.state.ClientData.sessionId,
                  pin: codigoSMS,
                  processType: this.state.ClientData.processType,
                  monitorInput: {
                    url : window.location.host,
                    device : this.deviceService.getWildField1(),
                    firmware : this.deviceService.getPlatformName(),
                    docName : window.location.pathname,
                    mobileNumber : '',
                    ip : (await (this.deviceService.getIP())).ip,
                    navegadorIdenticator: this.deviceService.getNavegadorIdentifier(),
                    deviceVersion: this.deviceService.getDeviceVersion(),
                    deviceId: this.deviceService.getDeviceId(),
                    deviceName: this.deviceService.getDeviceManufacturer(),
                    deviceModel: this.deviceService.getDevice(),
                    imei: this.deviceService.getIMEI(),
                    country: await this.deviceService.getCountry(),
                    city: await this.deviceService.getCity(),
                    isp: await this.deviceService.getISP(),
                    navegadorVersion: this.deviceService.getNavegadorVersion(),
                    navegadorVersionMajor: this.deviceService.getNavegadorVersionMajor(),
                    engineName: this.deviceService.getEngineName(),
                    engineVersion: this.deviceService.getEngineVersion(),
                    language: navigator.language,
                    architecture: this.deviceService.getArchitecture()
                  },
                  //--redeem
                  documentType: this.state.ClientData.documentType,
                  documentNumber: this.state.ClientData.documentNumber,
                  userId: this.state.ClientData.userId,
                  memberId: this.state.ClientData.memberId,
                  nOfPointsAvailable: this.state.ClientData.nOfPointsAvailable,
                  packagePoints: this.state.ClientData.packagePoints,
                  miles: this.state.ClientData.miles,
                  latamcode: this.state.ClientData.latamcode,
                  latamName: this.state.ClientData.latamName,
                  sku: this.state.ClientData.sku,
                  token: tokenCanje,
                  origin: this.state.ClientData.origin,
                  productId: this.state.ClientData.productId,
                  backendID: this.state.ClientData.backendID,
                  fullName: this.state.ClientData.fullName,
                  captcha: ''
                }
                await this.canjeMillasService.validateOTP_Redeem(body, token)
                  .then((data) => {
                    // console.log('validateOTP_Redeem:');
                    // console.log(data);
                    if (data.code === 0) {
                      //console.log('data.code ok');
                      //--validación correcta validateOTP
                      this.codigoErroneo = false;
                      this.numeroIntentosFallidos = data.countNumber;
                      this.maxnumeroIntentosFallidos = data.maxNumberAttemps;
                      //this.canjearPaquete(data);
                      //---validación correcta redeem
                      this.codigoErroneo = false;
                      this.flagContadorSegundo = false;
                      const paramExito = {
                        imagen: this.state.ClientData.imagen,
                        titulo: this.state.ClientData.title
                      }
                      this.stopTimer();
                      this.canjeMillasService.setPackExito(paramExito);
                      this.router.navigateByUrl('/paquete-exitoso');
                      this.showLoader = false;
                    } else {
                      //validateOTP Error
                      //Code = 710, Message = "El código ha expirado."
                      if (data.code === 712 || data.code === 711){
                        //caso 1 (intentos fallido x tipeo)
                        this.codigoErroneo = true;
                        this.numeroIntentosFallidos = data.countNumber;
                        this.maxnumeroIntentosFallidos = data.maxNumberAttemps;
                        this.showLoader = false;
                        this.stopTimer();
                        return;
                      }
                      if (data.code === 630){
                        //caso 1 (intentos fallido x tipeo)
                        //Has alcanzado el máximo de intentos
                        this.flagNumeroIntentos = true
                        this.identidadForm.get('codigoSMS').setValue("");
                        this.identidadForm.get('codigoSMS').disable();
                        this.tituloModal = '¡Canje bloqueado! Espera 24 horas.';
                        this.cuerpoModal = 'Ups, realizaste el máximo de intentos. Tu cuenta ha sido bloqueada ' +
                          'por 24 hrs. También puedes canjear llamando al nuestro fonocompras 6115959 opción 5 o ' +
                          'acércarte a una de nuestras Tiendas Ripley a nivel nacional.';
                        this.mensajetextoBloqueado = '¡Canje bloqueado! Espera 24 horas.';
                        this.mensajetextoIntentos = 'Ups, realizaste el máximo de intentos ';
                        this.tipoTimer = 'notini';
                        this.showLoader = false;
                        this.stopTimer();
                        document.getElementById('btnModal').click();
                        return;
                      }
                      if (data.code === 708 || data.code === 720 ){
                        this.codigoErroneo = true;
                        this.showLoader = false;
                        this.stopTimer();
                        return;
                      }
                      else {//Redeem Error
                        if (data.status === 'daily-limit') {  //Caso 2 (un caje de un paquete especifico diario)
                          this.flagNumeroCanjeDoble = true;
                          this.codigoErroneo = false;
                          this.flagContadorSegundo = false;
                          this.identidadForm.get('codigoSMS').setValue("");
                          this.identidadForm.get('codigoSMS').disable();
                          this.tituloModal = 'Has agotado el número de canjes diarios para este paquete';
                          this.cuerpoModal = 'Recuerda que solo es posible realizar un canje diario por paquete.'
                          this.mensajetextoBloqueado = '¡Has agotado el límite de canjes diarios!';
                          this.mensajetextoIntentos = 'Espera 24 hrs para canjear nuevamente';
                          this.tipoTimer = 'notini';
                          this.showLoader = false;
                          this.stopTimer();
                          document.getElementById('btnModal').click();
                          return;
                        }
                        if (data.status === 'limit-exceeded') {//caso 4 (paquetes x año)
                          this.flagNumeroCanjeDoble = true;
                          this.codigoErroneo = false;
                          this.flagContadorSegundo = false;
                          this.identidadForm.get('codigoSMS').setValue("");
                          this.identidadForm.get('codigoSMS').disable();
                          this.tituloModal = 'Has superado tu límite de canjes anuales';
                          this.cuerpoModal = 'Recuerda que hay un límite de canjes anuales para este paquete.'
                          this.mensajetextoBloqueado = '¡Has superado el límite de canjes anuales!';
                          this.mensajetextoIntentos = 'Selecciona otro paquete';
                          this.tipoTimer = 'notini';
                          this.showLoader = false;
                          this.stopTimer();
                          document.getElementById('btnModal').click();
                          return;
                        } else {
                          //Otros casos de error
                          //data.code in ('5','6','0','7','8','9')
                            this.codigoErroneo = false;
                            this.flagContadorSegundo = false;
                            this.identidadForm.get('codigoSMS').setValue("");
                            this.identidadForm.get('codigoSMS').disable();
                            this.cuerpoModal = data.message;
                            this.tipoTimer = 'notini';
                            this.showLoader = false;
                            this.stopTimer();
                            document.getElementById('btnModal').click();
                        }
                      }////Redeem Error
                    }//(data.code === 0)
                  })
                  .catch((error) => {
                    console.log('No sé pudo realizar el canje 1');
                    console.log(error);
                    this.cuerpoModal = 'No sé pudo realizar el canje'
                    document.getElementById('btnModal').click();
                    this.showLoader = false;
                    this.stopTimer();
                  });

              }

            },
            (error) => {
              console.log('No sé pudo realizar el canje 2');
              this.cuerpoModal = 'No sé pudo realizar el canje'
              document.getElementById('btnModal').click();
              this.showLoader = false;
              this.stopTimer();
            }
          );

        } else {
          this.flagContadorSegundo = false;
          this.codigoErroneo = false;
          this.showLoader = false;
        }//this.counter.sec
      } else {
        this.flagNumeroIntentos = true
        this.identidadForm.get('codigoSMS').setValue("");
        this.identidadForm.get('codigoSMS').disable();
        this.tituloModal = '¡Canje bloqueado! Espera 24 horas.';
        this.cuerpoModal = 'Ups, realizaste el máximo de intentos. Tu cuenta ha sido bloqueada ' +
          'por 24 hrs. También puedes canjear llamando al nuestro fonocompras 6115959 opción 5 o ' +
          'acércarte a una de nuestras Tiendas Ripley a nivel nacional.';
        this.mensajetextoBloqueado = '¡Canje bloqueado! Espera 24 horas.';
        this.mensajetextoIntentos = 'Ups, realizaste el máximo de intentos ';
        this.tipoTimer = 'notini';
        this.showLoader = false;
        this.stopTimer();
        document.getElementById('btnModal').click();
      }//numeroIntentosFallidos
    } else {
      this.flagLimiteIntentosPorHora = true;
      this.identidadForm.get('codigoSMS').setValue("");
      this.identidadForm.get('codigoSMS').disable();
      this.tituloModal = '';
      this.cuerpoModal = 'Has llegado al límite de intentos.\nEspera 24 horas para canjear \nnuevamente.'
      this.mensajetextoBloqueado = '¡Has agotado el límite de intentos!';
      this.mensajetextoIntentos = 'Espera 24 hrs para canjear nuevamente';
      this.tipoTimer = 'notini';
      this.showLoader = false;
      this.stopTimer();
      document.getElementById('btnModal').click();
    }//numeroIntentosPorHora
  }
  async enviarCodigoNuevo() {
    //console.log('enviar código nuevo');
    const paramSendOTP = {
      sessionId: this.state.ClientData.sessionId,
      processType: this.state.ClientData.processType,
      monitorInput: {
        url : window.location.host,
        device : this.deviceService.getWildField1(),
        firmware : this.deviceService.getPlatformName(),
        docName : window.location.pathname,
        mobileNumber : '',
        ip : (await (this.deviceService.getIP())).ip,
        navegadorIdenticator: this.deviceService.getNavegadorIdentifier(),
        deviceVersion: this.deviceService.getDeviceVersion(),
        deviceId: this.deviceService.getDeviceId(),
        deviceName: this.deviceService.getDeviceManufacturer(),
        deviceModel: this.deviceService.getDevice(),
        imei: this.deviceService.getIMEI(),
        country: await this.deviceService.getCountry(),
        city: await this.deviceService.getCity(),
        isp: await this.deviceService.getISP(),
        navegadorVersion: this.deviceService.getNavegadorVersion(),
        navegadorVersionMajor: this.deviceService.getNavegadorVersionMajor(),
        engineName: this.deviceService.getEngineName(),
        engineVersion: this.deviceService.getEngineVersion(),
        language: navigator.language,
        architecture: this.deviceService.getArchitecture(),
        productMillas: this.state.ClientData.productId,
        backendMillas: this.state.ClientData.backendID,
        memberMillas: this.state.ClientData.memberId,
        nOfPointsAvailable: this.state.ClientData.nOfPointsAvailable,
        packagePoints: this.state.ClientData.packagePoints,
        miles: this.state.ClientData.miles
      }
    }
    this.identidadForm.get('codigoSMS').setValue("");
    this.activarCanjear = false;
    this.codigoErroneo = false;
    this.flagNumeroIntentos = false;
    this.flagNumeroCanjeDoble = false;
    this.flagLimiteIntentosPorHora = false;
    this.flagContadorSegundo = false;

    this.authService.getAuthentication0().subscribe(
      (response: any) => {
        //console.log(response);
        const status = response.status;
        const bodyResponse = response.body;
        if (status) {
          const token = bodyResponse.access_token;
          //console.log('getAuthentication0:ok');

          this.canjeMillasService.sendOTP(paramSendOTP, token)
            .then((data) => {
              //console.log('dataSendOTP:');
              //console.log(data);
              if (data.code === 1) {
                this.state.DataOTP.countNumber = data.countNumber;//caso 3 (intentos durante una hora)
                this.state.DataOTP.maxNumberAttemps = data.maxNumberAttemps;//caso 3 (intentos durante una hora)
                this.tituloModal = 'Confirmación de Código';
                this.cuerpoModal = 'Su código se envió correctamente, verifique su celular'
                this.tipoTimer = 'ini';
                this.startTimer();
                document.getElementById('btnModal').click();
              } else {
                if (Number(this.state.DataOTP.countNumber) === Number(this.state.DataOTP.maxNumberAttemps)) {//caso 3
                  this.flagLimiteIntentosPorHora = true;
                  this.identidadForm.get('codigoSMS').setValue("");
                  this.identidadForm.get('codigoSMS').disable();
                  this.tituloModal = '';
                  this.cuerpoModal = 'Has llegado al límite de intentos.\nEspera 24 horas para canjear \nnuevamente.'
                  this.mensajetextoBloqueado = '¡Has agotado el límite de intentos!';
                  this.mensajetextoIntentos = 'Espera 24 hrs para canjear nuevamente';
                  this.tipoTimer = 'notini';
                  this.showLoader = false;
                  this.stopTimer();
                  document.getElementById('btnModal').click();
                } else {
                  this.codigoErroneo = false;
                  this.tituloModal = '';
                  this.cuerpoModal = data.message;
                  this.stopTimer();
                  document.getElementById('btnModal').click();
                }
              }

            })
            .catch(() => {
              console.log('No sé pudo realizar el canje 3');
              this.cuerpoModal = 'No sé pudo realizar el canje'
              document.getElementById('btnModal').click();
            });

        }

      },
      (error) => {
        console.log('No sé pudo realizar el canje 4');
        this.cuerpoModal = 'No sé pudo realizar el canje'
        document.getElementById('btnModal').click();

      }
    );

  }
  async cargarOTP() {
    const paramSendOTP = {
      sessionId: this.state.ClientData.sessionId,
      processType: this.state.ClientData.processType,
      monitorInput: {
        url : window.location.host,
        device : this.deviceService.getWildField1(),
        firmware : this.deviceService.getPlatformName(),
        docName : window.location.pathname,
        mobileNumber : '',
        ip : (await (this.deviceService.getIP())).ip,
        navegadorIdenticator: this.deviceService.getNavegadorIdentifier(),
        deviceVersion: this.deviceService.getDeviceVersion(),
        deviceId: this.deviceService.getDeviceId(),
        deviceName: this.deviceService.getDeviceManufacturer(),
        deviceModel: this.deviceService.getDevice(),
        imei: this.deviceService.getIMEI(),
        country: await this.deviceService.getCountry(),
        city: await this.deviceService.getCity(),
        isp: await this.deviceService.getISP(),
        navegadorVersion: this.deviceService.getNavegadorVersion(),
        navegadorVersionMajor: this.deviceService.getNavegadorVersionMajor(),
        engineName: this.deviceService.getEngineName(),
        engineVersion: this.deviceService.getEngineVersion(),
        language: navigator.language,
        architecture: this.deviceService.getArchitecture(),
        productMillas: this.state.ClientData.productId,
        backendMillas: this.state.ClientData.backendID,
        memberMillas: this.state.ClientData.memberId,
        nOfPointsAvailable: this.state.ClientData.nOfPointsAvailable,
        packagePoints: this.state.ClientData.packagePoints,
        miles: this.state.ClientData.miles
      }
    }
    this.authService.getAuthentication0().subscribe(
      (response: any) => {
        //console.log(response);
        const status = response.status;
        const bodyResponse = response.body;
        if (status) {
          const token = bodyResponse.access_token;
          this.canjeMillasService.sendOTP(paramSendOTP, token)
            .then((data) => {
              if (data.code === 1) {
                //console.log('envió sendOtp ok');
                this.tipoTimer = 'ini';
                this.startTimer();
                this.state.DataOTP.countNumber = data.countNumber;//caso 3 (intentos durante una hora)
                this.state.DataOTP.maxNumberAttemps = data.maxNumberAttemps;//caso 3 (intentos durante una hora)
              } else {
                if (Number(this.state.DataOTP.countNumber) === Number(this.state.DataOTP.maxNumberAttemps)) {
                  this.flagLimiteIntentosPorHora = true;
                  this.identidadForm.get('codigoSMS').setValue("");
                  this.identidadForm.get('codigoSMS').disable();
                  this.tituloModal = '';
                  this.cuerpoModal = 'Has llegado al límite de intentos.\nEspera 24 horas para canjear \nnuevamente.'
                  this.mensajetextoBloqueado = '¡Has agotado el límite de intentos!';
                  this.mensajetextoIntentos = 'Espera 24 hrs para canjear nuevamente';
                  this.tipoTimer = 'notini';
                  this.showLoader = false;
                  this.stopTimer();
                  document.getElementById('btnModal').click();
                } else {
                  this.codigoErroneo = false;
                  this.tituloModal = '';
                  this.cuerpoModal = data.message;
                  this.stopTimer();
                  document.getElementById('btnModal').click();
                }
              }
            })
            .catch(() => {
              console.log('No sé pudo realizar el canje 6');
              this.cuerpoModal = 'No sé pudo realizar el canje'
              document.getElementById('btnModal').click();
            });

        }

      },
      (error) => {
        console.log('No sé pudo realizar el canje 7');
        this.cuerpoModal = 'No sé pudo realizar el canje'
        document.getElementById('btnModal').click();

      }
    );


  }


  keyPressNumbers(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  onVolverInicio() {
    this.document.location.href = '/' + this.pathCanjeLatamPassDNI;
  }

}

import { PRETTY_RIPLEY_PHONE } from './misc.constants';

export const PRODUCT_NAMES = {
  '001': {
    name: 'SEF',
    prettyName: 'Préstamo Súper Efectivo'
  },
  '005': {
    name: 'EFEX',
    prettyName: 'Efectivo Express'
  },
  '007': {
    name: 'CDD',
    prettyName: 'Consolidación de deuda'
  }
};

export const BUTTON_TEXT = {
  '001': {
    name: 'SEF',
    text: 'Ver detalle',
    ripleyPhone: ''
  },
  '005': {
    name: 'EFEX',
    text: 'Acéptalo aquí',
    ripleyPhone: ''
  },
  '007': {
    name: 'CDD',
    text: 'Solicítalo al ',
    ripleyPhone: PRETTY_RIPLEY_PHONE
  }
};


export const BUTTON_TEXT_MOBILE = {
  '001': {
    name: 'SEF',
    text: '¡Solicítalo aquí!'
  },
  '005': {
    name: 'EFEX',
    text: 'Acéptalo aquí'
  },
  '007': {
    name: 'CDD',
    text: '¡Solicítalo aquí!'
  }
};

export const PRODUCT_SEF = '001';

export const PRODUCT_EFEX = '005';

export const PRODUCT_CDD = '007';

export const OFFERS_LOAN = 'OFFERS_LOAN';

export const LEAD_EXECUTION_CODE = 'LEAD_EXECUTION_CODE';

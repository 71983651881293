import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPromotionRequest, IPromotionResponse } from 'src/app/common/interfaces/promotion.interface';
import { CONTACT_DETAIL_MOCK } from 'src/app/common/mocks/account.mocks';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/services/http/http.service';


@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  headers = new HttpHeaders()
    .set('Content-Type', 'application/x-www-form-urlencoded;');

  constructor(
    private http: HttpService
  ) { }

  public postRegisterPromociones(params: IPromotionRequest, token: string): Promise<IPromotionResponse> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json;')
      .set('Authorization', `Bearer ${token}`);

    // let data = JSON.stringify(params);
    // console.log(data);

    const url = environment.BASE_BACKEND + '/clientwebapi/api/promotion/registerpromotion';
    return this.http.post<IPromotionResponse>(url, params, CONTACT_DETAIL_MOCK, headers).toPromise();
  }

}

// tslint:disable:no-magic-numbers
export const NEW_DEVICE_MOCK = {
  success: [2000],
  failures: [
    {
      code: 200,
      errorMessage: 'Error al crear el dispositivo'
    }
  ]
};
export const JSONIP_MOCK = {
  success: [
  {
    ip: '200.104.70.83',
    'geo-ip': 'https://getjsonip.com/#plus',
    'API Help': 'https://getjsonip.com/#docs'
  }
],
  failures: [
    {
      code: 200,
      errorMessage: ''
    }
  ]
};
export const IDENTIFIER_MOCK = {
  success: [
    1
  ],
  failures: [
    {
      code: 200,
      errorMessage: ''
    }
  ]
};

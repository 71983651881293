import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AccountManager } from 'src/app/common/interfaces/account-manager.interface';

@Injectable()
export class AccountManagerService {
  private readonly ACCOUNT_TYPE = 'com.ripley.banco.peru';
  private readonly ACCOUNT_MANAGER_GROUP_ID = 'T59XW9LH9H.' + this.ACCOUNT_TYPE;
  private accountManagerPlatform: Promise<AccountManager>;

  constructor(
    private platform: Platform,
  ) {
    if (this.platform.is('cordova')) {
      this.accountManagerPlatform = this.platform.ready().then(() => {
        const accountManager = window['AccountManager'];
        accountManager['initWithKey']('bancoripleyapp');
        return accountManager;
      });
    }
  }

  get accountManager() {
    return {
      getUserDNI: () => {
        return this.accountManagerPlatform.then((accountManager) => new Promise<string>((res) => {
          accountManager.getUserAccount(
            this.ACCOUNT_TYPE,
            this.ACCOUNT_MANAGER_GROUP_ID,
            'dni',
            ({dni}) => res(dni),
            () => res('')
          );
        }));
      },
      registerAccount: (username: string, password: string, userData: object = {}) => {
        return this.accountManagerPlatform.then((accountManager) => new Promise((res) => {
          accountManager.registerAccount(
            username,
            password,
            this.ACCOUNT_TYPE,
            this.ACCOUNT_MANAGER_GROUP_ID,
            userData,
            (...args) => res(args),
            () => res(null)
          );
        }));
      },
      unregister: () => {
        return this.accountManagerPlatform.then((accountManager) => new Promise((res) => {
          accountManager.removeAccount(
            this.ACCOUNT_TYPE,
            (...args) => res(args),
            () => res(null)
          );
        }));
      },
      getUserData: (key: string): any => {
        return this.accountManagerPlatform.then((accountManager) => new Promise((res) => {
          accountManager.getDataFromKey(
            this.ACCOUNT_TYPE,
            this.ACCOUNT_MANAGER_GROUP_ID,
            key,
            (...args) => res(args),
            () => res('')
          );
        }));
      }
    };
  }
}

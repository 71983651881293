import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comoacumular-ripleypuntos',
  templateUrl: './comoacumular-ripleypuntos.component.html',
  styleUrls: ['./comoacumular-ripleypuntos.component.scss'],
})
export class ComoacumularRipleypuntosComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}

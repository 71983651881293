import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-vitrina',
  templateUrl: './vitrina.component.html',
  styleUrls: ['./vitrina.component.scss'],
})
export class VitrinaComponent implements OnInit {
  //textoBanner = '¡Descuento de 60% para\nclientes Gold, 50% para\nclientes Silver y Plus!';
  @ViewChild('mySlider') slides: IonSlides;
  mySlideOptions = {
    //autoplay: true,
    autoplay: {
      delay: 5000,
    },
    initialSlide: 0,
    slideShadows: true,
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
    spaceBetween: 0,
    speed: 1100,
    pager: true,
    paginationType: 'fraction',
    effect: 'slide',
    dir: 'rtl',
    //delay: 10000,
    slideinterval: 10000,
  };
  rutaIMG = 'assets/img/categorias/slider/';  
  XS = 'xs-';
  sliderCategoriaList: any = [];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.getListCategoriaSlider();
    this.activatedRoute.fragment.subscribe(res => {
      if (res != null) {
        this.JumpTo(res);
      }
    });
  }

  JumpTo(section) {
    const timeout = 500;
    setTimeout(() => {
      // document.getElementById(section).scrollIntoView({behavior: 'smooth'});
      document.getElementById(section).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      // document.getElementById(section).focus();
      // const element = document.getElementById(section);
      // const offset = 45;
      // const bodyRect = document.body.getBoundingClientRect().top;
      // const elementRect = element.getBoundingClientRect().top;
      // const elementPosition = elementRect - bodyRect;
      // const offsetPosition = elementPosition - offset;
      // console.log(bodyRect);
      // console.log(elementRect);
      // window.scrollTo({
      //   top: offsetPosition,
      //   behavior: 'smooth'
      // });

    }, timeout);
  }

  onSectionBene() {
    window.location.hash = '';
    window.location.hash = "BeneficiosCategorias";

  }
  getListCategoriaSlider(){
    const item_sliderCategoriaList = [
      // // vitrinaSlide 0: Acumulacion x3
      // {
      //   titulo : 'TODAS tus compras en SUPERMERCADOS \nmultiplican x3 tus Puntos Go',
      //   mobile_titulo : 'TODAS tus compras en SUPERMERCADOS \nmultiplican x3 tus Puntos Go',
      //   subTitulo:'<br>Exclusivo con Tarjeta de Crédito Banco Ripley hasta el 31 de enero.<br><br>',
      //   mobile_subTitulo:'<br>Exclusivo con Tarjeta de Crédito Banco Ripley hasta el 31 de enero.<br><br>',
      //   hrefBanner: 'https://bancoripley.com.pe/formularios/index.html?plantilla=compra-en-supermercados',
      //   contenido_boton: 'Inscríbete aquí',
      //   textAlignBTN1_Desktop: 'left',   //left,center,right
      //   textAlignBTN1_Mobile: 'left',   //left,center,right
      //   imagen_vitrina: 'banner-home-supermercados-ene.png',
      //   taggeo:'banner-home-supermercados-ene'
      // },
      // // vitrinaSlide 1: Despacho Gratis y Go
      // {
      //   titulo : '\n\n',
      //   mobile_titulo : '\n',
      //   subTitulo:'<br><br><br>',
      //   mobile_subTitulo:'<br><br>',
      //   hrefBanner: 'https://bancoripley.com.pe/formularios/index.html?plantilla=registro-primax',
      //   contenido_boton: 'Obtén el beneficio',
      //   textAlignBTN1_Desktop: 'right',   //left,center,right
      //   textAlignBTN1_Mobile: 'right',   //left,center,right
      //   imagen_vitrina: 'banner-home-primax-ene.png',
      //   taggeo:'banner-home-primax-ene'
      // },
      // vitrinaSlide 2: Cumpleaños
      {
        titulo : '¡Disfruta del descuento especial que tenemos para ti en Tiendas Ripley!',
        mobile_titulo : '¡Disfruta del descuento especial que tenemos para ti en Tiendas Ripley!',
        subTitulo:'<br>Hasta 30% dto. exclusivo en vestuario, calzado y deporte con Tarjeta de Crédito Banco Ripley.<br><br>',
        mobile_subTitulo:'<br>Hasta 30% dto. exclusivo en vestuario, calzado y deporte con Tarjeta de Crédito Banco Ripley.<br><br>', 
        hrefBanner: 'clientes-prime',
        contenido_boton: 'Quiero ver más',
        textAlignBTN1_Desktop: 'left',   //left,center,right
        textAlignBTN1_Mobile: 'left',   //left,center,right
        imagen_vitrina: 'banner-categorias-cumpleanios-feb.png',
        taggeo:'banner-categorias-cumpleanios-feb'
      },    

      // vitrinaSlide 3: Restofans
      {
        titulo : '\n\n',
        mobile_titulo : '\n\n\n\n\n\n',
        subTitulo:'<br>\n\n<br><br>',
        mobile_subTitulo:'<br>\n\n\n\n\n<br><br>', 
        hrefBanner: 'https://www.bancoripley.com.pe/promociones/default.html',
        contenido_boton: 'Quiero ver más',
        textAlignBTN1_Desktop: 'left',   //left,center,right
        textAlignBTN1_Mobile: 'left',   //left,center,right
        imagen_vitrina: 'banner-categorias-restofans-feb.png',
        taggeo:'banner-categorias-restofans-feb'
      },

      // vitrinaSlide 1: Despacho Gratis y Go
      {
        titulo : 'En Febrero, ¡aprovecha tus envíos GRATIS!',
        mobile_titulo : 'En Febrero, ¡aprovecha tus envíos GRATIS!',
        subTitulo:'<br>Usa tus cupones de envío gratis al mes por ser cliente Gold o Silver. Exclusivo con Tarjeta de Crédito Banco Ripley Mastercard.<br><br>',
        mobile_subTitulo:'<br>Usa tus cupones de envío gratis al mes por ser cliente Gold o Silver. Exclusivo con Tarjeta de Crédito Banco Ripley Mastercard.<br><br>',
        hrefBanner: 'https://simple.ripley.com.pe',
        contenido_boton: 'Úsalos aquí',
        textAlignBTN1_Desktop: 'left',   //left,center,right
        textAlignBTN1_Mobile: 'left',   //left,center,right
        imagen_vitrina: 'banner-categorias-despacho-feb.png',
        taggeo:'banner-categorias-despacho-feb'
      },

      // vitrinaSlide 4: Acum. Supermercados
      {
        titulo: 'Con mis \nRipley Puntos GO, \n¡lo quiero, lo canjeo!',
        mobile_titulo: '',
        subTitulo: '<br>Acumula Puntos Go \ncon tu Tarjeta Banco Ripley \ny canjéalos por lo que quieras.<br><br>',
        mobile_subTitulo: '<br><br><br>',
        hrefBanner: '',
        contenido_boton: '',
        textAlignBTN1_Desktop: 'left',   //left,center,right
        textAlignBTN1_Mobile: 'left',   //left,center,right
        imagen_vitrina: 'banner-home-lanzamiento-feb.png',
        taggeo: 'banner-home-lanzamiento-feb'
      },
    ]

    this.sliderCategoriaList = item_sliderCategoriaList;  
  }
  slidePrev() {
    this.slides.slidePrev();
    this.slides.startAutoplay();
  }
  slideNext() {
    this.slides.slideNext();
    this.slides.startAutoplay();
  }  

}

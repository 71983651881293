import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { CanjeMillasService } from '@services/canjes/canje-millas.service';

@Component({
  selector: 'app-paquete-exitoso',
  templateUrl: './paquete-exitoso.component.html',
  styleUrls: ['./paquete-exitoso.component.scss'],
})
export class PaqueteExitosoComponent implements OnInit {
  //---parameter from confirmar paquete
  param : any;
  paramTemp : any;
  paramTitulos : any;
  textoTitulo = '';
  pathCanjeLatamPassDNI = 'canje-latam-pass';
    constructor(private canjeMillasService : CanjeMillasService,
                @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.paramTemp = this.canjeMillasService.getPackExito();
    if (this.paramTemp.length === 0) {
      this.document.location.href = '/' + this.pathCanjeLatamPassDNI;
    }
    else {
      this.param = this.paramTemp;
      this.paramTitulos = this.param.titulo.split("=");
      if (this.paramTitulos.length > 1) {
        this.textoTitulo = this.paramTitulos[1];
      }
      else {
        this.textoTitulo = this.paramTitulos[0];
      }
    }

  }
  volverInicio(){
    this.document.location.href = '/' + this.pathCanjeLatamPassDNI;
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-beneficios-promociones',
  templateUrl: './beneficios-promociones.component.html',
  styleUrls: ['./beneficios-promociones.component.scss'],
})
export class BeneficiosPromocionesComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}

import { Directive, HostListener, Input, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PeruvianCurrencyPipe } from 'src/app/pipes/peruvian-currency/peruvian-currency.pipe';
import { DecimalPipe } from '@angular/common';

@Directive({
  selector: '[moneyInputDec]'

})
export class MoneyDecInputDirective {

  @Input('moneyInputDec') formControl: FormControl;
  element: HTMLInputElement;
  decimalPipe: DecimalPipe;

  constructor(
    private el: ElementRef,
    private peruvianCurrency: PeruvianCurrencyPipe,
    private _decimalPipe: DecimalPipe
  ) {
    this.element = this.el.nativeElement;
    this.decimalPipe = this._decimalPipe;
  }

  @HostListener('input', ['$event.target.value']) onChange(typedValue: string) {
    if (typedValue) {
      const decimalValue = this.unformattCurrencyDec(typedValue);
      this.formControl.setValue( !isNaN(decimalValue) ? decimalValue : null);
      this.element.value = this.peruvianCurrency.transform(decimalValue, 0);
    }
  }

  @HostListener('blur', ['$event.target.value']) onBlur() {
    this.formControl.markAsTouched();
  }

  private unformattCurrencyDec(value: string) {
    const decimal_formatted = this.decimalPipe.transform(Number(value), '1.2-2');
    return Number(decimal_formatted);
  }
}

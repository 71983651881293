import { Component, HostListener, OnInit } from '@angular/core';
import { IProductResponse } from 'src/app/common/interfaces/product.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CanjeMillasService } from 'src/app/services/canjes/canje-millas.service';

@Component({
  selector: 'app-canjes-promociones-latam',
  templateUrl: './canjes-promociones-latam.component.html',
  styleUrls: ['./canjes-promociones-latam.component.scss'],
})
export class CanjesPromocionesLatamComponent implements OnInit {

  // ----puntos productos
  puntosProductoList: any = [];
  listPaquetesLatam: IProductResponse[];
  
  scrHeight = 0;
  scrWidth = 0;
  responsive = false;
  constructor(
    private authService: AuthService,
    private canjeMillasService: CanjeMillasService,
    ) {
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    if (this.scrWidth <= 670) {
      this.responsive = true;
    } else {
      this.responsive = false;
    }
  }

  ngOnInit() {
    // this.getListaPuntosProducto();
    this.getPaquetesMillas();
  }

  getListaPuntosProducto() {
    const item_puntosProductoList = [
      { points: '4,500', miles: '1,000' },
      { points: '7,000', miles: '3,000' },
      { points: '20,000', miles: '9,000' },
      { points: '50,000', miles: '24,000' },
      { points: '100,000', miles: '50,000' },
    ];
    this.puntosProductoList = item_puntosProductoList;
  }

  getPaquetesMillas() {
    // console.log('getPaquetesMillas');
    this.authService.getAuthentication0().subscribe(
      (response: any) => {
        // console.log(response);
        const status = response.status;
        const bodyResponse = response.body;
        const statusOk = 200;
        switch (status) {
          case statusOk:
            const token = bodyResponse.access_token;
            //console.log(token);
            this.canjeMillasService.getListProductsLatam(token)
              .then((dataResult) => {
                if (dataResult.length > 0) {
                  // this.listPaquetesLatam = dataResult;
                  this.puntosProductoList = dataResult;
                  // console.log(this.puntosProductoList);
                  // this.swiperSlideChanged(0);
                } else {
                  // this.router.navigateByUrl('segmentacion');
                }
              })
              .catch((error) => {
                console.log('catch: ', error);
              });
            break;
          default:
            break;
        }
      },
      (error) => {
        // const errorBody = error.error;
        // const status = error.status;
      }
    );
  }

}

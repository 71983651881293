export const ROUTES_WITH_RECAPTCHA_VALIDATION = [
  { route: 'enrollment/accountuserhb/contactdetail/newuser/createperson', requestKey: 'captchaResponse' },
  { route: 'enrollment/accountuserhb/update', requestKey: 'captchaResponse' },
  { route: 'admin/user/connect/token', requestKey: 'captchaResponse' },
  { route: 'productshbk/product/active/validate', requestKey: 'captchaResponse' },
  { route: 'productshbk/product/payment/get-information', requestKey: 'captchaResponse' },
  { route: 'productshbk/product/payment/validate-process', requestKey: 'captchaResponse' },
  { route: 'productshbk/product/payment/execution', requestKey: 'captchaResponse' },
  { route: 'cashmanagement/interbanktransfer/send', requestKey: 'captchaResponse' },
  { route: 'cashmanagement/transfertothirdparties/send', requestKey: 'captchaResponse' },
  { route: 'cashmanagement/transferbetweenmyaccounts', requestKey: 'captchaResponse' },
  { route: '/financialsoffers/efex/promotionals', requestKey: 'captchaResponse' },
  { route: '/financialsoffers/efex/send-sms', requestKey: 'captchaResponse' },
  { route: '/financialsoffers/efex/contact-data', requestKey: 'captchaResponse' },
  { route: '/financialsoffers/efex/qualification/list', requestKey: 'captchaResponse' },
  { route: '/financialsoffers/efex/qualification/save', requestKey: 'captchaResponse' },
  { route: '/financialsoffers/efex/register-requisition', requestKey: 'captchaResponse' },
  { route: '/parameterhbk/TCPD', requestKey: 'captchaResponse' },
  { route: '/parameterhbk/CSCA', requestKey: 'captchaResponse' },
  { route: 'productshbk/passive/openAccount/checkBlackList', requestKey: 'captchaResponse' },
  { route: 'productshbk/passive/openAccount/getDataClient', requestKey: 'captchaResponse' },
  { route: 'productshbk/passive/openAccount/pre-register-account', requestKey: 'captchaResponse' },
  { route: 'productshbk/passive/openAccount/send-sms', requestKey: 'captchaResponse' },
  { route: 'productshbk/passive/openAccount/register-account', requestKey: 'captchaResponse' },
  { route: 'productshbk/passive/openAccount/validate-sms', requestKey: 'captchaResponse' },
  { route: 'product/loans/information', requestKey: 'captchaResponse' },
  { route: 'product/loans/quota-pending', requestKey: 'captchaResponse' },
  { route: 'product/loans/payment/validate-process', requestKey: 'captchaResponse' },
  { route: 'product/loans/payment/execution', requestKey: 'captchaResponse' },
  { route: 'financialsoffers/loans/lead', requestKey: 'captchaResponse' },
  { route: 'financialsoffers/loans/simulator', requestKey: 'captchaResponse' },
  { route: 'financialsoffers/loans/customer-data', requestKey: 'captchaResponse' },
  { route: 'financialsoffers/loans/send-sms', requestKey: 'captchaResponse' },
  { route: 'financialsoffers/loans/disbursement-automatic', requestKey: 'captchaResponse' },
  { route: 'financialsoffers/loans/commissions-interbank', requestKey: 'captchaResponse' },
  { route: 'financialsoffers/loans/pre-register', requestKey: 'captchaResponse' },
  { route: 'financialsoffers/loans/save-account', requestKey: 'captchaResponse' },
  { route: 'financialsoffers/loans/customer-agreement', requestKey: 'captchaResponse' },
  { route: 'admin/user/menu-options/authorization', requestKey: 'captchaResponse' },
  { route: '/productshbk/product/active/consolidate', requestKey: 'captchaResponse' },
  { route: '/productshbk/product/active/detail/tc', requestKey: 'captchaResponse' },
  { route: '/productshbk/product/active/movements', requestKey: 'captchaResponse' },
  { route: '/productshbk/passive/movements/saving', requestKey: 'captchaResponse' },
  { route: 'product/rescheduling/tc/consult', requestKey: 'captchaResponse' },
  { route: 'product/rescheduling/tc/questions-list', requestKey: 'captchaResponse' },
  { route: 'product/rescheduling/tc/simulate', requestKey: 'captchaResponse' },
  { route: 'product/rescheduling/tc/send-otp', requestKey: 'captchaResponse' },
  { route: 'product/rescheduling/tc/validate-otp-execute', requestKey: 'captchaResponse' },
  { route: 'clientwebapi/api/segmentacion/getsetmentantion', requestKey: 'captcha' },
  { route: 'clientwebapi/api/clientomp/registerclientomp', requestKey: 'captcha' },
  { route: 'latamwebapi/customer/validationCustomer', requestKey: 'captcha' },
  { route: 'clientwebapi/api/promotion/registerpromotion', requestKey: 'captcha' },
];
export const ROUTES_WITHOUT_RECAPTCHA_HEADER = [
  'jsonip.com'
];
export const RECAPTCHA_ACTION_KEY = 'register';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-afiliacion-vitrina',
  templateUrl: './afiliacion-vitrina.component.html',
  styleUrls: ['./afiliacion-vitrina.component.scss'],
})
export class AfiliacionVitrinaComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}

import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  dropdownCanjes = false;
  dropdownAfiliate = false;
  menuMobileOpen = false;
  menuScroll = false;
  menuResponse = false;
  beta= false;//temporal

  iMenu = 1;
  sUri = '';
  public innerWidth: any;

  constructor(private router: Router) { }

  ngOnInit() {
    //ini temporal
   /* const flagBeta = sessionStorage.getItem('flagBeta');
    if (flagBeta == '1'){
      this.beta = true;
    }
    */
    //fin temporal
    this.innerWidth = window.innerWidth;
    // console.log('Ancho ' + this.innerWidth);
    if (this.innerWidth < 1024) {
      this.menuResponse = true;
    } else {
      this.menuResponse = false;
      this.menuScroll = false;
    }

    this.dropdownCanjes = false;
    this.dropdownAfiliate = false;
    this.menuMobileOpen = false;
    this.menuScroll = false;
    this.sUri = this.router.url;

    if (this.sUri.toUpperCase().includes('/INDEX')) {
      this.iMenu = 1;
    }

    if (this.sUri.toUpperCase().includes('/RIPLEY-PUNTOS')) {
      this.iMenu = 2;
    }

    if (this.sUri.toUpperCase().includes('/CLIENTES-PRIME')) {
      this.iMenu = 3;
    }

    if (this.sUri.toUpperCase().includes('/CANJES')) {
      this.iMenu = 4;
    }

    if (this.sUri.toUpperCase().includes('/AFILIACION')) {
      this.iMenu = 5;
    }

    if (this.sUri.toUpperCase().includes('/REGISTER')) {
      this.iMenu = 5;
    }
  }



  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    // console.log('Ancho onResize' + this.innerWidth);
    if (this.innerWidth < 1024) {
      this.menuResponse = true;
    } else {
      this.menuResponse = false;
      this.menuScroll = false;

    }
  }
  /*
  @HostListener("window:scroll", ["$event"]) onScroll(event: any) {
    let top = event.target.scrollTop;
    if (top > 80) { //--180
      this.menuScroll = true;
    } else {
      this.menuScroll = false;
    }
  }
  */

  logScrolling(event: any) {
    const top = event.detail.currentY;
    if (top > 150) {
      this.menuScroll = true;
    } else {
      this.menuScroll = false;
    }
  }


  CanjeShow(e) {
    this.dropdownCanjes = true;
    this.dropdownAfiliate = false;

  }

  CanjeNoShow(e) {
    this.dropdownCanjes = false;
    this.dropdownAfiliate = false;
  }


  AfiliateShow(e) {
    this.dropdownAfiliate = true;
    this.dropdownCanjes = false;

  }

  AfiliateNoShow(e) {
    this.dropdownAfiliate = false;
    this.dropdownCanjes = false;

  }



  DropdownNoShow(e) {
    this.dropdownAfiliate = false;
    this.dropdownCanjes = false;
  }

  MenuMobileOpen(e) {
    this.menuMobileOpen = true;
  }

  MenuMobileClose(e) {
    this.menuMobileOpen = false;
  }




  Click_Canjes(e) {
    this.iMenu = 4;
  }

  Click_Afiliate(e) {
    this.iMenu = 5;
  }
//temporal
/*
onIngresar(){
    let nroAcceso = (document.getElementById('nroAcceso') as HTMLInputElement ) .value;
    let contraseniaError = (document.getElementById('contraseniaError') as HTMLInputElement)
    //console.log(nroAcceso);
    if (nroAcceso =='123456789'){
      sessionStorage.setItem('flagBeta', '1')
      this.beta = true;
      contraseniaError.innerHTML = ''
    } else {
      contraseniaError.innerHTML = 'Acceso Incorrecto'
    }

  }
  */
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-app-puntoscalificables',
  templateUrl: './modal-app-puntoscalificables.component.html',
  styleUrls: ['./modal-app-puntoscalificables.component.scss'],
})
export class ModalAppPuntoscalificablesComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}

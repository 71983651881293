import { DOCUMENT, formatNumber } from '@angular/common';
import { Component, Inject, OnInit, LOCALE_ID } from '@angular/core';
import { CanjeMillasService } from '@services/canjes/canje-millas.service';

@Component({
  selector: 'app-paquete-exitoso',
  templateUrl: './paquete-exitoso.component.html',
  styleUrls: ['./paquete-exitoso.component.scss'],
})
export class PaqueteExitosoComponent implements OnInit {
  //---parameter from confirmar paquete
  param : any;
  paramTitulos : any;
  textoTitulo = '';
  textoEstado = '';
  textoProducto = '';
  pathInicioCanjeLatamPass = '/canjes?category=latampass';
  pathCanjeLatamPassDNI = '/canje-latam-pass';
    constructor(private canjeMillasService : CanjeMillasService,
                @Inject(DOCUMENT) private document: Document,
                @Inject(LOCALE_ID) public locale: string
  ) { }

  ngOnInit() {
    this.param = this.canjeMillasService.getPackExito();
    // this.param = {
    //   code: 0,
    //   status: "pending",
    //   imagen: "https://firebasestorage.googleapis.com/v0/b/hbk-prd.appspot.com/o/products%2Fimages%2F05-07-2023_09%3A52%3A21_latam-1500.png?alt=media&token=8c889ebf-37ef-4e8d-83ec-dc71492e1493",
    //     "termsAndConditionsPDF": "https://firebasestorage.googleapis.com/v0/b/hbk-prd.appspot.com/o/defaultUploads%2F26-09-2023_12%3A40%3A48_Legal%20RPGO-LATAM%20Pass%202023.pdf?alt=media&token=b649fb9c-c741-4560-a05b-9c55556f1731",
    //   titulo: "1,500 Puntos = 600 Millas LATAM Pass",
    //   miles: 600,
    //   packagePoints: 1500
    // }
    if (this.param.length === 0) {
      this.document.location.href = this.pathCanjeLatamPassDNI;
    }
    else {
      const formatoPoints = formatNumber(this.param.packagePoints, this.locale, '1.0-5');
      const formatoMiles = formatNumber(this.param.miles, this.locale, '1.0-5');
      

      if (this.param.code === 0) {
        this.textoTitulo = "¡Felicidades!<br>Has canjeado tu paquete de Millas LATAM Pass";
        this.textoEstado = "El canje de millas ha sido validado por LATAM Airlines. Para hacer uso de las millas en tus viajes, visita www.latamairlines.com";
      }
      else if (this.param.code === 1) {
        this.textoTitulo = "¡Felicidades!<br>Has canjeado tu paquete de Millas LATAM Pass";
        this.textoEstado = "El canje de millas está siendo validado por LATAM Airlines. Este proceso puede durar hasta 24 horas. Si presenta algún problema, se te reembolsarán los puntos.";
      }
      else {
        this.textoTitulo = 'Canje de ' + formatoMiles +' millas Latam Pass por ' + formatoPoints + ' Ripley Puntos Go';
        this.textoEstado = "El canje de millas ha sido rechazado por LATAM. Los puntos utilizados serán reembolsados a tu cuenta Ripley Puntos Go.";
      }

      this.textoProducto = formatoPoints + ' Puntos Go = ' + formatoMiles + ' Millas';

      // this.paramTitulos = this.param.titulo.split("=");
      // if (this.paramTitulos.length > 1) {
      //   this.textoProducto = this.paramTitulos[1];
      // }
      // else {
      //   this.textoProducto = this.paramTitulos[0];
      // }
    }

  }
  volverInicio(){
    this.document.location.href = this.pathInicioCanjeLatamPass;
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pantalla-notripley',
  templateUrl: './pantalla-notripley.component.html',
  styleUrls: ['./pantalla-notripley.component.scss'],
})
export class PantallaNotripleyComponent implements OnInit {

  pathConoceMas = 'ripley-puntos';
  
  constructor(
    private router: Router
  ) { }

  ngOnInit() {}

  onSolicitar() {
    const url: string = "https://www.bancoripley.com.pe/solicita-tarjeta-ripley-mastercard/index.html?utm_source=web-ripley-puntos&utm_medium=boton-solicitud&utm_campaign=aon-captacion";
      window.open(url, '_blank');
  }
  onConoceMas() {
    this.router.navigateByUrl(this.pathConoceMas);
  }
}

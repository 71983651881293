import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comocanjear-ripleypuntos',
  templateUrl: './comocanjear-ripleypuntos.component.html',
  styleUrls: ['./comocanjear-ripleypuntos.component.scss'],
})
export class ComocanjearRipleypuntosComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}

import { IGoogleTagManagerEvent, IGoogleTagV2ManagerEvent } from 'src/app/common/interfaces/google-tag-manager.interface';
import { Inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class GoogleTagManagerService {

    private isLoaded = false;
    private gtmId: string;
    public ripleyAnalytics: any;

    constructor(
        @Inject('googleTagManagerId') public googleTagManagerId: string,
        @Inject('googleTagManagerId_Second') public googleTagManagerId_Second: string,
        private platform: Platform,
    ) {
        if (this.platform.is('cordova')) {
            this.gtmId = googleTagManagerId_Second;
        } else {
            this.gtmId = googleTagManagerId;
        }
        window['ripleyAnalytics'] = {
            addEvent: () => {}
        };
    }

    public getDataLayer() {
        window['dataLayer'] = window['dataLayer'] || [];
        return window['dataLayer'];
    }

    public setRipleyAnalytics() {
        this.ripleyAnalytics = window['ripleyAnalytics'];
    }

    private pushOnDataLayer(obj: object) {
        const dataLayer = this.getDataLayer();
        dataLayer.push(obj);
    }

    public addGtmToDom() {
        this.pushOnDataLayer({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
        });

        const gtmScript = document.createElement('script');
        gtmScript.id = 'GTMscript';
        gtmScript.async = true;
        gtmScript.src = '//www.googletagmanager.com/gtm.js?id=' + this.gtmId;
        document.head.insertBefore(gtmScript, document.head.firstChild);

        const ifrm = document.createElement('iframe');
        ifrm.setAttribute('src', '//www.googletagmanager.com/ns.html?id=' + this.gtmId);
        ifrm.style.width = '0';
        ifrm.style.height = '0';
        ifrm.style.display = 'none';
        ifrm.style.visibility = 'hidden';

        const noscript = document.createElement('noscript');
        noscript.id = 'GTMiframe';
        noscript.appendChild(ifrm);

        document.body.insertBefore(noscript, document.body.firstChild);
        this.isLoaded = true;
    }

    public pushTag(item: IGoogleTagManagerEvent) {
        if (!this.isLoaded) { this.addGtmToDom(); }
        if (!item.additionalInformation) { item.additionalInformation = []; }
        this.setRipleyAnalytics();
        if (this.ripleyAnalytics) {
            this.ripleyAnalytics.addEvent(
                item.event,
                item.description,
                item.action,
                item.additionalInformation[0],
                item.additionalInformation[1]
            );
            this.pushOnDataLayer(item);
        }
    }

    public pushTagV2(item: IGoogleTagV2ManagerEvent) {
        if (!this.isLoaded) { this.addGtmToDom(); }
        this.pushOnDataLayer(item);
    }

    public pushTagObject(item: object) {
        if (!this.isLoaded) { this.addGtmToDom(); }
        this.pushOnDataLayer(item);
    }
}

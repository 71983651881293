import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-afiliacion-sintarjeta',
  templateUrl: './afiliacion-sintarjeta.component.html',
  styleUrls: ['./afiliacion-sintarjeta.component.scss'],
})
export class AfiliacionSintarjetaComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}

import { EXTEND_CREDIT_LINE_FAQS } from './faq-information.contants';
import { IDLE_EXIT_AFTER_WARNING_TIMEOUT } from './misc.constants';
import { MULTIPLE_SESSIONS_MESSAGE } from './auth.constants';

export const FORCED_UPDATE_MODAL_PROPS = {
  title: 'Actualiza tu app',
  message: 'Por razones de seguridad, debes actualizar tu app.',
  primaryButtonText: 'Ok',
  icon: '',
  extraClass: 'title-bold'
};

export const EXTEND_CREDIT_LINE_FAQS_MODAL_PROPS = {
  title: 'Preguntas frecuentes',
  information: EXTEND_CREDIT_LINE_FAQS,
};

export const EXTEND_CREDIT_LINE_CANCEL_PROGRESS_MODAL_PROPS = {
  title: '¿Estás seguro que deseas salir y no incrementar tu línea?',
  message: 'Perderás el progreso que llevas hasta ahora',
  primaryButtonText: 'Cancelar',
  secondaryButtonText: ' Sí, estoy seguro',
  icon: 'i-alert-purple'
};

export const EFEX_CANCEL_PROGRESS_MODAL_PROPS = {
  title: '¿Estás seguro que deseas salir y no desembolsar tu Efectivo Express?',
  message: 'Perderás el progreso que llevas hasta ahora',
  primaryButtonText: 'Cancelar',
  secondaryButtonText: ' Sí, estoy seguro',
  icon: 'i-alert-purple'
};

export const EFEX_SMS_MODAL_PROPS = {
  title: 'Ocurrió algo inesperado',
  message: 'Lo sentimos, en este momento no podemos realizar la operación. ' +
    'Contáctanos al (01) 611-5757, un asesor te ayudará a finalizar el desembolso de tu Efectivo Express.',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple'
};

export const EFEX_FAILED_MODAL_PROPS = {
  title: 'Ocurrió un error',
  message: 'En estos momentos no podemos atender tu solicitud, inténtalo nuevamente más tarde.',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple'
};

export const EFEX_FAILED_TC_MODAL = {
  title: '',
  message: 'Los términos y condiciones no se encuentran disponibles, inténtalo nuevamente más tarde.',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple'
};

export const EFEX_FAILED_AC_MODAL = {
  title: '',
  message: 'Los términos de autorización de cuenta no se encuentran disponibles, inténtalo nuevamente más tarde',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple'
};

export const EFEX_TCEA_MODAL_PROPS = {
  title: 'TCEA',
  message: 'La Tasa de Costo Efectivo Anual calculada considerando un seguro' +
  'de desgravamen mensual de S/7.90. Podría variar si el seguro de desgravamen mensual' +
  'es un monto diferente (rango puede ir de S/ 7.90 a S/15). Monto del Seguro de Desgravamen' +
  'es calculado según la edad a la fecha de afiliación.',
};

export const CONTACT_WITH_CALL_CENTER_MODAL_PROPS = {
  message: 'Contáctate con nuestro RipleyFono <a class="modal-link" href="tel:016115757">(01) 611 5757</a>' +
    ' o visítanos en la agencia de Banco Ripley más cercana para actualizar tu número.',
  primaryButtonText: 'Ir a bancoripley.com.pe',
};

export const IDLE_SESSION_WARNING_MODAL_PROPS = {
  title: 'Tu sesión está a punto de expirar',
  message: `Se cerrará la sesión en ${IDLE_EXIT_AFTER_WARNING_TIMEOUT} segundos`,
  primaryButtonText: 'Quedarme',
  secondaryButtonText: 'Salir',
  icon: 'i-alert-purple'
};

export const SIGNOUT_WARNING_MODAL_PROPS = {
  title: 'Estás a punto de cerrar sesión',
  message: '¿Estás seguro?',
  primaryButtonText: 'Sí',
  secondaryButtonText: 'No',
  icon: 'i-alert-purple'
};

export const INACTIVITY_CLOSED_SESSION_MODAL_PROPS = {
  title: 'Sesión finalizada',
  message: 'Por motivos de inactividad, hemos cerrado tu sesión. <br>Ingresa nuevamente para continuar.',
  primaryButtonText: 'Aceptar',
  icon: 'i-alert-purple',
};

export const CONFIRM_TRANSFER_MODAL_PROPS = {
  title: 'Confirma tu transferencia',
  message: '',
  primaryButtonText: 'Transferir',
  icon: 'i-confirm-modal',
  transferFormValue: {},
  voucher: {},
  currentType: '',
  currencySymbol: 'S/ '
};

export const TRANSFERS_SCHEDULE_MODAL_PROPS = {
  title: 'Horarios de transferencias',
  shiftsSchedule: [],
  nonWorkingDayMessage: 'Las transferencias realizadas los días sábados, domingos y/o feriados' +
    ' se procesarán al siguiente día útil.',
  primaryButtonText: 'Aceptar',
  icon: 'i-question',
};

export const MULTIPLE_SESSIONS_MODAL_PROPS = {
  title: MULTIPLE_SESSIONS_MESSAGE,
  message: 'Asegúrate cerrar sesión en todas las ventanas o dispositivos antes de volver a ingresar.' +
  ' Si crees que una persona no autorizada accedió a tu cuenta, comunícate ' +
  'al RipleyFono <a class="modal-link" href="tel:016115757">(01) 611 5757</a> .',
  primaryButtonText: 'Aceptar',
  icon: 'i-alert-purple',
};

export const SAVINGS_MOVEMENTS_MODAL_PROPS = {
  title: 'Constancia de movimiento',
  primaryButtonText: 'Volver',
  icon: ''
};

export const PAYMENT_CREDITS_MODAL_PROPS = {
  title: 'Crédito de consumo',
  primaryButtonText: 'Pagar préstamo',
  secondaryButtonText: 'Volver',
  icon: ''
};

export const PRODUCTS_FAILED_MODAL_PROPS = {
  title: 'Ocurrió un error',
  message: 'En estos momentos no podemos cargar alguno de tus productos, intenta más tarde',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple'
};

export const TRANFER_CTS_FAILED_MODAL_PROPS = {
  title: 'No es posible realizar la transferencia a esta cuenta',
  message: 'Una cuenta CTS no es válida como cuenta destino.' +
    ' Por favor, ingresa otra cuenta de tu preferencia para continuar la operación.',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple'
};


export const ALREADY_ENROLLED_PROPS = {
  title: '¡Ya te encuentras registrado!',
  message: 'Te invitamos a iniciar sesión con tu número de documento y Clave de ingreso',
  primaryButtonText: 'Ingresar',
  secondaryButtonText: 'Olvidé mi clave',
  icon: 'i-thumb-up'
};

export const ALREADY_ENROLLED_BLOCK_PROPS = {
  title: '¡Tu Cuenta se encuentra bloqueada!',
  message: 'Si necesitas ingresar con urgencia contáctate con nuestro RipleyFono (01) 6115757',
  primaryButtonText: 'Ir a Login',
  icon: 'i-lock-purple'
};

export const NOT_RIPLEY_USER_PROPS = {
  title: '¡Ups! Aún no eres parte de Banco Ripley',
  message: 'Entérate si tienes una tarjeta de crédito Ripley preaprobada.',
  primaryButtonText: 'Ver si tengo una tarjeta',
  icon: 'i-alert-purple'
};

export const NOT_PHONE_PROPS = {
  title: 'Tus datos no se encuentran registrados.',
  message: 'Por favor regístrate a través de la App Banco Ripley Perú',
  primaryButtonText: 'Salir',
  icon: 'i-alert-purple'
};

export const ENROLLMENT_EXIT_PROPS = {
  title: '',
  message: 'Perderás el progreso que llevas hasta ahora.',
  primaryButtonText: 'Cancelar',
  secondaryButtonText: ' Sí, estoy seguro',
  icon: 'i-alert-purple'
};

export const CREATED_USER_PROPS = {
  title: '',
  message: '<b>¡Felicidades!</b><br>Ahora podrás realizar tus operaciones de manera más fácil y en cualquier lugar.',
  primaryButtonText: 'Ingresar',
  secondaryButtonText: 'Ir a bancoripley.com.pe',
  icon: 'i-happy-face'
};

export const NOT_MY_NUMBER_PROPS = {
  title: '¿No es tu número?',
  message: 'Contáctate con nuestro RipleyFono <a class="modal-link" href="tel:016115757">(01) 611 5757</a>' +
    ' o visítanos en la agencia de Banco Ripley más cercana para actualizar tu número.',
  primaryButtonText: 'Ir a bancoripley.com.pe',
};

export const OPEN_ACCOUNT_FAILED_MODAL_PROPS = {
  title: 'Ocurrió un error',
  message: 'En estos momentos no podemos realizar la Apertura cuenta Ahorro Plus, intenta más tarde',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple'
};

export const TAMPERED_DEVICE_PROPS = {
  title: 'Lo sentimos',
  message: 'Tu dispositivo se encuentra inhabilitado para hacer uso de esta aplicación, debido a que ha sido alterado' +
    ' para obtener privilegios de administrador',
};

export const LOAN_FAILED_MODAL_PROPS = {
  title: 'Ocurrió un error',
  message: 'En estos momentos no podemos atender tu solicitud, inténtalo nuevamente más tarde.',
  primaryButtonText: 'Aceptar',
  secondaryButtonText: 'Reintentar',
  icon: 'i-alert-purple'
};

export const RECAPTCHA_MODAL_PROPS = {
  title: '¡Ups!',
  message: 'Error en el recaptcha, asegúrese de ser un humano.',
  primaryButtonText: 'Aceptar',
  icon: 'i-alert-purple'
};

export const TIME_OUT_MODAL_PROPS = {
  title: '¡Ups!',
  message: 'Su tiempo en la plataforma web ha caducado.',
  primaryButtonText: 'Aceptar',
  icon: 'i-alert-purple'
};

export const DATA_WRONG_MODAL_PROPS = {
  title: '¡Ups!',
  message: 'Los datos son incorrectos.',
  primaryButtonText: 'Aceptar',
  icon: 'i-alert-purple'
};

export const SERVICE_ERROR_MODAL_PROPS = {
  title: '¡Ups!',
  message: 'Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente más tarde',
  primaryButtonText: 'Salir',
  icon: 'i-alert-purple'
};

export const LOAN_LEAD_FAILED_MODAL_PROPS = {
  title: 'Ocurrió un error',
  message: 'En estos momentos no podemos registrar tu solicitud, intenta más tarde',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple'
};

export const EFEX_SEF_FAILED_POLICY_MODAL = {
  title: '',
  message: 'Las políticas de privacidad no se encuentran disponibles, inténtalo nuevamente más tarde.',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple'
};

export const OPEN_SIMPLE_ACCOUNT_FAILED_MODAL_PROPS = {
  title: 'Ocurrió un error',
  message: 'En estos momentos no podemos realizar la Apertura cuenta simple, intenta más tarde',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple'
};

export const SEF_EFEX_AUTO_TCEA_MODAL_PROPS = {
  extraTitleClass: 'extraTitleClass',
  title: 'Tasa de Costo Efectivo Anual',
  message: 'La TCEA es la Tasa de Costo Efectiva Anual que incluye la TEA ' +
  'más los gastos y comisiones del crédito. En este caso como gasto se ' +
  'considera al Seguro de Desgravamen S/ 7.90 y la comisión de membresía anual S/ 48.',
};

export const LEAVE_OFFER_MODAL_PROPS = {
  title: '',
  message: '¿Estás seguro que deseas salir del proceso de desembolso?',
  primaryButtonText: 'Sí, salir',
  secondaryButtonText: 'Cancelar',
  icon: ''
};

export const OFFER_EFEX_INACTIVE_MODAL_PROPS = {
  title: 'El desembolso no puede ser procesado',
  message: 'Lo sentimos, en este momento no podemos realizar la operación.' +
  ' Te invitamos a realizar el desembolso de tu Efectivo Express a partir de las 12:00 a.m.',
  primaryButtonText: 'De acuerdo',
  icon: 'i-alert-purple',
  extraClass: 'title-purple'
};

export const OFFER_SEF_INACTIVE_MODAL_PROPS = {
  title: 'El desembolso no puede ser procesado',
  message: 'Lo sentimos, en este momento no podemos realizar la operación.' +
  ' Te invitamos a realizar el desembolso de tu Súper Efectivo a partir de las 12:00 a.m.',
  primaryButtonText: 'De acuerdo',
  icon: 'i-alert-purple',
  extraClass: 'title-purple'
};

export const DISBURSEMENT_FAILED_WEB_MODAL_PROPS = {
  title: 'Ocurrió algo inesperado',
  message: '',
  content: 'Lo sentimos, en este momento no podemos realizar la operación. Contáctanos al ' +
  '<b>(01) 611-5757</b>, un asesor te ayudará a finalizar el desembolso de tu ',
  primaryButton: 'Cerrar'
};

export const DISBURSEMENT_FAILED_MOBILE_MODAL_PROPS = {
  title: 'Ocurrió algo inesperado',
  message: '',
  content: 'Lo sentimos, en este momento no podemos realizar la operación. Contáctanos al ' +
  '<b>(01) 611-5757</b>, un asesor te ayudará a finalizar el desembolso de tu ',
  primaryButton: 'Llamar ahora',
  secondaryButton: 'Cerrar'
};

export const AMOUNT_LIMIT_MODAL_PROPS = {
  title: 'El monto excede lo permitido',
  message: 'No te preocupes. Solicita el apoyo de uno de <b>nuestros asesores ' +
  'especializados</b> para obtener tu préstamo.',
  icon: 'i-alert-purple',
  primaryButtonText: 'Quiero que me llamen',
  secondaryButtonText: 'Cancelar'
};

export const END_TO_END_OFF_MODAL_PROPS = {
  title: '¡Ups!, parece que algo salió mal',
  message: 'No te preocupes. Solicita el apoyo de uno de <b>nuestros asesores ' +
  'especializados</b> para obtener tu préstamo.',
  icon: '',
  primaryButtonText: 'Quiero que me llamen',
  secondaryButtonText: 'Cancelar',
};

export const RESCHEDULING_CANCEL_MODAL = {
  title: '¿Estás seguro que deseas salir del proceso de reprogramación de deuda?',
  primaryButtonText: 'Sí, salir',
  secondaryButtonText: 'Cancelar',
  icon: 'paddingOff'
};

export const RESCHEDULING_TOTAL_DEBT_MODAL = {
  title: '',
  message: 'Deuda total al día de hoy, no considera las operaciones pendientes de procesar.',
};

export const ON_OFF_CANCEL_MODAL = {
  message: 'No has guardado los cambios que has realizado. ¿Estás seguro que deseas salir sin aplicar los cambios?',
  primaryButtonName: 'Guardar Cambios',
  secondaryButtonName: 'Salir',
  acceptProcessAction: 0,
  cancelProcessAction: 0,
  icon: 'i-alert-purple',
};

export const TEM_TOOLTIP_MOBILE = {
  title: '',
  message: 'Tasa efectiva mensual.',
};

export const TEA_OPTION = 'tea';

export const TEM_OPTION = 'tem';

export const TCEA_OPTION = 'tcea';

export const TEA_TOOLTIP_MOBILE = {
  title: '',
  message: 'Tasa efectiva anual.',
};

export const TCEA_TOOLTIP_MOBILE = {
  title: '',
  message: 'Tasa de costo efectivo anual.',
};

export const GENERIC_ERROR_PROPS = {
  title: 'Ocurrió algo inesperado',
  message: 'Lo sentimos, en este momento no podemos realizar la operación. ' +
  'Contáctanos al (01) 611-5757, un asesor te ayudará a finalizar el proceso de reprogramación.',
  primaryButtonText: 'Cerrar',
  icon: ''
};

export const SWORN_DECLARATION_MODAL_PROPS = {
  title: 'Parece que algo salió mal',
  message: 'No te preocupes. Solicita el apoyo de uno de <b>nuestros asesores ' +
  'especializados</b> para obtener tu préstamo.',
  icon: 'i-alert-purple',
  primaryButtonText: 'Quiero que me llamen',
  secondaryButtonText: 'Cancelar',
};

import { Injectable } from '@angular/core';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { Platform } from '@ionic/angular';
import { AccountManagerService } from 'src/app/services/account-manager/account-manager.service';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class BiometricService {

  constructor(
    private fingerprint: FingerprintAIO,
    private platform: Platform,
    private accountManagerService: AccountManagerService,
    private storage: Storage,
  ) { }

  public async showAuthDialog(): Promise<boolean> {
    try {
      const title = `Ingresa a 'Banco Ripley'`;
      const fallbackButtonTitle = 'Usar PIN';
      const subtitle = '';
      const description = 'Usa el sensor';
      const cancelButtonTitle = 'Cancelar';
      const disableBackup = true;
      const isAuthorized = await this.fingerprint.show(
        { title, fallbackButtonTitle, subtitle, description, cancelButtonTitle, disableBackup }
      );
      return !!isAuthorized;
    } catch (err) {
      return false;
    }
  }

  public availableHardware(): Promise<'face' | 'finger'> {
    return this.platform.ready().then(() => {
      return this.fingerprint.isAvailable();
    }).catch(() => {
      return null;
    });
  }

  public async isBiometricEnabled(): Promise<boolean> {
    try {
      const [username, documentType, keyboardId, biometricEnabled] = await Promise.all([
        this.accountManagerService.accountManager.getUserDNI(),
        this.accountManagerService.accountManager.getUserData('documentType'),
        this.accountManagerService.accountManager.getUserData('keyboardId'),
        this.storage.get('biometricEnabled'),
      ]);
      return !!(username && documentType && keyboardId && biometricEnabled);
    } catch (err) {
      return false;
    }
  }

  public disableBiometricConfig() {
    this.storage.set('biometricEnabled', false);
  }

  public enableBiometricConfig() {
    this.storage.set('biometricEnabled', true);
  }

  public async isBiometricSetUp(): Promise<boolean> {
    try {
      const biometricEnabled = await this.storage.get('biometricEnabled');
      return biometricEnabled != null;
    } catch (err) {
      return false;
    }
  }

  public removeBiometricSetUp() {
    this.storage.remove('biometricEnabled');
  }
}

// menu

export const MENU_OPTIONS = 'MENU_OPTIONS';
// No auth routes
export const LOGIN_ROUTE = 'login';

export const ENROLLMENT_ROUTE = 'enrollment';

export const MAINTENANCE_ROUTE = 'maintenance';

export const PASSWORD_RESET_ROUTE = 'password-reset';

// Auth routes

export const HOME_ROUTE = 'home';

export const addPrefix = (route: string = '') => `${HOME_ROUTE}/${route}`;

export const VOUCHER_ROUTE = 'voucher';

export const CREDIT_CARDS_DETAILS_ROUTE_BASELESS = 'credit-card-details';

export const CREDIT_CARDS_DETAILS_ROUTE = addPrefix(CREDIT_CARDS_DETAILS_ROUTE_BASELESS);

export const SEF_DETAILS_ROUTE_BASELESS = 'sef-card-details';

export const SEF_DETAILS_ROUTE = addPrefix(SEF_DETAILS_ROUTE_BASELESS);

export const RIPLEY_POINTS_ROUTE_BASELESS = 'ripley-points';

export const RIPLEY_POINTS_ROUTE = addPrefix(RIPLEY_POINTS_ROUTE_BASELESS);

export const RIPLEY_POINTS_DASHBOARD_ROUTE_BASELESS = `${RIPLEY_POINTS_ROUTE_BASELESS}/ripley-points-dashboard`;

export const RIPLEY_POINTS_DASHBOARD_ROUTE = addPrefix(RIPLEY_POINTS_DASHBOARD_ROUTE_BASELESS);

export const RIPLEY_POINTS_GOLD_SILVER_BENEFITS_ROUTE_BASELESS = `${RIPLEY_POINTS_ROUTE_BASELESS}/gold-silver-benefits`;

export const RIPLEY_POINTS_GOLD_SILVER_BENEFITS_ROUTE = addPrefix(RIPLEY_POINTS_GOLD_SILVER_BENEFITS_ROUTE_BASELESS);

export const RIPLEY_POINTS_GOLD_BENEFITS_ROUTE_BASELESS = `${RIPLEY_POINTS_ROUTE_BASELESS}/gold-benefits`;

export const RIPLEY_POINTS_GOLD_BENEFITS_ROUTE = addPrefix(RIPLEY_POINTS_GOLD_BENEFITS_ROUTE_BASELESS);

export const RIPLEY_POINTS_SILVER_BENEFITS_ROUTE_BASELESS = `${RIPLEY_POINTS_ROUTE_BASELESS}/silver-benefits`;

export const RIPLEY_POINTS_SILVER_BENEFITS_ROUTE = addPrefix(RIPLEY_POINTS_SILVER_BENEFITS_ROUTE_BASELESS);

export const RIPLEY_POINTS_DETAILS_ROUTE_BASELESS = `${RIPLEY_POINTS_ROUTE_BASELESS}/details`;

export const RIPLEY_POINTS_DETAILS_ROUTE = addPrefix(RIPLEY_POINTS_DETAILS_ROUTE_BASELESS);

export const RIPLEY_POINTS_GIFTCARD_ROUTE_BASELESS = `${RIPLEY_POINTS_ROUTE_BASELESS}/gift-card`;

export const RIPLEY_POINTS_GIFTCARD_ROUTE = addPrefix(RIPLEY_POINTS_GIFTCARD_ROUTE_BASELESS);

export const RIPLEY_POINTS_REDEEM_ROUTE_BASELESS = `${RIPLEY_POINTS_ROUTE_BASELESS}/${'redeem'}`;

export const RIPLEY_POINTS_REDEEM_ROUTE = addPrefix(RIPLEY_POINTS_REDEEM_ROUTE_BASELESS);

export const RIPLEY_POINTS_REDEEM_VOUCHER_ROUTE_BASELESS = `${RIPLEY_POINTS_REDEEM_ROUTE_BASELESS}/${VOUCHER_ROUTE}`;

export const RIPLEY_POINTS_REDEEM_VOUCHER_ROUTE = addPrefix(RIPLEY_POINTS_REDEEM_VOUCHER_ROUTE_BASELESS);

export const EXTEND_CREDIT_LINE_ROUTE_BASELESS = 'extend-credit-line';

export const EXTEND_CREDIT_LINE_ROUTE = addPrefix(EXTEND_CREDIT_LINE_ROUTE_BASELESS);

export const TRANSFER_ROUTE_BASELESS = 'transfer';

export const TRANSFER_ROUTE = addPrefix(TRANSFER_ROUTE_BASELESS);

export const CONTACT_INFORMATION_ROUTE_BASELESS = 'contact-information';

export const CONTACT_INFORMATION_ROUTE = addPrefix(CONTACT_INFORMATION_ROUTE_BASELESS);

export const EFEX_ROUTE_BASELESS = 'efex';

export const EFEX_AUTO_ROUTE_BASELESS = 'efex-auto';

export const EFEX_ROUTE = addPrefix(EFEX_ROUTE_BASELESS);

export const EFEX_AUTO_ROUTE = addPrefix(EFEX_AUTO_ROUTE_BASELESS);

export const EFEX_DETAIL_ROUTE_BASELESS = 'detail';

export const EFEX_DETAIL_ROUTE = addPrefix(EFEX_DETAIL_ROUTE_BASELESS);

export const EFEX_SWORN_DECLARATION_ROUTE_BASELESS = 'sworn-declaration';

export const EFEX_SWORN_DECLARATION_ROUTE = addPrefix(EFEX_SWORN_DECLARATION_ROUTE_BASELESS);

export const EFEX_SUCCESS_ROUTE_BASELESS = 'success';

export const EFEX_SUCCESS_ROUTE = addPrefix(EFEX_SUCCESS_ROUTE_BASELESS);

export const SEF_ROUTE_BASELESS = 'sef';

export const SEF_ROUTE = addPrefix(SEF_ROUTE_BASELESS);

export const SEF_DETAIL_ROUTE_BASELESS = 'detail';

export const SEF_DETAIL_ROUTE = addPrefix(SEF_DETAIL_ROUTE_BASELESS);

export const SEF_SWORN_DECLARATION_ROUTE_BASELESS = 'sworn-declaration';

export const SEF_SWORN_DECLARATION_ROUTE = addPrefix(SEF_SWORN_DECLARATION_ROUTE_BASELESS);

export const SEF_SUCCESS_ROUTE_BASELESS = 'success';

export const SEF_SUCCESS_ROUTE = addPrefix(SEF_SUCCESS_ROUTE_BASELESS);

export const RIPLEY_AGENCIES_ROUTE_BASELESS = 'ripley-agencies';

export const RIPLEY_AGENCIES_ROUTE = addPrefix(RIPLEY_AGENCIES_ROUTE_BASELESS);

export const PROMOTIONS_ROUTE_BASELESS = 'promotions';

export const PROMOTIONS_ROUTE = addPrefix(PROMOTIONS_ROUTE_BASELESS);

export const TRANSFERS_ROUTE_BASELESS = 'transfers';

export const TRANSFERS_ROUTE = addPrefix(TRANSFERS_ROUTE_BASELESS);

export const PAYMENTS_ROUTE_BASELESS = 'payments';

export const PAYMENTS_ROUTE = addPrefix(PAYMENTS_ROUTE_BASELESS);

export const ON_OF_ROUTE_BASELESS = 'on-of-cards';

export const ON_OF_ROUTE = addPrefix(ON_OF_ROUTE_BASELESS);

export const CREDITS_ROUTE_BASELESS = 'credit';

export const CREDITS_ROUTE = addPrefix(CREDITS_ROUTE_BASELESS);

export const OFFERS_ROUTE_BASELESS = 'offers';

export const OFFERS_ROUTE = addPrefix(OFFERS_ROUTE_BASELESS);

export const SAVING_ACCOUNTS_ROUTE_BASELESS = 'saving-accounts';

export const SAVING_ACCOUNTS_ROUTE = addPrefix(SAVING_ACCOUNTS_ROUTE_BASELESS);

export const OPTIONS_ROUTE_BASELESS = 'options';

export const OPTIONS_ROUTE = addPrefix(OPTIONS_ROUTE_BASELESS);

export const SAVING_PLUS_ACCOUNT_BASELESS = 'saving-plus';

export const SAVING_PLUS_ACCOUNT_ROUTE = addPrefix(SAVING_PLUS_ACCOUNT_BASELESS);

export const ACCOUNT_OPENNING_OFFERS_BASELESS = 'account-offers';

export const ACCOUNT_OPENNING_OFFERS_ROUTE = addPrefix(ACCOUNT_OPENNING_OFFERS_BASELESS);

export const BENEFITS_ACCOUNT_BASELESS = 'benefits';

export const BENEFITS_ACCOUNT_ROUTE = addPrefix(BENEFITS_ACCOUNT_BASELESS);

export const SALARY_INFO_BASELESS = 'salary-info';

export const SALARY_INFO_ROUTE = addPrefix(SALARY_INFO_BASELESS);

export const SUMMARY_ACCOUNT_BASELESS = 'summary';

export const SUMMARY_ACCOUNT_ROUTE = addPrefix(SUMMARY_ACCOUNT_BASELESS);

export const SWORN_DECLARATION_ACCOUNT_BASELESS = 'sworn-declaration';

export const SWORN_DECLARATION_ROUTE = addPrefix(SWORN_DECLARATION_ACCOUNT_BASELESS);

export const SUCCESS_OPEN_ACCOUNT_BASELESS = 'success';

export const SUCCESS_OPEN_ACCOUNT_ROUTE = addPrefix(SUCCESS_OPEN_ACCOUNT_BASELESS);

export const RESCHEDULING_ROUTE_BASELESS = 'rescheduling';

export const RESCHEDULING_ROUTE = addPrefix(RESCHEDULING_ROUTE_BASELESS);

export const RESCHEDULING_ROUTE_SEF_BASELESS = 'rescheduling-sef';

export const RESCHEDULING_ROUTE_SEF = addPrefix(RESCHEDULING_ROUTE_SEF_BASELESS);

export const SITE_RIPLEY_PUNTOS_GO = 'https://www.ripleypuntos.com.pe/';

export const INDEX = 'index';
export const CUSTOMER_GOLD_SILVER = 'clientes-prime';

export const RIPLEY_PUNTOS = 'ripley-puntos';

export const SEGMENTACION = 'segmentacion';

export const AFILIATE = 'afiliacion';

export const CANJES = 'canjes';

export const MILLASLATAM = 'millas-latam';

export const NOTFOUND = 'not-found';

export const HOME_MENU_BUTTON = {
  title: 'Inicio',
  route: `/${HOME_ROUTE}`,
  icon: 'i-menu-home',
  class: '',
};

export const CONTACT_INFORMATION_MENU_BUTTON = {
  title: 'Tus datos',
  route: `/${CONTACT_INFORMATION_ROUTE}`,
  icon: 'i-menu-contact-information',
  class: '',
};

export const TRANSFER_MENU_BUTTON = {
  title: 'Transferir',
  route: `/${HOME_ROUTE}/4`,
  icon: 'i-menu-transfer',
  class: '',
};

export const PAY_MENU_BUTTON = {
  title: 'Pagar',
  route: `/${PAYMENTS_ROUTE}`,
  icon: 'i-menu-payment',
  class: '',
};

export const SEF_MENU_BUTTON = {
  title: 'Ofertas para ti',
  route: `/${OFFERS_ROUTE}`,
  icon: 'i-menu-advances',
  class: '',
};

export const RIPLEY_POINTS_MENU_BUTTON = {
  title: 'Ripley Puntos Go',
  route: `/${RIPLEY_POINTS_DASHBOARD_ROUTE}`,
  icon: 'i-menu-points',
  class: '',
};

export const ON_OFF_MENU_BUTTON = {
  title: 'Tarjeta On/Off',
  route: `/${HOME_ROUTE}/4`,
  icon: 'i-menu-onoff',
  class: '',
};


export const BENEFITS_MENU_BUTTON = {
  title: 'Promociones',
  route: `/${PROMOTIONS_ROUTE}`,
  icon: 'i-menu-benefits',
  class: '',
};

export const TRANSFERS_MENU_BUTTON = {
  title: 'Transferencias',
  route: `/${TRANSFERS_ROUTE}`,
  icon: 'i-menu-transfer',
  class: '',
};

export const PAYMENTS_MENU_BUTTON = {
  title: 'Pagos',
  route: `/${PAYMENTS_ROUTE}`,
  icon: 'i-menu-payment',
  class: '',
};

/*
export const ON_OFF_CARDS_BUTTON = {
  title: 'Tarjeta on/off',
  route: `/${ON_OF_ROUTE}`,
  icon: 'i-menu-payment',
  class: '',
};*/

export const CREDIT_MENU_BUTTON = {
  title: 'Préstamos',
  route: `/${CREDITS_ROUTE}`,
  icon: 'i-menu-payment',
  class: '',
};

export const AGENCIES_MENU_BUTTON = {
  title: 'Red de agencias',
  route: `/${RIPLEY_AGENCIES_ROUTE}`,
  icon: 'i-light-pin-purple ',
  class: 'hidden-desktop',
};

export const KASNET_MENU_BUTTON = {
  title: 'Agencias KasNet',
  route: `KASNET`,
  icon: 'i-light-pin-purple ',
  class: 'hidden-desktop',
};

export const OPTIONS_MENU_BUTTON = {
  title: 'Ajustes',
  route: `/${OPTIONS_ROUTE}`,
  icon: 'i-light-pin-purple ',
  class: 'hidden-desktop',
};

export const SIDE_MENU_ROUTES_BUTTONS = {
  HOME_MENU_BUTTON,
  CONTACT_INFORMATION_MENU_BUTTON,
  SEF_MENU_BUTTON,
  BENEFITS_MENU_BUTTON,
  TRANSFERS_MENU_BUTTON,
  PAYMENTS_MENU_BUTTON,
  CREDIT_MENU_BUTTON,
  RIPLEY_POINTS_MENU_BUTTON,
  OPTIONS_MENU_BUTTON,
  AGENCIES_MENU_BUTTON,
  KASNET_MENU_BUTTON
};

export const MOBILE_HOME_BUTTONS = [
  TRANSFERS_MENU_BUTTON,
  PAYMENTS_MENU_BUTTON,
  CREDIT_MENU_BUTTON,
  SEF_MENU_BUTTON,
  BENEFITS_MENU_BUTTON,
];


export const MENU_ROUTES = [
  {
    title: 'Inicio',
    route: `/${HOME_ROUTE}`,
    icon: 'i-menu-home',
    class: '',
  },
  {
    title: 'Cliente Gold y Silver',
    route: `/home/customer`,
    icon: 'i-menu-transfer',
    class: '',
  },
  {
    title: 'Pagos',
    route: `/${PAYMENTS_ROUTE}`,
    icon: 'i-menu-payment',
    class: '',
  },
];

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-queson-ripleypuntos',
  templateUrl: './queson-ripleypuntos.component.html',
  styleUrls: ['./queson-ripleypuntos.component.scss'],
})
export class QuesonRipleypuntosComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}

import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CREATE_TOKEN,
  CREATE_TOKEN_PATH,
  REFRESH_TOKEN,
  REFRESH_TOKEN_PATH,
  REFRESH_TOKEN_REQUEST_DIFFERENTIAL,
  RESCUE_IDENTIFIER,
  RESCUE_SESSION_TOKEN,
  RESCUE_TOKEN_EXPIRES,
  SECONDS_TO_MILLISECONDS,
  USER_ID,
  USER_INFO,
} from 'src/app/common/constants/auth.constants';
import { INFORMATIVE_MODALS_VIEWED } from 'src/app/common/constants/misc.constants';
import {
  LEAD_EXECUTION_CODE,
  OFFERS_LOAN,
  PRODUCT_CDD,
  PRODUCT_EFEX,
  PRODUCT_SEF,
} from 'src/app/common/constants/offerings.constants';
import { OPEN_ACCOUNT_SUCCESS_KEY, PRE_REGISTER_KEY, TYPE_ACCOUNT_SELECTED } from 'src/app/common/constants/open-account.constants';
import { MENU_OPTIONS } from 'src/app/common/constants/routes.constants';
import { IOAuthTokens, IOAuthTokensSRP, IUserLogin } from 'src/app/common/interfaces/auth.interface';
import { REVOCATION_MOCK, SIGN_IN_MOCK } from 'src/app/common/mocks/auth.mocks';
import { IDENTIFIER_MOCK } from 'src/app/common/mocks/device.mocks';
import { LoginResponseModel } from 'src/app/common/models/auth-response.model';
import { environment } from 'src/environments/environment';
// import { Storage } from '@ionic/storage';
import { HttpService } from 'src/app/services/http/http.service';
import { HttpSrpService } from 'src/app/services/http/http.srp.service';
import { UserService } from 'src/app/services/user/user.service';
import { UtilsService } from 'src/app/services/utils/utils';
import { Subject, Subscription } from 'rxjs';

// import { FirebaseService } from '@services/firebase/firebase.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // private IDENTITYENDPOINT: string = environment.SRP_UrlIdentity;
  private IDENTITYENDPOINT: string = environment.BASE_BACKEND + '/identity/connect/token';
  private IDENTITYENDPOINT_Q: string = environment.BACKEND_IDENTITY_Q + '/identity/connect/token';
  private APICREDENTIAL: string = environment.SRP_Credential;
  private APICREDENTIAL_Q: string = environment.SRP_Credential_Q;

  headers = new HttpHeaders().set(
    'Content-Type',
    'application/x-www-form-urlencoded;'
  );


  public isLoggedIn: boolean;
  public currentToken: IOAuthTokens;
  public subscription: Subscription;
  public identifier: number;
  public refreshTokenExpired: Subject<boolean>;
  public refreshTokenFailed: boolean;
  public refreshTokenRunning: boolean;
  public tokenExpiresMillis: number;
  public refreshTokenExpiresMillis: number;

  constructor(
    private http: HttpService,
    private httpService: HttpSrpService,
    private userService: UserService,
    // private storage: Storage,
    // private firebaseService: FirebaseService,
    private utilsService: UtilsService,
  ) {
    this.refreshTokenExpired = new Subject<boolean>();
  }

  getAuthentication0() {
    const url = this.IDENTITYENDPOINT;
    const body = this.APICREDENTIAL;

    return this.httpService.post<LoginResponseModel>(url, body, { headers: this.headers });
  }

  getAuthenticationTokenCmsQ() {
    const url = this.IDENTITYENDPOINT_Q;
    const body = this.APICREDENTIAL_Q;

    return this.httpService.post<LoginResponseModel>(url, body, { headers: this.headers });
  }

  getAuthentication() {

    const url = this.IDENTITYENDPOINT;
    const encodedObject = this.APICREDENTIAL;

    return this.http.post(url, encodedObject, REVOCATION_MOCK, this.headers);

  }

  public getAuthentication1(): Promise<IOAuthTokensSRP> {

    const url = this.IDENTITYENDPOINT;
    const encodedObject = this.APICREDENTIAL;

    return this.http.post<IOAuthTokensSRP>(url, encodedObject, IDENTIFIER_MOCK, this.headers).toPromise();
  }

  public async getAuthentication2(): Promise<void> {

    const url = this.IDENTITYENDPOINT;
    const encodedObject = this.APICREDENTIAL;

    await this.http.post(url, encodedObject, REVOCATION_MOCK, this.headers).toPromise();
    // this.cleanSessionInfo();
    return Promise.resolve();
  }

  public isAuthenticated(): boolean {
    this.checkIfReloadedPage();
    return this.currentToken !== null && this.currentToken !== undefined;
  }

  private checkIfReloadedPage(): void {
    if (!this.currentToken) {
      const token = this.getSessionToken();
      if (token) {
        this.setSessionInfo(token);
        UtilsService.setInformativeModalsViewed();
      }
      const identifier = UtilsService.getIdentifier();
      if (identifier) {
        this.identifier = identifier;
        this.setIdentifier(identifier);
      }
    }
    if (!this.tokenExpiresMillis || !this.refreshTokenExpiresMillis) {
      const tokenExpires = this.getSessionTokenExpires();
      if (tokenExpires) {
        this.setTokenExpires(tokenExpires);
        UtilsService.setInformativeModalsViewed();
      }
    }
  }

  public async signIn(loginInfo: IUserLogin, identifier: number): Promise<void> {
    this.identifier = identifier;
    // this.storage.set(USER_INFO, loginInfo);
    this.setIdentifier(identifier);
    return await this.generateToken(loginInfo, CREATE_TOKEN, identifier);
  }

  private setIdentifier(identifier: number): void {
    sessionStorage.setItem(RESCUE_IDENTIFIER, JSON.stringify(identifier));
  }

  public async signOut(): Promise<void> {
    let headers = {} as HttpHeaders;
    const identifier = this.identifier;
    headers = new HttpHeaders().set('Identifier', identifier.toString());
    headers = headers.set('Channel', this.utilsService.getChannelCode());
    const encodedObject = { revokeToken: this.currentToken.refreshToken };
    const url = environment.BASE_BACKEND_WORKER + '/admin/user/revoke/token';

    await this.http.post(url, encodedObject, REVOCATION_MOCK, headers).toPromise();
    this.cleanSessionInfo();
    return Promise.resolve();
  }

  public setTokenExpires(tokenExpires: { token: number, refresh: number }): void {
    this.tokenExpiresMillis = tokenExpires.token;
    this.refreshTokenExpiresMillis = tokenExpires.refresh;
    sessionStorage.setItem(RESCUE_TOKEN_EXPIRES, JSON.stringify({
      token: this.tokenExpiresMillis,
      refresh: this.refreshTokenExpiresMillis
    }));
  }

  public async refreshIfTokenIsInvalid(): Promise<boolean> {
    if (!this.currentToken) { return false; }
    const currentTime = Date.now();
    if (currentTime >= this.refreshTokenExpiresMillis) {
      if (!this.refreshTokenFailed) { this.refreshTokenExpired.next(true); }
      return this.refreshTokenFailed = true;
    }
    if (currentTime >= this.tokenExpiresMillis && !this.refreshTokenRunning) {
      this.refreshTokenRunning = true;
      const requestBody = { refreshToken: this.currentToken.refreshToken };

      await this.generateToken(requestBody, REFRESH_TOKEN, this.identifier);
      this.refreshTokenRunning = false;
    }
    return false;
  }

  private async generateToken(encodedObject: object, serviceType: number, identifier: number): Promise<void> {
    let headers = {} as HttpHeaders;
    if (identifier) {
      headers = new HttpHeaders().set('Identifier', identifier.toString());
      headers = headers.set('Channel', this.utilsService.getChannelCode());
    } else {
      headers = new HttpHeaders().set('Channel', this.utilsService.getChannelCode());
    }
    const urlAction = serviceType === CREATE_TOKEN ? CREATE_TOKEN_PATH : REFRESH_TOKEN_PATH;
    const url = environment.BASE_BACKEND_WORKER + `/admin/user/${urlAction}/token`;
    try {
      const token = await this.http.post<IOAuthTokens>(url, encodedObject, SIGN_IN_MOCK, headers).toPromise();
      this.setSessionInfo(token);
      if (urlAction === CREATE_TOKEN_PATH) {
        // await this.firebaseSignInWithCustomToken(token);
      }
    } catch (e) {
      this.refreshTokenFailed = true;
      this.refreshTokenExpired.next(true);
      if (urlAction === CREATE_TOKEN_PATH) { throw e.error; }
    }
  }

  public getSessionToken(): IOAuthTokens {
    return JSON.parse(sessionStorage.getItem(RESCUE_SESSION_TOKEN));
  }

  public getUserIdToken(): string {
    return sessionStorage.getItem(USER_ID);
  }

  private getSessionTokenExpires(): { token: number, refresh: number } {
    return JSON.parse(sessionStorage.getItem(RESCUE_TOKEN_EXPIRES));
  }

  private getFbSessionToken(): string {
    const token = this.getSessionToken();
    if (token) { return token.fbSession ? token.fbSession : null; }
    return null;
  }

  public setSessionInfo(token: IOAuthTokens): void {
    const storedFbSession = this.getFbSessionToken();
    if (storedFbSession && !token.fbSession) {
      token.fbSession = storedFbSession;
    }
    this.currentToken = token;
    sessionStorage.setItem(RESCUE_SESSION_TOKEN, JSON.stringify(token));
    sessionStorage.setItem(USER_ID, `${token.userId}`);
    this.refreshTokenFailed = false;
    this.setTokenExpires({
      token: Date.now() + REFRESH_TOKEN_REQUEST_DIFFERENTIAL * this.currentToken.expiresIn * SECONDS_TO_MILLISECONDS,
      refresh: Date.now() + this.currentToken.refreshExpiresIn * SECONDS_TO_MILLISECONDS,
    });
  }

  // private async firebaseSignInWithCustomToken(token: IOAuthTokens): Promise<void> {
  //   if (environment.ENV !== 'local') {
  //     try {
  //       const credential = await this.firebaseService.signInWithCustomToken(token.fbSession);
  //       if (!credential) {
  //         console.warn('Cannot authenticate with Firebase');
  //       }
  //       token.fbSession = await credential.user.getIdToken();
  //     } catch (error) {
  //       console.error('firebaseSignInWithCustomToken Error', error);
  //     }
  //   }
  //   return this.setSessionInfo(token);
  // }

  private cleanSessionInfo(): void {
    this.userService.userInformation = null;
    this.currentToken = null;
    sessionStorage.removeItem(RESCUE_SESSION_TOKEN);
    sessionStorage.removeItem(RESCUE_IDENTIFIER);
    sessionStorage.removeItem(PRODUCT_SEF);
    sessionStorage.removeItem(PRODUCT_CDD);
    sessionStorage.removeItem(PRODUCT_EFEX);
    // this.storage.remove(USER_INFO);
    sessionStorage.removeItem(PRE_REGISTER_KEY);
    sessionStorage.removeItem(LEAD_EXECUTION_CODE);
    sessionStorage.removeItem(OFFERS_LOAN);
    sessionStorage.removeItem(RESCUE_TOKEN_EXPIRES);
    sessionStorage.removeItem(OPEN_ACCOUNT_SUCCESS_KEY);
    sessionStorage.removeItem(INFORMATIVE_MODALS_VIEWED);
    sessionStorage.removeItem(TYPE_ACCOUNT_SELECTED);
    sessionStorage.removeItem(USER_ID);
    localStorage.removeItem(MENU_OPTIONS);

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }



}

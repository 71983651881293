import { DOCUMENT, formatNumber, formatDate } from '@angular/common';
import { Component, Inject, OnInit, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { ICustomerResponse } from '@common/interfaces/customer.interface';
import { CanjeMillasService } from '@services/canjes/canje-millas.service';

@Component({
  selector: 'app-detalle-canje',
  templateUrl: './detalle-canje.component.html',
  styleUrls: ['./detalle-canje.component.scss'],
})
export class DetalleCanjeComponent implements OnInit {
  //---parameter from confirmar paquete
  customerData: ICustomerResponse;
  
  public showItemsText: boolean = false;
  public param : any;
  paramTitulos : any;
  public textoTitulo: string = '';
  public textoEstadoFecha: string = '';
  public textoCanjeIgual: string = '';
  pathMisCanjesLatamPass = 'mis-canjes-latam?type=miscanjes';
  pathCanjeLatamPassDNI = 'canje-latam-pass?type=miscanjes';
  pathInicioCanjeLatamPass = 'canjes?category=latampass';

  constructor(private canjeMillasService : CanjeMillasService,
      private router: Router,
      @Inject(DOCUMENT) private document: Document,
      @Inject(LOCALE_ID) public locale: string
  ) { }

  ngOnInit() {
    if (history.state.data === undefined) {
      this.document.location.href = '/' + this.pathCanjeLatamPassDNI;
    }
    else {
      this.customerData = history.state.data;
    }

    this.param = this.canjeMillasService.getConsultaEstadoExito();
    // this.param = this.getDataDummy();
    console.log('app-detalle-canje');
    // console.log(this.param);
    // this.paramTemp = paramExito;
    if (this.param.length === 0) {
      this.document.location.href = '/' + this.pathCanjeLatamPassDNI;
    }
    else {
      const status = this.param.status;
      const formatoPoints = formatNumber(this.param.points, this.locale, '1.0-5');
      const formatoMiles = formatNumber(this.param.value, this.locale, '1.0-5');
      const textTitulo1 = '¡Felicidades! Has canjeado con éxito tu paquete de ' + formatoMiles +' millas Latam Pass por ' + formatoPoints + ' Ripley Puntos Go';
      const textTitulo2 = 'Tu paquete de ' + formatoMiles +' millas Latam Pass por ' + formatoPoints + ' Ripley Puntos Go está siendo validado por LATAM Airlines';
      const textTitulo3 = 'Canje de ' + formatoMiles +' millas Latam Pass por ' + formatoPoints + ' Ripley Puntos Go';
      const textTitulo4 = 'Error Canje de ' + formatoMiles +' millas Latam Pass por ' + formatoPoints + ' Ripley Puntos Go, no se proceso';

      const textoEstadoFecha1 = 'Canjeado ' + this.param.dateRedeem;
      const textoEstadoFecha2 = 'Este proceso puede durar hasta 24 horas.';
      const textoEstadoFecha3 = 'Este canje fue cancelado por LATAM Airlines y tus puntos han sido reembolsados a tus Ripley Puntos Go.';
      const textoEstadoFecha4 = 'Intento de canje fallido.';

      const format = 'dd/MM/yyyy';
      const myDate = '2019-06-29';
      const locale1 = 'en-US';
      const locale2 = 'es-ES';
      const textoEstadoFecha111 = formatDate(myDate, format, this.locale);
      console.log(textoEstadoFecha1);
      console.log(this.locale);

      this.textoCanjeIgual = formatoPoints + ' Puntos Go = ' + formatoMiles + ' Millas';

      if (status == 'completed') {
        this.textoTitulo = textTitulo1;
        this.textoEstadoFecha = textoEstadoFecha1;
        this.showItemsText = true;
      }
      else if (status == 'pending') {
        this.textoTitulo = textTitulo2;
        this.textoEstadoFecha = textoEstadoFecha2;
        this.showItemsText = true;
      }
      else if (status == 'latam-redeem-cancel') {
        this.textoTitulo = textTitulo3;
        this.textoEstadoFecha = textoEstadoFecha3;
      }
      else if (status == 'insufficient-points') {
        this.textoTitulo = textTitulo4;
        this.textoEstadoFecha = textoEstadoFecha4;
      }
      else if (status == 'error-service-redeem') {
        this.textoTitulo = textTitulo4;
        this.textoEstadoFecha = textoEstadoFecha4;
      }
      else if (status == 'error-service-latam') {
        this.textoTitulo = textTitulo4;
        this.textoEstadoFecha = textoEstadoFecha4;
      }
      else {
        this.textoTitulo = textTitulo4;
        this.textoEstadoFecha = textoEstadoFecha4;
      }
      this.paramTitulos = this.param.product.image;
      console.log(this.paramTitulos);
    }

  }
  volverMisCanjesLatam(){
    // this.document.location.href = '/' + this.pathMisCanjesLatamPass;
    this.router.navigateByUrl(this.pathMisCanjesLatamPass, { state: { data: this.customerData } });
  }
  volverInicio(){
    this.document.location.href = '/' + this.pathInicioCanjeLatamPass;
  }

  getDataDummy() {
    const dataQA_Error = {
      "product": {
          "index": 0,
          "backendID": 171,
          "productId": null,
          "actionSelectProduct": "Selecciona el paquete que quieres canjear.",
          "active": true,
          "button": {
              "show": false,
              "text": "",
              "url": ""
          },
          "category": "latam",
          "confirmationSelectedTitle": "Estas canjeando un paquete de:",
          "errorMessages": {
              "limitExceeded": "Has canjeado la cantidad máxima para este producto.",
              "outOfStock": "Este producto solo se puede canjear en tiendas Ripley."
          },
          "fullName": "PAQ DE 1,000 MILLAS LATAM",
          "giftcardColor": "",
          "giftcardMultiplier": 0,
          "hasMultiplier": false,
          "image": "https://firebasestorage.googleapis.com/v0/b/hbk-qa.appspot.com/o/products%2Fimages%2F11-02-2022_10%3A18%3A49_20211029_RPGO_ADAPTACIONES_CANJE_DIGITAL_MILLAS_LATAM_PASS_01%201.svg?alt=media&token=e0aedfe2-7239-4818-8853-a63c5c95aeb8",
          "limit": 5,
          "points": 4500,
          "promotionCode": null,
          "selectedSubtitle": "RIPP1",
          "selectedTitle": "4,500 Puntos = 1,000 Millas LATAM Pass",
          "stock": 10,
          "stockActive": false,
          "stockMessage": "Stock disponible:",
          "successMessage": "Has canjeado un paquete de 1,000 millas latam por 4,500 Puntos Ripley Puntos Go.",
          "successfulExchanges": 7,
          "termsAndConditionsPDF": "https://firebasestorage.googleapis.com/v0/b/hbk-qa.appspot.com/o/defaultUploads%2F01-09-2021_09%3A48%3A42_TERMINOS%20Y%20CONDICIONES.pdf?alt=media&token=9f651199-872a-4d36-bf4f-3b6718cf8aed",
          "termsAndConditionsText": "",
          "termsAndConditionsURL": "",
          "updatedAt": {},
          "value": 1
      },
      "productTransactionId": "08wgH8DzHCQpCpU0HnQ4",
      "latamcode": "RIPP1",
      "nOfPointsAvailable": 200925,
      "hourRedeem": 0,
      "dateRedeem": "19/12/2022",
      "memberId": "51700372026",
      "transactionId": "0",
      "active": false,
      "backendID": "171",
      "button": null,
      "category": "latam",
      "code": "",
      "documentType": "C",
      "documentNumber": "70037202",
      "daysToRedeemBeforeItExpires": 0,
      "createdAt": {},
      "eventId": "9e9e6673-e0eb-44ce-b8d6-662dd7564ac8-0",
      "expirationDate": {},
      "fullname": "PAQ DE 1,000 MILLAS LATAM",
      "latamName": "RIPP1",
      "origin": "rpgo",
      "points": 4500,
      "sku": "GZj35cl4bMlmu5jScUkB",
      "status": "error-service-redeem",
      "token": null,
      "updatedAt": {},
      "userId": "C-70037202",
      "miles": 1,
      "value": 1,
      "yearRedeem": 2022
    };

    const dataQA_Completed = {
      "product": {
          "index": 0,
          "backendID": 171,
          "productId": null,
          "actionSelectProduct": "Selecciona el paquete que quieres canjear.",
          "active": true,
          "button": {
              "show": false,
              "text": "",
              "url": ""
          },
          "category": "latam",
          "confirmationSelectedTitle": "Estas canjeando un paquete de:",
          "errorMessages": {
              "limitExceeded": "Has canjeado la cantidad máxima para este producto.",
              "outOfStock": "Este producto solo se puede canjear en tiendas Ripley."
          },
          "fullName": "PAQ DE 1,000 MILLAS LATAM",
          "giftcardColor": "",
          "giftcardMultiplier": 0,
          "hasMultiplier": false,
          "image": "https://firebasestorage.googleapis.com/v0/b/hbk-qa.appspot.com/o/products%2Fimages%2F11-02-2022_10%3A18%3A49_20211029_RPGO_ADAPTACIONES_CANJE_DIGITAL_MILLAS_LATAM_PASS_01%201.svg?alt=media&token=e0aedfe2-7239-4818-8853-a63c5c95aeb8",
          "limit": 100,
          "points": 2500,
          "promotionCode": null,
          "selectedSubtitle": "RIPP1",
          "selectedTitle": "2,500 Puntos = 1,000 Millas LATAM Pass",
          "stock": 0,
          "stockActive": false,
          "stockMessage": "Stock disponible:",
          "successMessage": "Has canjeado un paquete de 1,000 millas latam por 4,500 Puntos Ripley Puntos Go.",
          "successfulExchanges": 28,
          "termsAndConditionsPDF": "https://firebasestorage.googleapis.com/v0/b/hbk-qa.appspot.com/o/defaultUploads%2F01-09-2021_09%3A48%3A42_TERMINOS%20Y%20CONDICIONES.pdf?alt=media&token=9f651199-872a-4d36-bf4f-3b6718cf8aed",
          "termsAndConditionsText": "",
          "termsAndConditionsURL": "",
          "updatedAt": {},
          "value": 1000
      },
      "productTransactionId": "0MyPsBp88lfL19p7sWRQ",
      "latamcode": "RIPP1",
      "nOfPointsAvailable": 118926,
      "hourRedeem": 2023121318,
      "dateRedeem": "13/12/2023",
      "memberId": "51700372026",
      "transactionId": "125698",
      "active": false,
      "backendID": "171",
      "button": null,
      "category": "latam",
      "code": "",
      "documentType": "C",
      "documentNumber": "70037202",
      "daysToRedeemBeforeItExpires": 0,
      "createdAt": {},
      "eventId": "2be9c049-5b76-4464-9446-f961f52d84b3-0",
      "expirationDate": {},
      "fullname": "PAQ DE 1,000 MILLAS LATAM",
      "latamName": "RIPP1",
      "origin": "rpgo",
      "points": 2500,
      "sku": "GZj35cl4bMlmu5jScUkB",
      "status": "completed",
      "token": null,
      "updatedAt": {},
      "userId": "C-70037202",
      "miles": 1000,
      "value": 1000,
      "yearRedeem": 2023
    };

    const dataPRD_Compled = {
      "product": {
          "index": 0,
          "backendID": 561,
          "productId": null,
          "actionSelectProduct": "Selecciona el paquete que quieres canjear.",
          "active": true,
          "button": {
              "show": false,
              "text": "",
              "url": ""
          },
          "category": "latam",
          "confirmationSelectedTitle": "Estas canjeando un paquete de:",
          "errorMessages": {
              "limitExceeded": "Has canjeado la cantidad máxima para este producto.",
              "outOfStock": "Producto agotado, solo lo puedes canjear en tiendas Ripley."
          },
          "fullName": "PAQ DE 600 MILLAS LATAM",
          "giftcardColor": "",
          "giftcardMultiplier": 0,
          "hasMultiplier": false,
          "image": "https://firebasestorage.googleapis.com/v0/b/hbk-prd.appspot.com/o/products%2Fimages%2F05-07-2023_09%3A52%3A21_latam-1500.png?alt=media&token=8c889ebf-37ef-4e8d-83ec-dc71492e1493",
          "limit": 1000000,
          "points": 1500,
          "promotionCode": null,
          "selectedSubtitle": "RIPP8",
          "selectedTitle": "1,500 Puntos = 600 Millas LATAM Pass",
          "stock": 0,
          "stockActive": false,
          "stockMessage": "Stock disponible:",
          "successMessage": "Has canjeado un paquete de 600 millas latam por 1,500 Puntos Ripley Puntos Go.",
          "successfulExchanges": 1253,
          "termsAndConditionsPDF": "https://firebasestorage.googleapis.com/v0/b/hbk-prd.appspot.com/o/defaultUploads%2F26-09-2023_12%3A40%3A48_Legal%20RPGO-LATAM%20Pass%202023.pdf?alt=media&token=b649fb9c-c741-4560-a05b-9c55556f1731",
          "termsAndConditionsText": "",
          "termsAndConditionsURL": "",
          "updatedAt": {},
          "value": 600
      },
      "productTransactionId": "16Rt5xC4WihxzUmBzPF9",
      "actionSelectProduct": null,
      "active": false,
      "backendID": "561",
      "button": null,
      "canRedeem": false,
      "category": "latam",
      "code": "",
      "confirmationSelectedTitle": null,
      "createdAt": {},
      "enoughPoints": false,
      "error": null,
      "errorMessages": null,
      "eventId": "80ac8ef0-1329-41e0-855e-60d6df3878be-0",
      "expirationDate": {},
      "fullname": "PAQ DE 600 MILLAS LATAM",
      "giftcardColor": null,
      "giftcardMultiplier": 0,
      "hasMultiplier": false,
      "image": null,
      "limit": 0,
      "origin": "rpgo",
      "points": 1500,
      "promotionCode": null,
      "selectedSubtitle": null,
      "selectedTitle": null,
      "sku": "8262QQ8azrAxhzmlDfbn",
      "status": "completed",
      "stock": 0,
      "stockActive": false,
      "stockMessage": null,
      "successMessage": null,
      "successfulExchanges": 0,
      "termsAndConditionsPDF": null,
      "termsAndConditionsText": null,
      "termsAndConditionsURL": null,
      "token": null,
      "updatedAt": {},
      "userId": "C-09797105",
      "value": 600,
      "yearRedeem": 2024
    };
    return dataQA_Completed;
  }
}

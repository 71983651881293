import { Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';

@Component({
  selector: 'app-beneficios-categorias',
  templateUrl: './beneficios-categorias.component.html',
  styleUrls: ['./beneficios-categorias.component.scss'],
})
export class BeneficiosCategoriasComponent implements OnInit {
  // private fragment: string;
  OpenTerminosBeneficios = false;
  href: string = "";
  IsSegmentacion:boolean = false;

  constructor(private router:Router) {

    this.href = this.router.url;
    if(this.href!="/segmentacion"){
      this.IsSegmentacion=true;
   
    }
   }

  ngOnInit() {
    // console.log('BeneficiosCategorias');
    // this.fragment = this.activatedRoute.snapshot.fragment;
    // console.log(this.fragment);

    // if (this.fragment != null) {
    //   // console.log('querySelector');
    //   // document.querySelector('#' + this.fragment).scrollIntoView();
    // }
   

  }

  btnEnviar() {
    window.location.href = 'https://www.bancoripley.com.pe/promociones/index.html';
  }

  openDialog() {
    this.OpenTerminosBeneficios = true;
  }

  closeDialog() {
    this.OpenTerminosBeneficios = false;
  }
}

import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from '@services/utils/utils';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SegmentationService } from 'src/app/services/segmentation/segmentation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-prime-canje-promo',
  templateUrl: './client-prime-canje-promo.component.html',
  styleUrls: ['./client-prime-canje-promo.component.scss']
})
export class ClientPrimeCanjePromoComponent implements OnInit {

  myOptions = {
    'placement': 'bottom',
    'showDelay': 0,
    'hideDelay': 300,
    'trigger': 'hover',
    'tooltip-class':'tooltip-inner'
  }
  MensajeTooltip='No se consideran los puntos <br> obtenidos en campañas <br> promocionales para las categorías';


  typeDoc = '01';
  placeHolderIdentidad = 'Número de DNI';
  isValid = true;
  mostrarPantallaCarga = false;
  min_length_nroDocumento: number;
  max_length_nroDocumento: number;
  nombre = '';
  puntos = 0;
  segmento = '';
  existeCliente = false;
  noExisteCliente = false;
  pathSegmentacion = 'segmentacion';
  identidadForm: FormGroup;
  linkHbk ='https://hbk.qa.bancoripley.com.pe/login';
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private segmentationService: SegmentationService,
    private utilsService: UtilsService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.createForm();
    console.log(this.existeCliente);
  }
  createForm() {
    this.identidadForm = this.formBuilder.group({
      nroDocumentoPrestamo: [{ value: null, disabled: false }, [Validators.required]],
    });

    this.max_length_nroDocumento = 8;
  }
  get control() { return this.identidadForm.controls; }
  selectedTypeDoc(type) {
    this.typeDoc = type;
    this.isValid = true;

    this.identidadForm.get('nroDocumentoPrestamo').setValue('');
    if (type === '01') {
      this.identidadForm.get('nroDocumentoPrestamo').setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(8)]);
      this.min_length_nroDocumento = 5;
      this.max_length_nroDocumento = 8;
      this.placeHolderIdentidad = 'Número de DNI';
    } else {
      this.identidadForm.get('nroDocumentoPrestamo').setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(11)]);
      this.min_length_nroDocumento = 5;
      this.max_length_nroDocumento = 11;
      this.placeHolderIdentidad = 'Número de Carnet de Extranjería';
    }

  }
  onChange() {
    if (this.isValid === false) {
      this.nroDocumentoValida();
    }
  }
  keyPressNumbers(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  onConsultar() {
    if (environment.ENV === 'qa') {
      this.linkHbk ='https://hbk.qa.bancoripley.com.pe/login';
    } else {
      this.linkHbk = 'https://bancaenlinea.bancoripley.com.pe/login';
    }
    const nroDocumentoValidate = this.nroDocumentoValida();
    if (!nroDocumentoValidate) {
      // marcacionErrorNroDocumento();
    }
    if (nroDocumentoValidate) {
      const token = '';
      this.consultarPointsClient(token);
    }

  }
  consultarPointsClient(token) {
    const document = this.identidadForm.get('nroDocumentoPrestamo').value;
    const client = {
      typeDocument: this.typeDoc,
      numberDocument: document,
      flagClient: '1',
      captcha: token
    };
    this.pantallaDeCarga(true);
    this.getPointsClient(client);
  }
  pantallaDeCarga(booleano) {
    this.mostrarPantallaCarga = booleano;
  }
  getPointsClient(client) {
    this.authService.getAuthentication0().subscribe(
      (response: any) => {
        const status = response.status;
        const bodyResponse = response.body;
        switch (status) {
          case 200:
            const token = bodyResponse.access_token;
            this.segmentationService.getSetmentantion(client, token)
              .then((data) => {
                //console.log(data);
                if (data.result != null) {
                  if (data.result[0] != null) {
                    if (data.result.length > 0) {
                      const list = data.result[0].data;
                      for (const row of list) {
                        if (row.customFields.length > 0) {
                          const listData = row.customFields;
                          //console.log(listData);
                          localStorage.setItem('objData', JSON.stringify(listData));
                          this.ripleyPuntosAnalytics('Segmentacion_Ripleypuntos', 'Consulta', 'Consulta Exitosa');
                          //this.RegistrarNumeroDocumentoConsultado(this.typeDoc, client.numberDocument, '02', token);
                          //this.document.location.href = '/' + this.pathSegmentacion;
                          // return nombre y puntos

                          const listNombre = listData.filter(val => (val.name.toUpperCase() === 'NOMBRE') || (val.name.toUpperCase() === 'RIPLEYPUNTOS') || (val.name.toUpperCase() === 'CLASE_PUNTOS'));
                          this.segmento = listNombre[0].value.substring(4).trim();
                          this.nombre = listNombre[1].value;
                          this.puntos = Number(listNombre[2].value);
                          this.existeCliente = true;
                          this.noExisteCliente = false;
                          this.pantallaDeCarga(false);
                          //console.log(listNombre);
                          //console.log(this.segmento);

                        }
                      }
                    } else {
                      console.log('caso 2');
                      this.ripleyPuntosAnalytics('Segmentacion_Ripleypuntos', 'Consulta', 'Consulta No Exitosa');
                      //this.RegistrarNumeroDocumentoConsultado(this.typeDoc, client.numberDocument, '02', token);
                      //this.document.location.href = '/' + this.pathSegmentacion;
                      this.noExisteCliente = true;
                      this.existeCliente = false;
                      this.pantallaDeCarga(false);

                    }
                  } else {
                    console.log('caso 3');
                    this.ripleyPuntosAnalytics('Segmentacion_Ripleypuntos', 'Consulta', 'Consulta No Exitosa');
                    //this.RegistrarNumeroDocumentoConsultado(this.typeDoc, client.numberDocument, '02', token);
                    //this.document.location.href = '/' + this.pathSegmentacion;
                    this.noExisteCliente = true;
                    this.existeCliente = false;
                    this.pantallaDeCarga(false);
                  }
                } else {
                  console.log('caso 4');
                  this.ripleyPuntosAnalytics('Segmentacion_Ripleypuntos', 'Consulta', 'Consulta No Exitosa');
                  //this.RegistrarNumeroDocumentoConsultado(this.typeDoc, client.numberDocument, '02', token);
                  //this.document.location.href = '/' + this.pathSegmentacion;
                  this.noExisteCliente = true;
                  this.existeCliente = false;
                  this.pantallaDeCarga(false);
                }
                // limpiar texto DNI
                this.identidadForm.get('nroDocumentoPrestamo').setValue('');
                this.pantallaDeCarga(false);
              })
              .catch(() => {
                console.log('catch');
                this.pantallaDeCarga(false);
              });
            break;
          default:
            break;
        }
      },
      (error) => {
        // const errorBody = error.error;
        // const status = error.status;
        this.pantallaDeCarga(false);
      }
    );
  }
  RegistrarNumeroDocumentoConsultado(TypeDocument, document, TipoIdForm, token) {
    const _tipodocumento = (TypeDocument === '01' ? 'DNI' : 'CE');
    const _formid = (TipoIdForm === '01' ? 'ID_FORM_SEGMENTACION' : 'ID_FORM_RIPLEYPUNTOS');
    const trama = 'formId►' + _formid + '▲names►▲firstName►▲lastName►▲documentType►' + _tipodocumento + '▲documentNumber►' + document + '▲email►▲mobilePhone►▲promotionCode►▲purchaseNumber►▲turnBuy►▲businessName►▲dataTreatment►';

    const InscriptionFormCms = {
      trama: trama,
      captcha: '',
      sourcecaptcha: 'RP'
    };
    if (environment.ENV === 'SOLOQA') {
      console.log(environment.ENV);
      this.authService.getAuthenticationTokenCmsQ().subscribe(
        (response: any) => {
          const status = response.status;
          const bodyResponse = response.body;
          const statusOk = 200;
          switch (status) {
            case statusOk:
              const tokenCms = bodyResponse.access_token;
              
              this.segmentationService.RegisterInscripcionNroDocumentoFormCMS(InscriptionFormCms, token, tokenCms)
                .then((data) => {
                  //console.log(data);
                  if (data.result != null) {

                  } else {

                  }
                  this.pantallaDeCarga(false);
                })
                .catch(() => {
                  console.log('error al registrar documento');
                });
              break;
  
            default:
  
              break;
          }
  
        },
        (error) => {
          // const errorBody = error.error;
          // const status = error.status;
          console.log("Error getAuthenticationTokenCmsQ");
        }
      );
    }
    else {
      this.segmentationService.RegisterInscripcionNroDocumentoFormCMS(InscriptionFormCms, token, "")
      .then((data) => {
        //console.log('InscripcionNroDoc');
        //console.log(data);
        if (data.result != null) {

        } else {

        }
        this.pantallaDeCarga(false);
      })
      .catch(() => {
        console.log('error al registrar documento');
      });
    }
    
  }

  nroDocumentoValida() {
    this.isValid = true;
    const nroDocumento = this.identidadForm.get('nroDocumentoPrestamo').value;

    if (!(nroDocumento && nroDocumento !== '')) {
      this.isValid = false;
    } else {
      if (this.typeDoc === '01') {
        if (nroDocumento.length !== 8) {
          this.isValid = false;
        }
      } else {
        if (nroDocumento.length < 9 || nroDocumento.length > 11) {
          this.isValid = false;
        }
      }
    }
    return this.isValid;
  }

  ripleyPuntosAnalytics(event: string, description: string, action: string, additionalInformation?: any[]) {

    this.utilsService.addEventToTagManagerAndFirebase(event, description, action, additionalInformation);

  }

  regresarFormulario(){
    this.existeCliente=false;
    this.noExisteCliente=false;
    this.nombre='';
    this.puntos=0;
    this.segmento='';
  }

}

import { DEFAULT_ERROR_ALERT_HEADER, DEFAULT_ERROR_ALERT_SUBHEADER } from 'src/app/common/constants/error.constants';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { IAlertButton } from 'src/app/common/interfaces/default.interface';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  isAlertOpen: boolean;
  constructor(
    private alertController: AlertController,
    private router: Router
  ) {
    this.isAlertOpen = false;
  }

  public async openErrorAlert(
    routerAlert?: string,
    withoutExitButton?: boolean,
    withoutRetryButton?: boolean,
    optionalHeader?: string,
    optionalSubHeader?: string,
    optionalFirstButton?: IAlertButton,
    optionalSecondButton?: IAlertButton,
  ) {
    let alert: HTMLIonAlertElement;
    const header = optionalHeader ? optionalHeader : DEFAULT_ERROR_ALERT_HEADER;
    const subHeader = optionalSubHeader ? optionalSubHeader : DEFAULT_ERROR_ALERT_SUBHEADER;
    const buttons = [
      {
        text: optionalFirstButton ? optionalFirstButton.text : 'Reintentar',
        role: optionalFirstButton ? optionalFirstButton.role : 'cancel',
        cssClass: optionalFirstButton ? optionalFirstButton.cssClass : '',
        handler: () => {
          this.setOpenedAlert(false);
          alert.dismiss(true);
          return true;
        }
      },
      {
        text: optionalSecondButton ? optionalSecondButton.text : 'Salir',
        role: optionalSecondButton ? optionalSecondButton.role : 'cancel',
        cssClass: optionalSecondButton ? optionalSecondButton.cssClass : '',
        handler: () => {
          this.setOpenedAlert(false);
          alert.dismiss(false);
          return true;
        }
      }
    ];
    if (withoutExitButton) { buttons.pop(); }
    if (withoutRetryButton) { buttons.shift(); }
    if (!this.isAlertOpen) {
      alert = await this.alertController.create({
        header,
        subHeader,
        buttons,
      });
      this.setOpenedAlert(true);
      if (this.router.url !== routerAlert) { alert = null; }
      return alert;
    }
    return null;
  }

  public async simpleMessageError(title: string, message: string, button?: string): Promise<void> {
    if (!this.isAlertOpen) {
      const alert = await this.alertController.create({
        header: title,
        subHeader: message,
        buttons: [button || 'Cerrar']
      });
      alert.onDidDismiss().then(() => this.setOpenedAlert(false));
      this.setOpenedAlert(true);
      await alert.present();
    }
  }

  private setOpenedAlert(newValue: boolean) {
    this.isAlertOpen = newValue;
  }
}

import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { CanjeMillasService } from '@services/canjes/canje-millas.service';

@Component({
  selector: 'app-detalle-canje',
  templateUrl: './detalle-canje.component.html',
  styleUrls: ['./detalle-canje.component.scss'],
})
export class DetalleCanjeComponent implements OnInit {
  //---parameter from confirmar paquete
  param : any;
  paramTemp : any;
  paramTitulos : any;
  textoTitulo = '';
  pathCanjeLatamPassDNI = 'canje-latam-pass';
    constructor(private canjeMillasService : CanjeMillasService,
                @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    const paramExito = {
      imagen: "https://firebasestorage.googleapis.com/v0/b/hbk-prd.appspot.com/o/products%2Fimages%2F07-07-2023_16%3A10%3A59_LATAM-1.png?alt=media&token=6c955ada-2193-4ce0-af24-a0143c9256d0",
      titulo: "2,500 Puntos = 1,000 Millas LATAM Pass"
    }
    // this.paramTemp = this.canjeMillasService.getPackExito();
    this.paramTemp = paramExito;
    if (this.paramTemp.length === 0) {
      this.document.location.href = '/' + this.pathCanjeLatamPassDNI;
    }
    else {
      this.param = this.paramTemp;
      this.paramTitulos = this.param.titulo.split("=");
      if (this.paramTitulos.length > 1) {
        this.textoTitulo = this.paramTitulos[1];
      }
      else {
        this.textoTitulo = this.paramTitulos[0];
      }
    }

  }
  volverInicio(){
    this.document.location.href = '/' + this.pathCanjeLatamPassDNI;
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preguntas-frecuentes',
  templateUrl: './preguntas-frecuentes.component.html',
  styleUrls: ['./preguntas-frecuentes.component.scss']
})
export class PreguntasFrecuentesComponent implements OnInit {
  flecha1: boolean;
  flecha2: boolean;
  flecha3: boolean;
  flecha4: boolean;
  flecha5: boolean;
  flecha6: boolean;
  flecha7: boolean;
  flecha8: boolean;
  flecha9: boolean;

  // @ViewChild("btncord1") btncord1: ElementRef;
  // @ViewChild("btncord2") btncord2: ElementRef;
  // @ViewChild("btncord3") btncord3: ElementRef;
  // @ViewChild("btncord4") btncord4: ElementRef;
  // @ViewChild("btncord5") btncord5: ElementRef;
  // @ViewChild("btncord6") btncord6: ElementRef;
  // @ViewChild("btncord7") btncord7: ElementRef;
  constructor() { }

  ngOnInit(): void {
    this.inicializarVariables();

  }
  inicializarVariables() {
    this.flecha1  = false;
    this.flecha2  = false;
    this.flecha3  = false;
    this.flecha4  = false;
    this.flecha5  = false;
    this.flecha6  = false;
    this.flecha7  = false;              
    this.flecha8  = false;
    this.flecha9  = false;

  }
  moverFecha(nroFlecha:string) {

    // console.log(`"btn-acor1PP-${nroFlecha}"`);
    // let x = document.getElementById(`"btn-acor1PP-${nroFlecha}"`);



      if (nroFlecha === '1') {
      if (!this.flecha1) {
        this.flecha1 = true;
        this.flecha2 = false;
        this.flecha3 = false;
        this.flecha4 = false;
        this.flecha5 = false;
        this.flecha6 = false;
        this.flecha7 = false;
        this.flecha8 = false;
        this.flecha9 = false;
      } else {
        this.flecha1 = false;
      }

      // this.ScrollIntoView("#btn-acor1PP-1");
      this.ScrollIntoView("#titleButton");
    }
    if (nroFlecha === '2') {
      if (!this.flecha2) {
        this.flecha1 = false;
        this.flecha2 = true;
        this.flecha3 = false;
        this.flecha4 = false;
        this.flecha5 = false;
        this.flecha6 = false;
        this.flecha7 = false;
        this.flecha8 = false;
        this.flecha9 = false;

      } else {
        this.flecha2 = false;
      }
      this.ScrollIntoView("#btn-acor1PP-1");
    }
    if (nroFlecha === '3') {
      if (!this.flecha3) {
        this.flecha1 = false;
        this.flecha2 = false;
        this.flecha3 = true;
        this.flecha4 = false;
        this.flecha5 = false;
        this.flecha6 = false;
        this.flecha7 = false;
        this.flecha8 = false;
        this.flecha9 = false;

      } else {
        this.flecha3 = false;
      }
      this.ScrollIntoView("#btn-acor1PP-2");
    }
    if (nroFlecha === '4') {
      if (!this.flecha4) {

        // this.btncord4.nativeElement.scrollIntoView({behavior:"smooth"});
        this.flecha1 = false;
        this.flecha2 = false;
        this.flecha3 = false;
        this.flecha4 = true;
        this.flecha5 = false;
        this.flecha6 = false;
        this.flecha7 = false;
        this.flecha8 = false;
        this.flecha9 = false;

      } else {
        this.flecha4 = false;
      }
      this.ScrollIntoView("#btn-acor1PP-3");
    }
    if (nroFlecha === '5') {
      if (!this.flecha5) {
        this.flecha1 = false;
        this.flecha2 = false;
        this.flecha3 = false;
        this.flecha4 = false;
        this.flecha5 = true;
        this.flecha6 = false;
        this.flecha7 = false;
        this.flecha8 = false;
        this.flecha9 = false;

      } else {
        this.flecha5 = false;
      }

      this.ScrollIntoView("#btn-acor1PP-3");
    }
    if (nroFlecha === '6') {
      if (!this.flecha6) {
        this.flecha1 = false;
        this.flecha2 = false;
        this.flecha3 = false;
        this.flecha4 = false;
        this.flecha5 = false;
        this.flecha6 = true;
        this.flecha7 = false;
        this.flecha8 = false;
        this.flecha9 = false;
      } else {
        this.flecha6 = false;
      }
      this.ScrollIntoView("#btn-acor1PP-5");
    }
    if (nroFlecha === '7') {
      if (!this.flecha7) {
        this.flecha1 = false;
        this.flecha2 = false;
        this.flecha3 = false;
        this.flecha4 = false;
        this.flecha5 = false;
        this.flecha6 = false;
        this.flecha7 = true;
        this.flecha8 = false;
        this.flecha9 = false;
      } else {
        this.flecha7 = false;
      }
      this.ScrollIntoView("#btn-acor1PP-6");
    }
    if (nroFlecha === '8') {
      if (!this.flecha8) {
        this.flecha1 = false;
        this.flecha2 = false;
        this.flecha3 = false;
        this.flecha4 = false;
        this.flecha5 = false;
        this.flecha6 = false;
        this.flecha7 = false;
        this.flecha8 = true;
        this.flecha9 = false;
      } else {
        this.flecha8 = false;
      }
      this.ScrollIntoView("#btn-acor1PP-7");
    }
    if (nroFlecha === '9') {
      if (!this.flecha9) {
        this.flecha1 = false;
        this.flecha2 = false;
        this.flecha3 = false;
        this.flecha4 = false;
        this.flecha5 = false;
        this.flecha6 = false;
        this.flecha7 = false;
        this.flecha8 = false;
        this.flecha9 = true;
      } else {
        this.flecha9 = false;
      }
      this.ScrollIntoView("#btn-acor1PP-8");
    }

  }


  ScrollIntoView(elem: string) {
    setTimeout(() => {
    document.querySelector(elem).scrollIntoView({ behavior: 'smooth', block: 'start',inline:'center' });
    }, 300);

  }


}

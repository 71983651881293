import { Injectable } from '@angular/core';
import { IHashInformation } from 'src/app/common/interfaces/general.interface';
import { IUserHashInformation, IUserInformation } from 'src/app/common/interfaces/user.interface';
import { HASH_USER_INFORMATION_MOCK, USER_INFORMATION_MOCK } from 'src/app/common/mocks/user.mocks';
import { environment } from 'src/environments/environment';
import { TitleCasePipe } from 'src/app/pipes/title-case/title-case.pipes';
import { HttpService } from 'src/app/services/http/http.service';

// import { FirebaseService } from '@services/firebase/firebase.service';
// import * as firebase from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  userInformation: IUserInformation;

  constructor(
    private http: HttpService,
    // private firebaseService: FirebaseService,
  ) { }

  public async getUserInformation() {
    const url = environment.BASE_BACKEND_WORKER + '/managementapp/userdigital/dataprofile';
    const user = await this.http.get<IUserInformation>(url, USER_INFORMATION_MOCK).toPromise();
    this.userInformation = user;
    return user;
  }


  public getContactData(): Promise<IUserInformation> {
    const url = environment.BASE_BACKEND_WORKER + '/promotion/incrementlinehb/contactdataincrement';
    return this.http.post<IUserInformation>(url, {}, USER_INFORMATION_MOCK).toPromise();
  }

  public getDataProfile(userHashParams: IHashInformation): Promise<IUserHashInformation> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/efex/contact-data';
    return this.http.post<IUserHashInformation>(url, userHashParams, HASH_USER_INFORMATION_MOCK).toPromise();
  }

  public getFullName() {
    const name = this.userInformation.names ?
      ' ' + new TitleCasePipe().transform(this.userInformation.names.split(' ')[0]) : '';
    const lastName = this.userInformation.lastName ?
      ' ' + new TitleCasePipe().transform(this.userInformation.lastName) : '';
    return name + lastName;
  }
  // public async loadUserInformation(user: any, userDNI: string, deviceInformation: IDevice) {
  //   const hashToken = ''; // this.authService.currentToken.refreshToken;  TODO: hashear token con blake2

  //   this.firebaseService.addSefEfexServiceResponse(
  //     {
  //       token: hashToken,
  //       sessionDNI: userDNI,
  //       createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  //       customerId: user.customerId,
  //       userEmail: user.email,
  //       userName:  user.names.toUpperCase(),
  //       userLastName: user.lastName.toUpperCase(),
  //       mobileNumber: user.mobileNumber,
  //       device: deviceInformation
  //     },
  //     'userInformationResponses');
  // }

}

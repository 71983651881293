import { ViewportScroller } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy, Scroll } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  GOOGLE_TAG_MANAGER_ID,
  GOOGLE_TAG_MANAGER_ID_SECOND,
  IDLE_EXIT_AFTER_WARNING_TIMEOUT,
  IDLE_WARNING_TIMEOUT,
} from 'src/app/common/constants/misc.constants';
import { RecaptchaInterceptor } from 'src/app/common/interceptors/recaptcha.interceptor';
import { ComponentsModule } from 'src/app/components/components.module';
import { environment } from 'src/environments/environment';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Calendar } from '@ionic-native/calendar/ngx';
import { Device } from '@ionic-native/device/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Market } from '@ionic-native/market/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
// import { IonicStorageModule } from '@ionic/storage';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AccountManagerService } from 'src/app/services/account-manager/account-manager.service';
import { BiometricService } from 'src/app/services/biometric/biometric.service';
import { LocationService } from 'src/app/services/location/location.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { NotificationsServiceMock } from 'src/app/services/notifications/notifications.service.mocks';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils';

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { filter } from 'rxjs/operators';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';



import { TooltipModule ,TooltipOptions} from 'ng2-tooltip-directive';
import { MyDefaultTooltipOptionsComponent } from '@components/my-default-tooltip-options/my-default-tooltip-options.component';

import {NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import {NgbConfig} from '@ng-bootstrap/ng-bootstrap';


// import { EncryptionInterceptor } from 'src/app/common/interceptors/encryption.interceptor';
// import { IonicStorageModule } from '@ionic/storage';
// import { FirebaseService } from '@services/firebase/firebase.service';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    ComponentsModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    RecaptchaV3Module,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.ENV === 'production' || environment.ENV === 'qa' }),
    NgbPaginationModule,
    NgbModule
    //, TooltipModule.forRoot(MyDefaultTooltipOptionsComponent as TooltipOptions)
  ],
  providers: [
    StatusBar,
    InAppBrowser,
    FileOpener,
    File,
    SplashScreen,
    // FirebaseService,
    FirebaseX,
    AccountManagerService,
    BiometricService,
    FingerprintAIO,
    Geolocation,
    Device,
    UtilsService,
    Calendar,
    AppVersion,
    SocialSharing,
    LocationService,
    HTTP,
    StorageService,
    Market,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (firebaseService: FirebaseService) => async () => {
    //     try {
    //       // const realConfig = await firebaseService.initializeRemoteConfig();
    //       // environment.BASE_BACKEND = realConfig.BASE_BACKEND;
    //       // // environment.TRANSFERS_ENABLED = realConfig.TRANSFERS_ENABLED;
    //       // environment.API_KEY = realConfig.API_KEY;
    //       // environment.MAINTENANCE = realConfig.MAINTENANCE;
    //     } catch (err) {
    //       // environment.MAINTENANCE = true;
    //       // console.log('Error fetching the remote config: ', err);
    //     }
    //   },
    //   deps: [FirebaseService],
    //   multi: true
    // },
    // CloudFlareService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (cloudFlareService: CloudFlareService) => async () => {
    //     try {
    //       // const realConfig = await cloudFlareService.getRemoteConfig();
    //       // environment.PUBLIC_KEY_PEM = realConfig.PUBLIC_KEY;
    //       // environment.MAINTENANCE = realConfig.MAINTENANCE;
    //       // environment.MAINTENANCE_MESSAGE = realConfig.MAINTENANCE_MESSAGE;
    //     } catch (err) {
    //       // environment.MAINTENANCE = true;
    //       // console.log('Error fetching the remote config: ', err);
    //     }
    //   },
    //   deps: [CloudFlareService],
    //   multi: true
    // },
    {
      provide: NotificationsService, useClass: environment.ENV !== 'local' ?
        NotificationsService : NotificationsServiceMock
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.RECAPTCHA_SITE_KEY
    },
    {
      provide: 'googleTagManagerId',
      useValue: GOOGLE_TAG_MANAGER_ID
    },
    {
      provide: 'googleTagManagerId_Second',
      useValue: GOOGLE_TAG_MANAGER_ID_SECOND
    },
    {
      provide: 'idleConfig', useValue: {
        idle: IDLE_WARNING_TIMEOUT,
        timeout: IDLE_EXIT_AFTER_WARNING_TIMEOUT,
      }
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: CloudFlareInterceptor,
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RecaptchaInterceptor,
      multi: true
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: EncryptionInterceptor,
    //   multi: true
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthorizationInterceptor,
    //   multi: true
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: SslPinningInterceptor,
    //   multi: true
    // },
  ],
  exports: [
    NgbPaginationModule,
    NgbModule
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    viewportScroller.setOffset([0, 60]);
    router.events
      .pipe(filter((e) => e instanceof Scroll))
      .subscribe((e: Scroll) => {
        if (e.anchor) {
          // anchor navigation
          setTimeout(() => {
            viewportScroller.scrollToAnchor(e.anchor);
          });
        } else if (e.position) {
          // backward navigation
          viewportScroller.scrollToPosition(e.position);
        } else {
          // forward navigation
          viewportScroller.scrollToPosition([0, 0]);
        }
      });
  }
}

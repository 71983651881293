import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ver-promociones',
  templateUrl: './ver-promociones.component.html',
  styleUrls: ['./ver-promociones.component.scss'],
})
export class VerPromocionesComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}

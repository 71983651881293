import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RECAPTCHA_ACTION_KEY, ROUTES_WITH_RECAPTCHA_VALIDATION } from 'src/app/common/constants/recaptcha.constants';

@Injectable()
export class RecaptchaInterceptor implements HttpInterceptor {

  constructor(
    public router: Router,
    private recaptchaService: ReCaptchaV3Service
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('RecaptchaInterceptor');
    const matches = ROUTES_WITH_RECAPTCHA_VALIDATION.filter(routes => request.url.includes(routes.route));
    if (matches.length === 1) {
      return from(this.getRecaptchaToken(RECAPTCHA_ACTION_KEY))
        .pipe(
          switchMap((recaptchaToken: string) => {
            let requestClone;
            // console.log('recaptchaToken');
            // console.log(recaptchaToken);
            if (typeof (request.body) === 'string') {
              //console.log('paso 1');
              requestClone = request.clone({
                body: request.body + `&${matches[0].requestKey}=${recaptchaToken}`,
              });
            } else if (typeof (request.body) === 'object') {
              //console.log('paso 2');
              requestClone = request.clone({
                body: {
                  ...request.body,
                  [matches[0].requestKey]: recaptchaToken
                },
              });
            }
            return next.handle(requestClone);
          })
        );
    }
    return next.handle(request);
  }

  private getRecaptchaToken(actionKey: string) {
    return this.recaptchaService.execute(actionKey);
  }
}

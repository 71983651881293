import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promociones-terminos-condiciones',
  templateUrl: './promociones-terminos-condiciones.component.html',
  styleUrls: ['./promociones-terminos-condiciones.component.scss'],
})
export class PromocionesTerminosCondicionesComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}

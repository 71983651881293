import { ISegmentSelector } from 'src/app/common/interfaces/default.interface';

export const EXTEND_CREDIT_LINE_FAQS = [
  {
    label: '¿El incremento se realiza al instante?',
    value: 'Si, una vez aceptado el monto, el incremento se aplicará automaticamente a tu tarjeta'
  },
  {
    label: '¿Si incremento mi línea, cambia mi tarjeta de crédito?',
    value: 'Las condiciones de tu tarjeta de crédito se mantienen, solo incrementas la línea para usarla en lo que desees.'
  },
] as ISegmentSelector[];

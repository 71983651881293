import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pantalla-carga',
  templateUrl: './pantalla-carga.component.html',
  styleUrls: ['./pantalla-carga.component.scss'],
})
export class PantallaCargaComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-queson-categoria-ripleypuntos',
  templateUrl: './queson-categoria-ripleypuntos.component.html',
  styleUrls: ['./queson-categoria-ripleypuntos.component.scss'],
})
export class QuesonCategoriaRipleypuntosComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}

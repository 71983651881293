import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-afiliacion-terminoslegales',
  templateUrl: './afiliacion-terminoslegales.component.html',
  styleUrls: ['./afiliacion-terminoslegales.component.scss'],
})
export class AfiliacionTerminoslegalesComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}

import { IEnvironment } from 'src/app/common/interfaces/environments.interface';

export const environment: IEnvironment = {
  ENV: 'qa',
  BASE_BACKEND: 'https://rpgoserv.qa.bancoripley.com.pe',
  BASE_BACKEND_WORKER: 'https://cmsadmin.qa.bancoripley.com.pe/inscriptionswebapi/api/inscriptions',
  BACKEND_IDENTITY_Q: 'https://rpgoserv.qa.bancoripley.com.pe',
  BACKEND_FORM_CMS_Q: 'https://cmsadmin.qa.bancoripley.com.pe/inscriptionswebapi/api/inscriptions',
  TRANSFERS_ENABLED: true,
  CHANNEL: '',
  USING_MOCKS: false,
  SERVICES_TIMEOUT: 60000,
  API_KEY: '',
  MAINTENANCE: false,
  MAINTENANCE_MESSAGE: '¡Volveremos pronto con mejores servicios para ti! Puedes realizar tus operaciones desde tu Banca Móvil.',
  MOCK_SUCCESS_RATE: 1,
  MOCK_DELAY_MILISECONDS: 1000,
  CLIENT_ID: '',
  CLIENT_SECRET: '',
  DNI_CODE: 'C',
  FOREIGN_CARD_CODE: '2',
  FIREBASE_CONFIG: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    vapidKey: '',
    measurementId: ''
  },
  PUSH_NOTIFICATIONS_API_PATH: '',
  PUBLIC_KEY_PEM: '',
  // RECAPTCHA_SITE_KEY: '6LcPIN8UAAAAAIpiRZDmAs1N7EjZLw1O_GSC5GVY',
  RECAPTCHA_SITE_KEY: '6LfPpqwdAAAAAEfkEGErn5I_t0ssxkUjFH_m2IGu',
  PAYMENTS_CONFIG: {
    CHANNEL: '',
    ENDPOINT_JS: '',
    ENDPOINT_CSS: '',
    LANGUAGE: '',
    FONT: ''
  },
  SSL_PINNING_ENABLED: false,
  IP_PROVIDERS:[
    "https://webp-qa-worker.bancoripley.workers.dev"
  ],
  HEADER_ROUTE_ID: '/ip',

  SRP_Credential: 'grant_type=client_credentials&client_id=LoyaltyApp-qa&client_secret=0057c594-06ce-439b-89dd-4bc276403eb5',
  SRP_Credential_Q: 'grant_type=client_credentials&client_id=LoyaltyApp-qa&client_secret=0057c594-06ce-439b-89dd-4bc276403eb5'
};

import { Component, Input, OnDestroy } from '@angular/core';
// import { slideInAnimation } from 'src/app/common/utils/animations/slide-in-animation';
// import { slideOutAnimation } from 'src/app/common/utils/animations/slide-out-animation';
import { NotificationsFeedComponent } from 'src/app/components/notifications-feed/notifications-feed.component';
import { ModalController } from '@ionic/angular';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notifications-bell',
  templateUrl: './notifications-bell.component.html',
  styleUrls: ['./notifications-bell.component.scss'],
})
export class NotificationsBellComponent implements OnDestroy {

  public subscription: Subscription;
  public currentCssClass: string;
  @Input() public inverseColor: boolean;

  constructor(
    private notificationsService: NotificationsService,
    private modalController: ModalController,
  ) {
    this.subscription = new Subscription();
    this.inverseColor = false;
    this.currentCssClass = 'i-notification-off';
    this.listenToNotificationUpdate();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public async openFeed() {
    // const modal = await this.modalController.create({
    //   component: NotificationsFeedComponent,
    //   cssClass: 'side-modal',
    //   enterAnimation: slideInAnimation,
    //   leaveAnimation: slideOutAnimation
    // });
    // await modal.present();
  }

  private listenToNotificationUpdate(): void {
    this.subscription.add(this.notificationsService.hasNewNotifications.subscribe((newNotifications) => {
     this.currentCssClass = newNotifications ? 'i-notification-on' : 'i-notification-off';
    }));
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pantalla-notfound',
  templateUrl: './pantalla-notfound.component.html',
  styleUrls: ['./pantalla-notfound.component.scss'],
})
export class PantallaNotfoundComponent implements OnInit {

  pathConoceMas = 'ripley-puntos';

  constructor(
    private router: Router
  ) { }

  ngOnInit() {}

  onConoceMas() {
    this.router.navigateByUrl(this.pathConoceMas);
  }
}

export const CONTACT_DETAIL_MOCK = {
  success: [
    {
      idSession: 'b2370081-fc0b-932g-9316-863194eee46e',
      mail: null,
      mobileNumber: '988308283'
    },
    {
      idSession : 'b2370081-fc0b-993d-9316-863194ccc46e',
      mail: 'cmora@kunder.cl',
      mobileNumber: '988308283'
    },
    {
      idSession : 'b2370081-fc0b-993d-9316-863194ccc46e',
      mail: 'cmora@kunder.cl',
      mobileNumber: ''
    }
  ],
  failures: [{
    code: 100,
    errorMessage: 'Generic Error message'
  }]
};

export const CHANGE_PASSWORD_MOCK = {
  success: [
    {
      code: 1,
      errorMessage: 'OK'
    },
    {
      code: 0,
      errorMessage: 'FAILURE'
    }
  ],
  failures: [
    {
      code: 600,
      errorMessage: 'Clave'
    },
    {
      code: 620,
      errorMessage: 'Sesión expirada'
    },
  ]
};

export const UPDATE_CONTACT_DATA_MOCK = {
  success: [
    {
      code: 1,
      errorMessage: 'OK'
    }
  ],
  failures: []
};

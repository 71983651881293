import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICustomerRequest, ICustomerResponse } from 'src/app/common/interfaces/customer.interface';
import { CONTACT_DETAIL_MOCK } from 'src/app/common/mocks/account.mocks';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/services/http/http.service';
import { PaqueteMillasValidator } from 'src/app/common/models/canjes.model';
import { IProductResponse } from 'src/app/common/interfaces/product.interface';
import { ISendOtpRequest, ISendOtpResponse, IValidateOtpRequest, IValidateOtpResponse, IRewardpointsRequest, IRewardpointsTokenRequest, IFirebaseResponse,IValidateOtpRedeemResponse,IValidateOtpRedeemRequest } from 'src/app/common/interfaces/canjes.interface';

@Injectable({
  providedIn: 'root'
})
export class CanjeMillasService {

  dataValida: PaqueteMillasValidator;
  private paramPackExito = [];
  vistaPreFrecuentes: boolean = true;

  headers = new HttpHeaders().set(
    'Content-Type',
    'application/x-www-form-urlencoded;'
  );

  constructor(
    private http: HttpService
  ) { }


  public validationCustomer(params: ICustomerRequest, token: string): Promise<ICustomerResponse> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json;')
      .set('Authorization', `Bearer ${token}`);

    const url = environment.BASE_BACKEND + '/latamwebapi/customer/validationCustomer';
    return this.http.post<ICustomerResponse>(url, params, CONTACT_DETAIL_MOCK, headers).toPromise();
  }

  public getListProductsLatam(token: string): Promise<any[]> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json;')
      .set('Authorization', `Bearer ${token}`);

    const url = environment.BASE_BACKEND + '/latamwebapi/customer/getListProductsLatam';
    return this.http.get<any[]>(url, CONTACT_DETAIL_MOCK, headers).toPromise();
  }

  public getProductsList(token: string): Promise<IProductResponse[]> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json;')
      .set('Authorization', `Bearer ${token}`);

    const url = environment.BASE_BACKEND + '/latamwebapi/customer/getProductsList';
    return this.http.get<IProductResponse[]>(url, CONTACT_DETAIL_MOCK, headers).toPromise();
  }

  public sendOTP(params: ISendOtpRequest, token: string): Promise<ISendOtpResponse> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json;')
      .set('Authorization', `Bearer ${token}`);

    const url = environment.BASE_BACKEND + '/latamwebapi/customer/sendOTP';
    return this.http.post<ISendOtpResponse>(url, params, CONTACT_DETAIL_MOCK, headers).toPromise();
  }

  public validateOTP(params: IValidateOtpRequest, token: string): Promise<IValidateOtpResponse> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json;')
      .set('Authorization', `Bearer ${token}`);

    const url = environment.BASE_BACKEND + '/latamwebapi/customer/validateOTP';
    return this.http.post<IValidateOtpResponse>(url, params, CONTACT_DETAIL_MOCK, headers).toPromise();
  }
  public validateOTP_Redeem(params: IValidateOtpRedeemRequest, token: string): Promise<IValidateOtpRedeemResponse> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json;')
      .set('Authorization', `Bearer ${token}`);

    const url = environment.BASE_BACKEND + '/latamwebapi/customer/validateOTP_redeem';
    return this.http.post<IValidateOtpRedeemResponse>(url, params, CONTACT_DETAIL_MOCK, headers).toPromise();
  }

  public redeem(params: IRewardpointsRequest, token: string): Promise<IFirebaseResponse> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json;')
      .set('Authorization', `Bearer ${token}`);

    const url = environment.BASE_BACKEND + '/latamwebapi/customer/redeem';
    return this.http.post<IFirebaseResponse>(url, params, CONTACT_DETAIL_MOCK, headers).toPromise();
  }

  setPackExito(param){
    this.paramPackExito = param;
  }
  getPackExito(){
    return this.paramPackExito;
  }

}

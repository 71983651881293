export const OPEN_ACCOUNT_PROGRESS_ADDITION = 0.20;
export const YES = 'SI';
export const NO = 'NO';
export const REGISTER_ACCOUNT_SUCCESS_CODE = 1;
export const ERROR_CODE_SMS = 630;
export const ACCOUNT_PLUS_CODE = 'AC001002';
export const ACCOUNT_SIMPLE_CODE = 'AC001006';
export const SOLES = 'PEN';
export const DOLLARS = 'USD';
export const PRE_REGISTER_KEY = 'preRegister';
export const TYPE_ACCOUNT_SELECTED = 'typeAccountSelected';
export const OPEN_ACCOUNT_SUCCESS_KEY = 'openAccount';

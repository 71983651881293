import { Component, OnInit, ViewEncapsulation, ViewChild, NgZone, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SwiperComponent } from "swiper/angular";

// import Swiper core and required modules
import SwiperCore, { EffectFade, EffectCube, EffectCoverflow, EffectFlip, Navigation, Pagination } from "swiper";

import { ICustomerRequest, ICustomerResponse } from 'src/app/common/interfaces/customer.interface';
import { IProductResponse } from 'src/app/common/interfaces/product.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CanjeMillasService } from 'src/app/services/canjes/canje-millas.service';

// install Swiper modules
SwiperCore.use([EffectCoverflow, Navigation, Pagination]);

@Component({
  selector: 'app-paquete-latam',
  templateUrl: './paquete-latam.component.html',
  styleUrls: ['./paquete-latam.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaqueteLatamComponent implements OnInit {

  identidadForm: FormGroup;

  listPaquetes2 = [
    { index: 0, image: 'assets/img/svg/latam/Canje_Millas_01.svg', value: 1000, points: 4500 },
    { index: 1, image: 'assets/img/svg/latam/Canje_Millas_02.svg', value: 3000, points: 7000 },
    { index: 2, image: 'assets/img/svg/latam/Canje_Millas_03.svg', value: 9000, points: 20000 },
    { index: 3, image: 'assets/img/svg/latam/Canje_Millas_04.svg', value: 24000, points: 50000 },
    { index: 4, image: 'assets/img/svg/latam/Canje_Millas_05.svg', value: 50000, points: 100000 },
  ];

  customerData: ICustomerResponse;

  listPaquetesLatam: IProductResponse[];
  selectPaquete: IProductResponse;

  selectedTitle: string;
  termsAndConditionsPDF: string;
  haveEnoughPoints = false;
  pointMessage = '';

  progress: number = 0;
  label: string;

  pathCanjeLatamPassDNI = 'canje-latam-pass?type=canje';
  pathConfirmaPaquete = 'confirma-paquete';
  showLoader = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _ngZone: NgZone,
    private authService: AuthService,
    private canjeMillasService: CanjeMillasService,
    @Inject(DOCUMENT) private document: Document
  ) {

  }

  ngOnInit() {
    if (history.state.data === undefined) {
      // this.router.navigateByUrl(this.pathCanjeLatamPassDNI);
      this.document.location.href = '/' + this.pathCanjeLatamPassDNI;
    }
    else {
      this.customerData = history.state.data;
      this.createForm();
      this.getPaquetesMillas();
    }
  }
  createForm() {
    this.identidadForm = this.formBuilder.group({
      codigoSMS: [{ value: null, disabled: false }, [Validators.required]],
    });
  }
  getPaquetesMillas() {
    //console.log('getPaquetesMillas');
    this.authService.getAuthentication0().subscribe(
      (response: any) => {
        // console.log(response);
        const status = response.status;
        const bodyResponse = response.body;
        const statusOk = 200;
        switch (status) {
          case statusOk:
            const token = bodyResponse.access_token;
            //console.log(token);

            this.canjeMillasService.getProductsList(token)
              .then((dataResult) => {
                if (dataResult.length > 0) {
                  this.listPaquetesLatam = dataResult;
                  //console.log(this.listPaquetesLatam);
                  this.swiperSlideChanged(0);
                } else {
                  // this.router.navigateByUrl('segmentacion');
                }
              })
              .catch(() => {
                console.log('catch');
              });

            break;

          default:

            break;
        }

      },
      (error) => {
        // const errorBody = error.error;
        // const status = error.status;
      }
    );
  }

  swiperSlideChanged(event) {
    let activeIndex = 0;
    if (event === 0) {
      activeIndex = 0
    } else {
      activeIndex = event.activeIndex
    }
    const paqueteSelect = this.listPaquetesLatam.find(x => x.index===activeIndex);
    if (paqueteSelect.points > this.customerData.points) {
      const puntosFalta = paqueteSelect.points - this.customerData.points;
      this.pointMessage = 'Te faltan ' + puntosFalta.toLocaleString('es-es', {minimumFractionDigits: 0}) + ' puntos. ¡Sigue acumulando!';
      this.haveEnoughPoints = false;
    }
    else {
      this.pointMessage = '';
      this.haveEnoughPoints = true;
    }

    this.selectedTitle = paqueteSelect.selectedTitle;
    this.termsAndConditionsPDF = paqueteSelect.termsAndConditionsPDF;

    this.selectPaquete = paqueteSelect;
    this._ngZone.run(() => {  });
    // this._ngZone.run(() => { console.log('Outside Done!'); });

  }

  onTerminosCondiciones() {
    window.open(this.termsAndConditionsPDF, '_blank');
  }

  onConfirmar() {
    this.showLoader = true;
    const clientData = {
      processType: 'RPGO',
      imagen: this.selectPaquete.image,
      title: this.selectPaquete.selectedTitle,
      subtitle: this.selectPaquete.selectedSubtitle,
      latamcode: this.selectPaquete.selectedSubtitle,
      latamName: this.selectPaquete.selectedSubtitle,
      telefono: this.customerData.customer.mobileNumberMask,
      sessionId: this.customerData.customer.sessionId,
      userId: this.customerData.customer.userId,
      memberId: this.customerData.memberId,
      nOfPointsAvailable: this.customerData.points,
      packagePoints: this.selectPaquete.points,
      miles: this.selectPaquete.value,
      sku: this.selectPaquete.productId,
      productId: this.selectPaquete.productId,
      limit: this.selectPaquete.limit,
      origin: 'rpgo',
      documentType: this.customerData.customer.documentType,
      documentNumber: this.customerData.customer.documentNumber,
      backendID: this.selectPaquete.backendID.toString(),
      fullName: this.selectPaquete.fullName,
    };
    //console.log('onConfirmar');
    //console.log(clientData);
    this.router.navigateByUrl(this.pathConfirmaPaquete, { state: { ClientData: clientData } });
    this.showLoader = false;

    // // this.router.navigateByUrl(this.pathConfirmaPaquete);
    // this.authService.getAuthentication0().subscribe(
    //   (response: any) => {
    //     // console.log(response);
    //     const status = response.status;
    //     const bodyResponse = response.body;
    //     const statusOk = 200;
    //     switch (status) {
    //       case statusOk:
    //         // this.tokenService.setToken(bodyResponse.access_token);
    //         // this.localStorageService.set(AppConstants.Session.TOKEN_TYPE, bodyResponse.token_type);

    //         const token = bodyResponse.access_token;
    //         console.log(token);

    //         const client = {
    //           sessionId: this.customerData.customer.sessionId,
    //           processType: 'RPGO',
    //           captcha: ''
    //         };

    //         this.canjeMillasService.sendOTP(client, token)
    //           .then((dataResult) => {
    //             console.log('objDataSendOTP');
    //             console.log(dataResult);
    //             if (dataResult.code === 1) {
    //               this.showLoader = false;
    //               localStorage.setItem('objDataSendOTP', JSON.stringify(dataResult));

    //               const clientData = {
    //                 processType: 'RPGO',
    //                 imagen: this.selectPaquete.image,
    //                 title: this.selectPaquete.selectedTitle,
    //                 subtitle: this.selectPaquete.selectedSubtitle,
    //                 latamcode: this.selectPaquete.selectedSubtitle,
    //                 latamName: this.selectPaquete.selectedSubtitle,
    //                 telefono: this.customerData.customer.mobileNumberMask,
    //                 sessionId: this.customerData.customer.sessionId,
    //                 userId: this.customerData.customer.userId,
    //                 memberId: this.customerData.memberId,
    //                 nOfPointsAvailable: this.customerData.points,
    //                 packagePoints: this.selectPaquete.points,
    //                 miles: this.selectPaquete.value,
    //                 sku: this.selectPaquete.productId,
    //                 productId: this.selectPaquete.productId,
    //                 limit: this.selectPaquete.limit,
    //                 origin: 'rpgo',
    //                 documentType: this.customerData.customer.documentType,
    //                 documentNumber: this.customerData.customer.documentNumber,
    //                 backendID: this.selectPaquete.backendID.toString(),
    //                 fullName: this.selectPaquete.fullName,
    //               };

    //               this.router.navigateByUrl(this.pathConfirmaPaquete, { state: { DataOTP: dataResult, ClientData: clientData } });

    //             } else {
    //               // this.router.navigateByUrl('segmentacion');
    //             }
    //             // this.pantallaDeCarga(false);
    //           })
    //           .catch(() => {
    //             console.log('catch');
    //           });

    //         break;

    //       default:

    //         break;
    //     }

    //   },
    //   (error) => {
    //     // const errorBody = error.error;
    //     // const status = error.status;
    //   }
    // );
  }
  onCancelar() {
    // this.router.navigateByUrl(this.pathCanjeLatamPassDNI);
    this.showLoader = false;
    this.document.location.href = '/' + this.pathCanjeLatamPassDNI;
  }

}

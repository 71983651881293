export const LOGIN_PASSWORD_MAX_LENGTH = 6;

export const PASSWORD_LABEL = 'hashCodes';

export const PASSWORD_TOOLTIP_TEXT = 'Clave que generaste al momento de registrarte a través del App o Banca por Internet.';

export const EMAIL_REGEX = new RegExp(/^[a-z0-9.*_%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$/);

export const EMAIL_REGEXB = new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$/);

export const MILLISECONDS_TO_SECOND = 1000;

export const PASSWORD_RESET_FORM = 'password';

export const ENROLLMENT_FORM = 'enrollment';
export const SCREEN_WIDTH = {
  DESKTOP: 1023,
  TABLET: 769,
  MOBILE: 481,
};

export const DEFAULT_LOCALE = 'es';

export const DEFAULT_MOBILE_HOME_BUTTON_LABEL = 'Ver movimientos';

export const SAVINGS_MOBILE_HOME_BUTTON_LABEL = 'Ver cuentas';

export const BENEFITS_MOBILE_HOME_BUTTON_LABEL = 'Ver beneficios';

export const DEFAULT_PAYMENTS_MOBILE_HOME_BUTTON_LABEL = 'Pagar';

export const SEF_PAYMENTS_MOBILE_HOME_BUTTON_LABEL = 'Pagar SEF';

export const CARD_PAYMENTS_MOBILE_HOME_BUTTON_LABEL = 'Pagar Tarjeta';

export const DEFAULT_SERVICE_ERROR_MESSAGE = '¡Ups! Información no disponible';

export const CARD_DETAILS_TABLE_HEADER_CONTENT = [
  {
    class: 'date-label',
    value: 'Fecha',
  },
  {
    class: 'description-label',
    value: 'Descripción',
  },
  {
    class: 'value-label',
    value: 'Monto',
  },
];

export const CARD_DETAILS_EXTRA_TAB_TABLE_HEADER_CONTENT = [
  {
    class: 'date-label',
    value: 'Fecha',
  },
  {
    class: 'description-label',
    value: 'Descripción',
  },
  {
    class: 'value-label',
    value: 'Monto',
  },
  {
    class: 'detail-label',
    value: '',
  }
];

export const CREDITS_DETAILS_TABLE_HEADER_CONTENT = [
  {
    class: 'description-label',
    value: 'Tipo de préstamo',
  },
  {
    class: 'description-label',
    value: 'Monto de préstamo',
  },
  {
    class: 'date-label',
    value: 'Fecha de desembolso ',
  },
  {
    class: 'description-label',
    value: 'Capital pendiente de pago',
  },
  {
    class: 'detail-label',
    value: 'Detalle',
  }
];

export const ALERT_SILVER_GOLD = [
  {
    case: 'silver',
    message: 'Mantendrás tu categoría Silver si acumulas 5.000 o más puntos hasta el 31/12/',
    icon: 'https://firebasestorage.googleapis.com/v0/b/banco-ripley-app.appspot.com/o/icons%2Fi-warning-pink.svg?alt=media',
    enabled: false,
  },
  {
    case: 'gold',
    message: 'Mantendrás tu categoría Gold si acumulas 11.000 o más puntos hasta el 31/12/',
    icon: 'https://firebasestorage.googleapis.com/v0/b/banco-ripley-app.appspot.com/o/icons%2Fi-warning-pink.svg?alt=media',
    enabled: false,
  },
  {
    case: 'ripley',
    message: '',
    icon: 'https://firebasestorage.googleapis.com/v0/b/banco-ripley-app.appspot.com/o/icons%2Fi-warning-pink.svg?alt=media',
    enabled: false,
  },
];

export const RIPLEY_BANK_PUBLIC_SITE_URL = 'https://www.bancoripley.com.pe/';

export const RIPLEY_BANK_CARD_SITE = 'https://www.bancoripley.com.pe/solicita-tarjeta-ripley-mastercard/index.html';

export const RIPLEY_POINTS_SITE = 'https://www.ripleypuntos.com.pe/ripley-puntos.html';

export const DEFAULT_ANIMATION_OPTION_SCROLL = {
  behavior: 'smooth',
  block: 'center'
} as ScrollIntoViewOptions;

export const EXTEND_CREDIT_LINE_PROGRESS_ADDITION = 0.25;

export const ANDROID_DOWNLOAD_MESSAGE = 'Descargar en Play Store';

export const ANDROID_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.ripley.banco.peru&hl=es_419';

export const IOS_DOWNLOAD_MESSAGE = 'Descargar en App Store';

export const IOS_STORE_LINK = 'https://apps.apple.com/pe/app/banco-ripley-per%C3%BA/id1425352352';

export const NO_PHONE_MESSAGE = '<b>¡Ups! Aún no tenemos tu celular, </b>actualiza tus datos en la App Banco Ripley Perú.</p>';

export const NO_CONTACT_INFORMATION_MESSAGE = '<b>¡Ups! Solo tenemos tu nombre, </b>agrega tus datos en la App Banco Ripley Perú.</p>';

export const UPDATE_DATA_MESSAGE = 'Para poder continuar con el proceso es  necesario que tus datos estén actualizados. ' +
'Por favor, actualiza tus datos y vuélvelo a intentar.';

export const EARTH_DIAMETER_IN_KM = 12742;

export const MATH_RADIANS = 0.017453292519943295;

export const MAX_SLIDE_PROMOTIONS = 5;

export const SLIDE_ORDER_KEY = 'slideOrder';

export const TERMS_AND_CONDITIONS_MODAL_TITLE = 'Términos y Condiciones';

export const GENERIC_SUCCESSFUL_CODE = 1;

export const INCREMENT_LINE_MONEY_STEP = 100;

export const IDLE_WARNING_TIMEOUT = 240;

export const IDLE_EXIT_AFTER_WARNING_TIMEOUT = 60;

export const DEFAULT_REQUEST_TIMEOUT = 60000;

export const REMOTE_CONFIG_SETTINGS = {
  minimumFetchIntervalMillis: 3600000,
  fetchTimeoutMillis: 60000
};

export const REMOTE_CONFIG_MAIN_KEY = 'CONFIG';

export const GOOGLE_TAG_MANAGER_ID = 'GTM-KQDSTHV';

export const GOOGLE_TAG_MANAGER_ID_SECOND = 'GTM-KPZ86Z9';

export const CHANNEL = 'WebHB';

export const CHANNEL_CODE_PWA_APP = '06';

export const CHANNEL_CODE = '01';

export const CHANNEL_ID = '1';

export const INFORMATIVE_MODALS_VIEWED = 'informativeModalsViewed';

export const RIPLEY_PHONE = '016115757';

export const PRETTY_RIPLEY_PHONE = '01 611-5757';

export const SAVING_PRODUCT_TYPE = 'savingType';

export const CTS_PRODUCT_TYPE = 'ctsType';

export const MOBILE_BREAKPOINT = 768;

export const SEF_CODE = '001';

export const EFEX_CODE = '005';

export const SEF = 'sef';

export const EFEX = 'efex';

export const PAYMENT_BUTTON_NAME = 'pagos';

// tslint:disable-next-line: max-line-length
export const IP_REGEX = new RegExp(/^(([0-9]{1}|[1-9]{2}|[1-9]0|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]{1}|[1-9]{2}|[1-9]0|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/);

export enum MAIN_CATEGORY {
  GIFTCARD = 'gift-card',
  E_GIFTCARD = 'gift-card-2',
  LATAM = 'latam',
}

export enum TAB_CATEGORY {
  PHYSICAL = 'physical-store',
  ONLINE = 'online-store'
}

export const CONTACT_PHONE_REDEEM = '(01)611-5757';

export const TIMER_UPDATE_BY_SEC = 1000;

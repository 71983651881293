import { Directive, HostListener, Input, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PeruvianCurrencyPipe } from 'src/app/pipes/peruvian-currency/peruvian-currency.pipe';

@Directive({
  selector: '[moneyInput]'
})
export class MoneyInputDirective {

  @Input('moneyInput') formControl: FormControl;
  @Input() numberOfDecimals = 2;
  element: HTMLInputElement;

  constructor(
    private el: ElementRef,
    private peruvianCurrency: PeruvianCurrencyPipe
  ) {
    this.element = this.el.nativeElement;
  }

  @HostListener('input', ['$event.target.value']) onChange(typedValue: string) {
    const numericValue = this.unformattCurrency(typedValue);
    if (numericValue) {
      this.formControl.setValue( !isNaN(numericValue) ? numericValue : null);
      this.element.value = this.peruvianCurrency.transform(numericValue, this.numberOfDecimals);
      this.formControl.markAsDirty();
    } else {
      this.element.value = 'S/ 0';
    }
  }

  @HostListener('blur', ['$event.target.value']) onBlur() {
    this.formControl.markAsTouched();
  }

  private unformattCurrency(value: string): number {
    const clean = this.clearNumber(value);
    if (isNaN(clean)) { return 0; }
    // tslint:disable-next-line: no-magic-numbers
    return clean / 10 ** this.numberOfDecimals;
  }

  private clearNumber(value: string): number {
    return Number(value.replace(/\D+/g, ''));
  }
}

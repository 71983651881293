import { Observable } from "rxjs/internal/Observable";
// import { Observable, of, throwError } from 'rxjs';
import { of, throwError } from 'rxjs';
import { delay, map, catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IHttpParams, IMockup } from 'src/app/common/interfaces/http.interface';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private httpSuccessCode = 200;
  private httpFailureCode = 400;
  private headers = new HttpHeaders();

  constructor(private http: HttpClient) {}


  private httpCall({ method, url, mockup, data, customHeaders }: IHttpParams): Observable<any> {
    const header = customHeaders ? customHeaders : this.headers;

    //if (environment.USING_MOCKS) { return this.mockedResponse(mockup); }
    // if (method === 'delete' || method === 'get') { return this.http[method]<any>(url, { headers: header }); }
    if (method === 'delete' || method === 'get') { return this.http[method](url, { headers: header }); }

    return this.http[method]<any>(url, data, { headers: header });
  }

  public delete<T>(url: string, mockup: IMockup, customHeaders?: HttpHeaders): Observable<T> {
    return this.httpCall({ method: 'delete', url, mockup, data: false, customHeaders });
  }

  public get<T>(url: string, mockup: IMockup, customHeaders?: HttpHeaders): Observable<T> {
    return this.httpCall({ method: 'get', url, mockup, data: false, customHeaders });
  }

  public post<T>(url: string, data: any, mockup: IMockup, customHeaders?: HttpHeaders): Observable<T> {
    return this.httpCall({ method: 'post', url, mockup, data, customHeaders });
  }

  public put<T>(url: string, data: any, mockup: IMockup, customHeaders?: HttpHeaders): Observable<T> {
    return this.httpCall({ method: 'put', url, mockup, data, customHeaders });
  }

/*
private httpCall({ method, url, mockup, data, customHeaders }: IHttpParams): Observable<any> {
    const header = customHeaders ? customHeaders : this.headers;

    //if (environment.USING_MOCKS) { return this.mockedResponse(mockup); }
    // if (method === 'delete' || method === 'get') { return this.http[method]<any>(url, { headers: header }); }
    if (method === 'delete' || method === 'get') { return this.http[method](url, { headers: header }); }

    return this.http[method]<any>(url, data, { headers: header });
  }

  public delete(url: string, mockup: IMockup, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'delete', url, mockup, data: false, customHeaders });
  }

  public get(url: string, mockup: IMockup, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'get', url, mockup, data: false, customHeaders });
  }

  public post(url: string, data: any, mockup: IMockup, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'post', url, mockup, data, customHeaders });
  }

  public put(url: string, data: any, mockup: IMockup, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'put', url, mockup, data, customHeaders });
  }
*/

/*
  public delete<T>(url: string, mockup: IMockup, options: any = {}): Observable<T> {
    let optionsMerge = Object.assign(options,{observe: 'response'});
    return this.http.delete(url, optionsMerge).pipe(
        map((res: any) => { return res; }),
        catchError(this.handleError)
    );
  }
  public get<T>(url: string, mockup: IMockup, options: any = {}): Observable<T> {
    let optionsMerge = Object.assign(options,{observe: 'response'});
    return this.http.get<T>(url, optionsMerge).pipe(
        map((res: any) => { return res; }),
        catchError(this.handleError)
    );
  }
  public post<T>(url: string, data: any, mockup: IMockup, options: any = {}): Observable<T> {
    let optionsMerge = Object.assign(options,{observe: 'response'});
    return this.http.post(url, data, optionsMerge).pipe(
        map((res: any) => { 
            return res; }),
        catchError(this.handleError)
    );
  }
  public put<T>(url: string, data: any, mockup: IMockup, options: any = {}): Observable<T> {
    let optionsMerge = Object.assign(options,{observe: 'response'});
    return this.http.put(url, data, optionsMerge).pipe(
        map((res: any) => { return res; }),
        catchError(this.handleError)
    );
  }
  
  private handleError(httpError: HttpErrorResponse) {
      if (httpError.error instanceof ErrorEvent) {
          return throwError(httpError);
      } else {
          return throwError(httpError);
      }
  };
*/
}


// EFEX
// Enter to option enrollment

export const ENROLL_REGISTER = {
  variable: 'REGISTRO',
  event: 'Regístrate',
  action: 'click'
};

// STEP 1

export const ENROLL_DOC_VERIFICATION = {
  variable: 'REGISTRO',
  event: 'Paso_1-Ingreso_Documento',
  action: 'click'
};

export const ENROLL_MODAL_NO_PRODUCTS = {
  variable: 'REGISTRO',
  event: 'Paso_1-Ingreso_Documento',
  action: 'visualización',
  info1: '0',
  info2: '1'
};
export const ENROLL_CHECK_FOR_CARD = {
  variable: 'REGISTRO',
  event: 'Paso_1-Ver_si_tengo_tarjeta',
  action: 'click'
};

export const ENROLL_MODAL_PRODUCTS_FOUND = {
  variable: 'REGISTRO',
  event: 'Paso_1-Ya_registrado',
  action: 'visualización',
  info1: '0',
  info2: '1'
};

export const ENROLL_FORGOT_PASS = {
  variable: 'REGISTRO',
  event: 'Olvidé mi clave',
  action: 'click'
};

export const ENROLL_LOGIN = {
  variable: 'REGISTRO',
  event: 'Ingresar',
  action: 'click'
};

// Paso 2

export const ENROLL_CONFIRM_SMS = {
  variable: 'REGISTRO',
  event: 'Paso_2-Confirmar',
  action: 'click'
};

export const ENROLL_TIMEOUT_SMS = {
  variable: 'REGISTRO',
  event: 'Paso_2-TimeoutSMS',
  action: 'click'
};
export const ENROLL_NOT_NUMBER = {
  variable: 'REGISTRO',
  event: 'Paso_2-No_Es_Tu_Numero',
  action: 'click'
};
export const ENROLL_MODAL_NOT_NUMBER = {
  variable: 'REGISTRO',
  event: 'Paso_2- No_Es_Tu_Numero',
  action: 'visualización',
  info1: '0',
  info2: '1'
};
export const ENROLL_GO_RIPLEY = {
  variable: 'REGISTRO',
  event: 'Paso_2-Ir-abancoripley',
  action: 'click',
  info1: '0',
  info2: '1'

};

// SECURY METHOD CLIENT
export const ENROLL_SCREEN_SHOW = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_1.1',
  event_label: 'home_enrolamiento_mobile'
};

export const ENROLL_TO_REGISTER = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_1.2',
  event_label: 'inciar_registro_mobile'
};

export const ENROLL_INPUT_DOCUMENT_NUMBER = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_1.3',
  event_label: 'ingresar_numero_documento_enrolamiento_mobile'
};

export const ENROLL_PRESS_CONTINUE_DOCUMENT_NUMBER = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_1.4',
  event_label: 'continuar_enrolamiento_mobile'
};

export const ENROLL_PRESS_CONTINUE = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_2.3',
  event_label: 'continuar_sms_enrolamiento_mobile'
};

export const ENROLL_PRESS_CANCEL = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_1.5',
  event_label: 'cancelar_enrolamiento_mobile'
};

export const ENROLL_PRESS_NOT_SMS_NUMBER = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_2.0',
  event_label: 'no_es_numero_sms_enrolamiento_mobile'
};

export const ENROLL_SET_SMS_IMPUT = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_2.1',
  event_label: 'agregar_codigo_sms_enrolamiento_mobile'
};

export const ENROLL_SMS_VALIDATE_SUCCESFULL = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_2.2',
  event_label: 'codigo_valido_sms_enrolamiento_mobile'
};

export const ENROLL_PAGE_CREATE_PASSWORD = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_3.1',
  event_label: 'pantalla_ingresar_clave_enrolamiento_mobile'
};

export const ENROLL_ACCEPT_TERMS = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_3.2',
  event_label: 'acepto_terminos_condiciones_enrolamiento_mobile'
};

export const ENROLL_REGISTER_USER = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_3.3',
  event_label: 'agregar_registrarse_mobile'
};

export const ENROLL_CANCEL_SUBMIT_FORM = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_3.4',
  event_label: 'cancelar_registro_clave_mobile'
};

export const ENROLL_USER_CREATE_OK = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_4.1',
  event_label: 'exito_enrolamiento_mobile'
};

export const ENROLL_CLICK_RIPLEY_BANK = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_4.2',
  event_label: 'inicio_sesion_desde_enrolamiento_mobile'
};

export const ENROLL_CLICK_RIPLEY_URL = {
  event: 'enrolamientoMobile_event',
  event_category: 'flujoEnrolamientoMobile',
  event_action: 'step_4.3',
  event_label: 'ir_a_bancoripleyComPe_mobile'
};

export const ENROLL_SCREEN_SHOW_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_1.1',
  event_label: 'home_enrolamiento'
};

export const ENROLL_TO_REGISTER_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_1.2',
  event_label: 'inciar_registro'
};

export const ENROLL_INPUT_DOCUMENT_NUMBER_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_1.3',
  event_label: 'ingresar_numero_documento_enrolamiento'
};

export const ENROLL_PRESS_CONTINUE_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_1.6',
  event_label: 'continuar_enrolamiento'
};

export const ENROLL_PRESS_CANCEL_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_1.7',
  event_label: 'cancelar_enrolamiento'
};

export const ENROLL_SCREEN_ENTER_DNI = {

  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_1.8',
  event_label: 'vista_ingreso_dni_enrolamiento'
};


export const ENROLL_SCREEN_ENTER_SMS = {

  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_1.9',
  event_label: 'vista_ingreso_sms_enrolamiento'
};


export const ENROLL_PRESS_NOT_SMS_NUMBER_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_2.0',
  event_label: 'no_es_numero_sms_enrolamiento'
};

export const ENROLL_SET_SMS_IMPUT_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_2.1',
  event_label: 'agregar_codigo_sms_enrolamiento'
};

export const ENROLL_SMS_VALIDATE_SUCCESFULL_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_2.2',
  event_label: 'codigo_valido_sms_enrolamiento'
};

export const ENROLL_CLIENT_PRESS_CONTINUE_SMS_PWA = {

  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_2.3',
  event_label: 'continuar_sms_enrolamiento'

};

export const ENROLL_CLIENT_PRESS_CANCEL_SMS_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_2.4',
  event_label: 'cancelar_sms_enrolamiento'

};

export const ENROLL_CODIGO_SMS_INCORRECTO = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_2.5',
  event_label: 'codigo_sms_incorrecto_enrolamiento'
};

export const ENROLL_PAGE_CREATE_PASSWORD_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_3.1',
  event_label: 'pantalla_ingresar_clave_enrolamiento'
};

export const ENROLL_ACCEPT_TERMS_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_3.2',
  event_label: 'acepto_terminos_condiciones_enrolamiento'
};

export const ENROLL_REGISTER_USER_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_3.3',
  event_label: 'agregar_registrarse'
};

export const ENROLL_CANCEL_SUBMIT_FORM_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_3.4',
  event_label: 'cancelar_registro_clave'
};

export const ENROLL_USER_CREATE_OK_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_4.1',
  event_label: 'exito_enrolamiento'
};

export const ENROLL_CLICK_RIPLEY_BANK_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_4.2',
  event_label: 'inicio_sesion_desde_enrolamiento'
};

export const ENROLL_CLICK_RIPLEY_URL_PWA = {
  event: 'enrolamiento_event',
  event_category: 'flujoEnrolamiento',
  event_action: 'step_4.3',
  event_label: 'ir_a_bancoripleyComPe'
};

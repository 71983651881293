import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-preguntas-frecuentes-categorias',
  templateUrl: './preguntas-frecuentes-categorias.component.html',
  styleUrls: ['./preguntas-frecuentes-categorias.component.scss'],
})
export class PreguntasFrecuentesCategoriasComponent implements OnInit {
  flecha1: boolean;
  flecha2: boolean;
  flecha3: boolean;
  flecha4: boolean;
  flecha5: boolean;
  flecha6: boolean;
  flecha7: boolean;

  // @ViewChild('scroll') scroll: ElementRef;

  constructor() { }

  ngOnInit():void {
           
    this.inicializarVariables();

  }
  inicializarVariables() {
    this.flecha1  = false;
    this.flecha2  = false;
    this.flecha3  = false;
    this.flecha4  = false;
    this.flecha5  = false;
    this.flecha6  = false;
    this.flecha7  = false;          

  }
  moverFecha(nroFlecha:string) {
    if (nroFlecha === '1') {
      if (!this.flecha1) {
        this.flecha1 = true;
        this.flecha2 = false;
        this.flecha3 = false;
        this.flecha4 = false;
        this.flecha5 = false;
        this.flecha6 = false;
        this.flecha7 = false;

        // window.scroll(0,0);

        // this.scroll.nativeElement.scrollTop = 0;
        // this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
        // window.scrollTo({
        //   top: 100,
        //   behavior: 'smooth'
        // });

      } else {
        this.flecha1 = false;         
      }

      this.ScrollIntoView("#titleFrec");
    }
    if (nroFlecha === '2') {
      if (!this.flecha2) {
        this.flecha1 = false;
        this.flecha2 = true;
        this.flecha3 = false;
        this.flecha4 = false;
        this.flecha5 = false;
        this.flecha6 = false;
        this.flecha7 = false;

      } else {
        this.flecha2 = false;
      }
      this.ScrollIntoView("#btn-acor1PC-1");
    }
    if (nroFlecha === '3') {
      if (!this.flecha3) {
        this.flecha1 = false;
        this.flecha2 = false;
        this.flecha3 = true;
        this.flecha4 = false;
        this.flecha5 = false;
        this.flecha6 = false;
        this.flecha7 = false;

      } else {
        this.flecha3 = false;
      }
      
      this.ScrollIntoView("#btn-acor1PC-5");
    }
    if (nroFlecha === '4') {
      if (!this.flecha4) {
        this.flecha1 = false;
        this.flecha2 = false;
        this.flecha3 = false;
        this.flecha4 = true;
        this.flecha5 = false;
        this.flecha6 = false;
        this.flecha7 = false;

      } else {
        this.flecha4 = false;
      }
      
      this.ScrollIntoView("#btn-acor1PC-3");
    }
    if (nroFlecha === '5') {
      if (!this.flecha5) {
        this.flecha1 = false;
        this.flecha2 = false;
        this.flecha3 = false;
        this.flecha4 = false;
        this.flecha5 = true;
        this.flecha6 = false;
        this.flecha7 = false;

      } else {
        this.flecha5 = false;
      }
      
      this.ScrollIntoView("#btn-acor1PC-2");
    }
    if (nroFlecha === '6') {
      if (!this.flecha6) {
        this.flecha1 = false;
        this.flecha2 = false;
        this.flecha3 = false;
        this.flecha4 = false;
        this.flecha5 = false;
        this.flecha6 = true;
        this.flecha7 = false;
      } else {
        this.flecha6 = false;
      }

      
    }
    if (nroFlecha === '7') {
      if (!this.flecha7) {
        this.flecha1 = false;
        this.flecha2 = false;
        this.flecha3 = false;
        this.flecha4 = false;
        this.flecha5 = false;
        this.flecha6 = false;
        this.flecha7 = true;

      } else {
        this.flecha7 = false;
      }

      this.ScrollIntoView("#btn-acor1PC-4");
    }
  }



  ScrollIntoView(elem: string) {
    setTimeout(() => {
    document.querySelector(elem).scrollIntoView({ behavior: 'smooth', block: 'start',inline:'center' });
    }, 300);

  }

}

import { formatNumber } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';

@Component({
  selector: 'app-segmentaciones',
  templateUrl: './segmentaciones.component.html',
  styleUrls: ['./segmentaciones.component.scss'],
})


export class SegmentacionesComponent implements OnInit {

  myOptions = {
    'placement': 'bottom',
    'showDelay': 0,
    'hideDelay': 300,
    'trigger': 'hover',
    'tooltip-class':'tooltip-inner'
  }




  clasePuntos: any;
  clientePuntos: any;
  nombres: any;
  clientePuntosObjetivo: any;
  fechaRipleyPuntos: any;
  puntaje: any;
  puntajeFaltante: any;
  puntajePorcentaje: any;
  totalRipleyPuntos: any;
  totalRipleyPuntos_: any;
  MensajeTooltip: string;
  MensajeTooltipInfoVioleta: string;
  MensajeTooltipInfoWhite: string;

  public windowsize: any;

  lista = [
    { name: 'CLASE_PUNTOS', value: '01.- SILVER PRIME' },
    { name: 'CLASE_PUNTOS_CLIENTES', value: 'SILVER PRIME' },
    { name: 'CLASE_PUNTOS_CLIENTES_OBJETIVO', value: 'SILVER PRIME Prime' },
    { name: 'CLASE_PUNTOS_OBJETIVO', value: '01.- SILVER PRIME' },
    { name: 'DOCUMENTO', value: '25836096' },
    { name: 'FECHA_RIPLEYPUNTOS', value: '' },
    { name: 'FLG_CLIENTE', value: '1' },
    { name: 'ID_CLIENTE', value: '000002583609601' },
    { name: 'NOMBRE', value: 'Erika' },
    { name: 'PUNTAJE', value: '18,524' },
    { name: 'PUNTAJE_FALTANTE', value: '' },
    { name: 'PUNTAJE_PORCENTAJE', value: '80' },
    { name: 'RIPLEYPUNTOS', value: '89933' },
    { name: 'TIPO_DOCUMENTO', value: '01' }
  ];

  lista1 = [
    { name: 'CLASE_PUNTOS', value: '01.- GOLD' },
    { name: 'CLASE_PUNTOS_CLIENTES', value: 'Gold Prime' },
    { name: 'CLASE_PUNTOS_CLIENTES_OBJETIVO', value: 'Gold Prime' },
    { name: 'CLASE_PUNTOS_OBJETIVO', value: '01.- GOLD' },
    { name: 'DOCUMENTO', value: '25836096' },
    { name: 'FECHA_RIPLEYPUNTOS', value: '' },
    { name: 'FLG_CLIENTE', value: '1' },
    { name: 'ID_CLIENTE', value: '000002583609601' },
    { name: 'NOMBRE', value: 'Erika' },
    { name: 'PUNTAJE', value: '18,524' },
    { name: 'PUNTAJE_FALTANTE', value: '' },
    { name: 'PUNTAJE_PORCENTAJE', value: '80' },
    { name: 'RIPLEYPUNTOS', value: '89933' },
    { name: 'TIPO_DOCUMENTO', value: '01' }
  ];


  constructor(
    @Inject(LOCALE_ID) public locale: string
  ) { }

  ngOnInit() {
   // this.MensajeTooltip = "Para subir a la categoría Gold <br/>necesitas obtener 11,000 puntos <br/>categoría durante el 2022.";
    this.cargaInicial();
  
  }


  cargaInicial() {
    const objData = localStorage.getItem('objData');
    //console.log('cargaInicial');
    //console.log(objData);

    if (objData != null && objData !== '') {
      const listData = JSON.parse(objData);
      // listData = this.lista;
      //console.log(listData);
      if (listData.length > 0) {
        // let clasePuntos = '';
        // let clientePuntos = '';
        // let nombres = '';
        // let clientePuntosObjetivo = '';
        // let fechaRipleyPuntos = '';
        // let puntaje = '';
        // let puntajeFaltante = '';
        // let puntajePorcentaje = '';
        // let totalRipleyPuntos = '';
        // let totalRipleyPuntos_ = '';
        // for (let y = 0; y < listData.length; y++)
        for (const row of listData) {
          if (row.name === 'CLASE_PUNTOS') {
            // this.clasePuntos = listData[y].value;
            this.clasePuntos = row.value;
          }
          if (row.name === 'CLASE_PUNTOS_CLIENTES') {
            this.clientePuntos = row.value;
          }
          if (row.name === 'CLASE_PUNTOS_CLIENTES_OBJETIVO') {
            this.clientePuntosObjetivo = row.value;
          }
          if (row.name === 'FECHA_RIPLEYPUNTOS') {
            this.fechaRipleyPuntos = row.value;
          }
          if (row.name === 'NOMBRE') {
            this.nombres = row.value;
          }
          if (row.name === 'PUNTAJE') {
            this.puntaje = row.value;
          }
          if (row.name === 'PUNTAJE_FALTANTE') {
            this.puntajeFaltante = row.value === '' ? '0' : row.value;
          }
          if (row.name === 'PUNTAJE_PORCENTAJE') {
            this.puntajePorcentaje = row.value === '' ? '0' : row.value;
          }
          if (row.name === 'RIPLEYPUNTOS') {
            this.totalRipleyPuntos_ = row.value;
            this.totalRipleyPuntos = formatNumber(this.totalRipleyPuntos_, this.locale, '1.0-5');

          }
        }
    
        this.nombres = this.nombres + '!';
        this.windowsize = window.innerWidth;
        var year = new Date().getFullYear();
        //this.MensajeTooltip = "Para subir a la categoría Gold <br/>necesitas obtener 11,000 puntos <br/>categoría durante el 2022.";
        const sizecompo = 767.98;
        if (this.clientePuntos.toUpperCase() === 'SILVER PRIME') {
          localStorage.setItem('idSeccionSegmentador', 'divSegmentadorSiver');
          this.MensajeTooltip= "Para subir a la categoría Gold necesitas obtener 11,000 puntos categoría durante el "+year+".";
          // $('#divSegmentadorRipley').hide();
          // $('#divSegmentadorGold').hide();
          // $('#divSegmentadorNoClientes').hide();
          // $('#divSegmentadorSiver').show();

          // $('#divSegmentadorSiver #nombre').text(nombres);
          // $('#divSegmentadorSiver #puntaje').text(puntaje);
          // $('#divSegmentadorSiver #puntajeTotal').text(totalRipleyPuntos);
          // $('#divSegmentadorSiver #puntajeFaltante').text(puntajeFaltante);
          // $('#divSegmentadorSiver #porcentajeBarra').width(
          //   puntajePorcentaje + '%'
          // );
          // $('#divSegmentadorSiver #porcentaje').width(puntajePorcentaje + '%');
          // $('#divSegmentadorSiver #porcentaje').text(puntajePorcentaje + '%');

          if (this.windowsize <= sizecompo) {
            this.mostrarSilver();
          }
        } else if (this.clientePuntos.toUpperCase() === 'GOLD PRIME') {
          localStorage.setItem('idSeccionSegmentador', 'divSegmentadorGold');
          this.MensajeTooltip= "Para mantenerte en la categoría Gold necesitas obtener 11,000 puntos categoría durante el "+year+".";
       
          // $('#divSegmentadorRipley').hide();
          // $('#divSegmentadorSiver').hide();
          // $('#divSegmentadorNoClientes').hide();
          // $('#divSegmentadorGold').show();

          // $('#divSegmentadorGold #nombre').text(nombres);
          // $('#divSegmentadorGold #puntaje').text(puntaje);
          // $('#divSegmentadorGold #puntajeTotal').text(totalRipleyPuntos);
          // $('#divSegmentadorGold #puntajeFaltante').text(puntajeFaltante);
          // $('#divSegmentadorGold #porcentajeBarra').width(
          //   puntajePorcentaje + '%'
          // );
          // $('#divSegmentadorGold #porcentaje').width(puntajePorcentaje + '%');
          // $('#divSegmentadorGold #porcentaje').text(puntajePorcentaje + '%');

          if (this.windowsize <= sizecompo) {
            this.mostrarGold();
          }

          if (this.totalRipleyPuntos === ''
          || this.totalRipleyPuntos === '0'
          || this.totalRipleyPuntos == null
          || this.totalRipleyPuntos === undefined) {
            // $('.divNoNormalInfoGold').attr('style', 'display:block');
            // $('.divNormalInfoGold').attr('style', 'display:none');
          }


        }else if (this.clientePuntos.toUpperCase() === 'PLUS') {
          localStorage.setItem('idSeccionSegmentador', 'divSegmentadorPlus');
          this.MensajeTooltip= "Para subir a la categoría Silver necesitas obtener 5000 puntos categoría durante el "+year+".";
          // $('#divSegmentadorSiver').hide();
          // $('#divSegmentadorGold').hide();
          // $('#divSegmentadorNoClientes').hide();
          // $('#divSegmentadorRipley').show();

          // $('#divSegmentadorRipley #nombre').text(nombres);
          // $('#divSegmentadorRipley #puntaje').text(puntaje);
          // $('#divSegmentadorRipley #puntajeTotal').text(totalRipleyPuntos);
          // $('#divSegmentadorRipley #puntajeFaltante').text(puntajeFaltante);
          // $('#divSegmentadorRipley #porcentajeBarra').width(
          //   puntajePorcentaje + '%'
          // );
          // $('#divSegmentadorRipley #porcentaje').width(puntajePorcentaje + '%');
          // $('#divSegmentadorRipley #porcentaje').text(puntajePorcentaje + '%');

          if (this.windowsize <= sizecompo) {
            this.mostrarSilver();
          }
        } 
        else if (this.clientePuntos.toUpperCase() === 'RIPLEY') {
          localStorage.setItem('idSeccionSegmentador', 'divSegmentadorSiver');
          this.MensajeTooltip= "Para subir a la categoría Plus necesitas obtener 2,500 puntos categoría durante el "+year+".";
          // $('#divSegmentadorSiver').hide();
          // $('#divSegmentadorGold').hide();
          // $('#divSegmentadorNoClientes').hide();
          // $('#divSegmentadorRipley').show();

          // $('#divSegmentadorRipley #nombre').text(nombres);
          // $('#divSegmentadorRipley #puntaje').text(puntaje);
          // $('#divSegmentadorRipley #puntajeTotal').text(totalRipleyPuntos);
          // $('#divSegmentadorRipley #puntajeFaltante').text(puntajeFaltante);
          // $('#divSegmentadorRipley #porcentajeBarra').width(
          //   puntajePorcentaje + '%'
          // );
          // $('#divSegmentadorRipley #porcentaje').width(puntajePorcentaje + '%');
          // $('#divSegmentadorRipley #porcentaje').text(puntajePorcentaje + '%');

          if (this.windowsize <= sizecompo) {
            this.mostrarSilver();
          }
        }
      } else {
        localStorage.setItem('idSeccionSegmentador', 'divSegmentadorNoClientes');
        this.clientePuntos = 'NOCLIENTES';
        // $('#divSegmentadorSiver').hide();
        // $('#divSegmentadorGold').hide();
        // $('#divSegmentadorRipley').hide();
        // $('#divSegmentadorNoClientes').show();
      }
    } else {
      localStorage.setItem('idSeccionSegmentador', 'divSegmentadorNoClientes');
      this.clientePuntos = 'NOCLIENTES';
      // $('#divSegmentadorSiver').hide();
      // $('#divSegmentadorGold').hide();
      // $('#divSegmentadorRipley').hide();
      // $('#divSegmentadorNoClientes').show();
      this.mostrarSilver();
    }
    this.MensajeTooltipInfoWhite="Son los puntos acumulados entre el 01 de enero y el 31 de diciembre de cada año que te permiten acceder a las categorías Plus, Silver y Gold. No se consideran los puntos adquiridos por bonos y/o campañas promocionales.";
    this.MensajeTooltipInfoVioleta="Puntos que puedes canjear por Gift Cards, Súper Canjes, Experiencias o Millas. Tienen vigencia de 12 meses (excepto puntos categoría Gold con vigencia de 24 meses).";
  }

  mostrarSilver() {
    // const idSecSegmentador = localStorage.getItem('idSeccionSegmentador');
    // $('#' + idSecSegmentador + ' #markerGold').hide();
    // $('#' + idSecSegmentador + ' #markerGrey').show();

    // $('#' + idSecSegmentador + ' #seccionGold').animate(
    //   {
    //     'margin-left': '100%'
    //   },
    //   500
    // );

    // $('#' + idSecSegmentador + ' #seccionSilver').animate(
    //   {
    //     'margin-left': '0%'
    //   },
    //   500
    // );
    // setTimeout(function(){  $('#' + idSecSegmentador + ' #seccionGold').css('display', 'none'); }, 400);

  }
  mostrarGold() {
    // const idSecSegmentador = localStorage.getItem('idSeccionSegmentador');
    // $('#' + idSecSegmentador + ' #markerGrey').hide();
    // $('#' + idSecSegmentador + ' #markerGold').show();
    // $('#' + idSecSegmentador + ' #seccionGold').css('display', 'block');
    // $('#' + idSecSegmentador + ' #seccionSilver').animate(
    //   {
    //     'margin-left': '-100%'
    //   },
    //   500
    // );
    // $('#' + idSecSegmentador + ' #seccionGold').animate(
    //   {
    //     'margin-left': '0%'
    //   },
    //   500
    // );
  }

}

import { PeruvianCurrencyPipe } from 'src/app/pipes/peruvian-currency/peruvian-currency.pipe';
import { NgModule } from '@angular/core';
import { DynamicMoneyInputDirective } from 'src/app/directives/dynamic-money-directive/dynamic-money.directive';
import { AlphaInputDirective } from 'src/app/directives/alpha-directive/alpha.directive';
import { CtrlKeysDirective } from 'src/app/directives/ctrl-keys-directive/ctrl-keys.directive';
import { CurrencyPipe } from '@angular/common';
import { MoneyInputDirective } from 'src/app/directives/money-directive/money.directive';
import { MoneyDecInputDirective } from './money-directive/moneyDec.directive';
import { MoneyWithoutDecInputDirective } from './money-directive/moneyWithoutDec.directive';

@NgModule({
  declarations: [
    MoneyInputDirective,
    MoneyDecInputDirective,
    DynamicMoneyInputDirective,
    MoneyWithoutDecInputDirective,
    AlphaInputDirective,
    CtrlKeysDirective
  ],
  exports: [
    MoneyInputDirective,
    MoneyDecInputDirective,
    DynamicMoneyInputDirective,
    MoneyWithoutDecInputDirective,
    AlphaInputDirective,
    CtrlKeysDirective
  ],
  providers: [
    PeruvianCurrencyPipe,
    CurrencyPipe,
  ]
})
export class DirectivesModule {}

import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IInscriptionRequest, ISegmentationRequest, ISegmentationResponse } from 'src/app/common/interfaces/segmentation.interface';
import { CONTACT_DETAIL_MOCK } from 'src/app/common/mocks/account.mocks';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class SegmentationService {

  // menuOptions: Promise<IMenuOptions>;

  headers = new HttpHeaders()
    .set('Content-Type', 'application/x-www-form-urlencoded;');

  constructor(
    private http: HttpService,
    private authService: AuthService,
  ) { }

  // public async checkMenuOptions(): Promise<IMenuOptions> {
  //   const url = environment.BASE_BACKEND_WORKER + '/admin/user/menu-options/authorization';
  //   return this.http.post(url, {}, CHECK_BLACK_LIST_MOCK).toPromise();
  // }

  public getSetmentantion(params: ISegmentationRequest, token: string): Promise<ISegmentationResponse> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json;')
      .set('Authorization', `Bearer ${token}`);

    const url = environment.BASE_BACKEND + '/clientwebapi/api/segmentacion/getsetmentantion';
    return this.http.post<ISegmentationResponse>(url, params, CONTACT_DETAIL_MOCK, headers).toPromise();
  }

  public RegisterInscripcionNroDocumentoFormCMS(params: IInscriptionRequest, token: string, tokenCms: string): Promise<ISegmentationResponse> {
    
    if (environment.ENV === 'SOLOQA') {
      console.log(environment.ENV);
      const headersCms = new HttpHeaders()
      .set('Content-Type', 'application/json;')
      .set('Authorization', `Bearer ${tokenCms}`);

      const url = environment.BACKEND_FORM_CMS_Q + '/registerformcms';
      return this.http.post<ISegmentationResponse>(url, params, CONTACT_DETAIL_MOCK, headersCms).toPromise();

    }
    else {
      const headers = new HttpHeaders()
      .set('Content-Type', 'application/json;')
      .set('Authorization', `Bearer ${token}`);

      const url = environment.BASE_BACKEND_WORKER + '/registerformcms';
      return this.http.post<ISegmentationResponse>(url, params, CONTACT_DETAIL_MOCK, headers).toPromise();
    }
    
  }

}

import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-pantalla-notcanje',
  templateUrl: './pantalla-notcanje.component.html',
  styleUrls: ['./pantalla-notcanje.component.scss'],
})
export class PantallaNotcanjeComponent implements OnInit {
  pathCanjeLatamPassDNI = 'canje-latam-pass';
  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {

  }

  ngOnInit() {}

  volverCuenta()
  {
    this.document.location.href = '/' + this.pathCanjeLatamPassDNI;
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-vitrina-slider-home',
  templateUrl: './vitrina-slider-home.component.html',
  styleUrls: ['./vitrina-slider-home.component.scss'],
})
export class VitrinaSliderHomeComponent implements OnInit {
  @ViewChild('mySlider') slides: IonSlides;
  mySlideOptions = {
    //autoplay: true,
    autoplay: {
      delay: 5000,
    },
    initialSlide: 0,
    slideShadows: true,
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
    spaceBetween: 0,
    speed: 1100,
    pager: true,
    paginationType: 'fraction',
    effect: 'slide',
    dir: 'rtl',
    //delay: 10000,
    slideinterval: 10000,
  };

  numero_slides: string;
  rutaIMG: string;
  XS: string;
  heightVitrina: string;
  heightImgVitrina: string;
  widthImgVitrina: string;
  maxWidthImg: string;

  sliderHomeList: any = [];
  constructor() { }

  ngOnInit() {
    this.inicializarVariables();
    this.getListaHomeSlider();
    // this.slides.startAutoplay();
  }
  inicializarVariables() {
    this.numero_slides = '5';
    this.rutaIMG = 'assets/img/home/slider/';
    this.XS = 'xs-';
    this.heightVitrina = '480px';
    this.heightImgVitrina = '100%';
    this.widthImgVitrina = '100%';
    this.maxWidthImg = '1190px';

  }

  slidePrev() {
    this.slides.slidePrev();
    this.slides.startAutoplay();
  }
  slideNext() {
    this.slides.slideNext();
    this.slides.startAutoplay();
  }
  getListaHomeSlider() {

    const item_sliderHomeList = [
      // vitrinaSlide 1: PreCyber Latam
      {
        contenidoPrimerTexto_vitrina: '¡Aprovecha el 40% Dscto. en Millas LATAM PASS!',
        mobile_PrimerTexto_vitrina: '¡Aprovecha el 40% Dscto. en Millas LATAM PASS!',
        contenidoSegundoTexto_vitrina: '<br>Canjea tus Puntos Go por hasta 50,000 Millas LATAM PASS. Válido del 1 al 4 de abril de 2025. *Sujeto a Stock<br><br><br>',
        mobile_SegundoTexto_vitrina: 'Canjea tus Puntos Go por hasta 50,000 Millas LATAM PASS. Válido del 1 al 4 de abril de 2025. *Sujeto a Stock<br><br>',
        hrefBTN1_vitrina: 'canje-latam-pass',
        //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
        href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
        verCondiciones_Mobile:'n',//s/n
        contenidoBTN1_vitrina: 'Canjea aquí',
        textAlignBTN1_Desktop: 'left',   //left,center,right
        textAlignBTN1_Mobile: 'left',   //left,center,right
        textMarginLeftBTN1_Desktop: '0px',   //0px
        textMarginLeftBTN1_Mobile: '0px',   //0px
        hrefBTN2_vitrina: '', 
        contenidoBTN2_vitrina: '',
        imagen_vitrina: 'banner-home-ripley-precyberlatam2-abr25.png',
        taggeo: 'banner-home-ripley-precyberlatam2-abr25'
      },
      // // vitrinaSlide 1: Acumulación x3
      // {
      //   contenidoPrimerTexto_vitrina: 'TODAS tus compras en SUPERMERCADOS \nmultiplican x3 tus Puntos Go',
      //   mobile_PrimerTexto_vitrina: 'TODAS tus compras en SUPERMERCADOS \nmultiplican x3 tus Puntos Go',
      //   contenidoSegundoTexto_vitrina: '<br>Exclusivo con Tarjeta de Crédito Banco Ripley hasta el 31 de enero.<br><br>',
      //   mobile_SegundoTexto_vitrina: '<br>Exclusivo con Tarjeta de Crédito Banco Ripley hasta el 31 de enero.<br><br>',
      //   hrefBTN1_vitrina: 'https://bancoripley.com.pe/formularios/index.html?plantilla=compra-en-supermercados',
      //   //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
      //   href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
      //   verCondiciones_Mobile:'n',//s/n
      //   contenidoBTN1_vitrina: 'Inscríbete aquí',
      //   textAlignBTN1_Desktop: 'left',   //left,center,right
      //   textAlignBTN1_Mobile: 'left',   //left,center,right
      //   textMarginLeftBTN1_Desktop: '0px',   //0px
      //   textMarginLeftBTN1_Mobile: '0px',   //0px
      //   hrefBTN2_vitrina: '',
      //   contenidoBTN2_vitrina: '',
      //   imagen_vitrina: 'banner-home-supermercados-ene.png',
      //   taggeo: 'banner-home-supermercados-ene'
      // },
      // // vitrinaSlide 2: CyberWOW Multiplica  - Del 7 al 10 de abril 2025
      // {
      //   contenidoPrimerTexto_vitrina: '<br><br><br>',
      //   mobile_PrimerTexto_vitrina: '<br><br><br><br><br><br><br><br><br><br><br><br><br><br>',
      //   contenidoSegundoTexto_vitrina: '<br><br><br>',
      //   mobile_SegundoTexto_vitrina: '<br><br>',
      //   hrefBTN1_vitrina: 'https://bancoripley.com.pe/formularios/index.html?plantilla=registro-primax',
      //   //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
      //   href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
      //   verCondiciones_Mobile:'n',//s/n
      //   contenidoBTN1_vitrina: 'Inscríbete aquí',
      //   textAlignBTN1_Desktop: 'left',   //left,center,right
      //   textAlignBTN1_Mobile: 'left',   //left,center,right
      //   textMarginLeftBTN1_Desktop: '0px',   //0px
      //   textMarginLeftBTN1_Mobile: '-20px',   //0px
      //   hrefBTN2_vitrina: '',
      //   contenidoBTN2_vitrina: '',
      //   imagen_vitrina: 'banner-home-ripley-cyberwowmulti-abr.png',
      //   taggeo: 'banner-home-ripley-cyberwowmulti-abr'
      // },
      // vitrinaSlide 3: Experiencias Cyber WOW
      {
        contenidoPrimerTexto_vitrina: '¡Hasta 50% Dscto!',
        mobile_PrimerTexto_vitrina: '¡Hasta 50% Dscto!',
        contenidoSegundoTexto_vitrina: '<br>En vales y experiencias, durante todo el mes del cyber.<br><br>',
        mobile_SegundoTexto_vitrina: '<br>En vales y experiencias, durante todo el mes del cyber.<br><br>',
        hrefBTN1_vitrina: 'https://bancaenlinea.bancoripley.com.pe/home/ripley-points',
        //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
        href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
        verCondiciones_Mobile:'n',//s/n
        contenidoBTN1_vitrina: 'Canjea aquí',
        textAlignBTN1_Desktop: 'left',   //left,center,right
        textAlignBTN1_Mobile: 'left',   //left,center,right
        textMarginLeftBTN1_Desktop: '0px',   //0px
        textMarginLeftBTN1_Mobile: '0px',   //0px
        hrefBTN2_vitrina: '',
        contenidoBTN2_vitrina: '',
        imagen_vitrina: 'banner-home-ripley-expcyber-abr.png',
        taggeo: 'banner-home-ripley-expcyber-abr'
      },
      // vitrinaSlide 3: Super Canjes
      {        
        contenidoPrimerTexto_vitrina: '¡Tu estilo combina con tus Puntos Go!',
        mobile_PrimerTexto_vitrina: '¡Tu estilo combina con tus Puntos Go!',
        contenidoSegundoTexto_vitrina: '<br>Aprovecha el especial de polos y jeans. Revisa todo lo que puedes canjear en el catálogo del mes.<br><br>',
        mobile_SegundoTexto_vitrina: 'Aprovecha el especial de polos y jeans. Revisa todo lo que puedes canjear en el catálogo del mes.<br><br>',
        hrefBTN1_vitrina: 'https://firebasestorage.googleapis.com/v0/b/hbk-prd.appspot.com/o/RPGO%2FCATALOGOS%2FpdfCatalogoSC?alt=media',
        //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
        href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
        verCondiciones_Mobile:'n',//s/n
        contenidoBTN1_vitrina: 'Mira el catálogo',
        textAlignBTN1_Desktop: 'left',   //left,center,right
        textAlignBTN1_Mobile: 'left',   //left,center,right
        textMarginLeftBTN1_Desktop: '0px',   //0px
        textMarginLeftBTN1_Mobile: '0px',   //0px
        hrefBTN2_vitrina: '',
        contenidoBTN2_vitrina: '',
        imagen_vitrina: 'banner-home-supercanje-abr.png',
        taggeo: 'banner-home-supercanje-abr'
      },
      
      // // vitrinaSlide 4: Experiencias
      // {
      //   contenidoPrimerTexto_vitrina: '¡Ahorra en la lista de útiles con tus Puntos Go!<br>',
      //   mobile_PrimerTexto_vitrina: '¡Ahorra en la lista de útiles con tus Puntos Go!',
      //   contenidoSegundoTexto_vitrina: '<br>Canjea desde 3,000 Puntos Go, un vale de S/50 en Tai Loy, Wong y Metro.<br><br>',
      //   mobile_SegundoTexto_vitrina: 'Canjea desde 3,000 Puntos Go, un vale de S/50 en Tai Loy, Wong y Metro.<br><br>',
      //   hrefBTN1_vitrina: 'https://bancaenlinea.bancoripley.com.pe/home/ripley-points',
      //   //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
      //   href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
      //   verCondiciones_Mobile:'n',//s/n
      //   contenidoBTN1_vitrina: 'Canjea aquí',
      //   textAlignBTN1_Desktop: 'left',   //left,center,right
      //   textAlignBTN1_Mobile: 'left',   //left,center,right
      //   textMarginLeftBTN1_Desktop: '0px',   //0px
      //   textMarginLeftBTN1_Mobile: '0px',   //0px
      //   hrefBTN2_vitrina: '',
      //   contenidoBTN2_vitrina: '',
      //   imagen_vitrina: 'banner-home-experiencias-mar.png',
      //   taggeo: 'banner-home-experiencias-mar'
      // },

      // vitrinaSlide 5: Gift Cards
      {
        contenidoPrimerTexto_vitrina: '¡Solo en abril!<br>',
        mobile_PrimerTexto_vitrina: '¡Solo en abril!<br>',
        contenidoSegundoTexto_vitrina: '<br>Canjea esta Gift Card Promocional de S/50, por 2,500 Puntos Go y úsala en Tiendas Ripley, Ripley.com y Ripley App<br><br>',
        mobile_SegundoTexto_vitrina: '<br>Canjea esta Gift Card Promocional de S/50, por 2,500 Puntos Go y úsala en Tiendas Ripley, Ripley.com y Ripley App<br><br>',
        hrefBTN1_vitrina: 'https://bancaenlinea.bancoripley.com.pe/home/ripley-points',
        //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
        href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
        verCondiciones_Mobile:'n',//s/n
        contenidoBTN1_vitrina: 'Canjea aquí',
        textAlignBTN1_Desktop: 'left',   //left,center,right
        textAlignBTN1_Mobile: 'left',   //left,center,right
        textMarginLeftBTN1_Desktop: '0px',   //0px
        textMarginLeftBTN1_Mobile: '0px',   //0px
        hrefBTN2_vitrina: '',
        contenidoBTN2_vitrina: '',
        imagen_vitrina: 'banner-home-giftcar-abr.png',
        taggeo: 'banner-home-giftcar-abr'
      },
      
      // vitrinaSlide 6: LATAM regular
      {
        contenidoPrimerTexto_vitrina: '¡Viaja con tus Puntos Go!',
        mobile_PrimerTexto_vitrina: '¡Viaja con tus Puntos Go!',
        contenidoSegundoTexto_vitrina: '<br>Canjea tus Puntos Go por Millas LATAM PASS y úsalas para recorrer el mundo.<br><br>',
        mobile_SegundoTexto_vitrina: '<br>Canjea tus Puntos Go por Millas LATAM PASS y úsalas para recorrer el mundo. <br><br>',
        hrefBTN1_vitrina: 'canje-latam-pass', 
        //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
        href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
        verCondiciones_Mobile:'n',//s/n
        contenidoBTN1_vitrina: 'Canjea aquí',
        textAlignBTN1_Desktop: 'left',   //left,center,right
        textAlignBTN1_Mobile: 'left',   //left,center,right
        textMarginLeftBTN1_Desktop: '0px',   //0px
        textMarginLeftBTN1_Mobile: '0px',   //0px
        hrefBTN2_vitrina: '',
        contenidoBTN2_vitrina: '',
        imagen_vitrina: 'banner-home-latamregular-abr.png',
        taggeo: 'banner-home-latamregular-abr'
      },
      
      // vitrinaSlide 1: lanzamiento
      {
        contenidoPrimerTexto_vitrina: 'Con mis \nRipley Puntos GO, \n¡lo quiero, lo canjeo!',
        mobile_PrimerTexto_vitrina: '',
        contenidoSegundoTexto_vitrina: '<br>Acumula Puntos Go \ncon tu Tarjeta Banco Ripley \ny canjéalos por lo que quieras.<br><br>',
        mobile_SegundoTexto_vitrina: '<br><br><br>',
        hrefBTN1_vitrina: 'https://bancoripley.com.pe/formularios/index.html?plantilla=compra-en-supermercados',
        //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
        href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
        verCondiciones_Mobile:'n',//s/n
        contenidoBTN1_vitrina: '',
        textAlignBTN1_Desktop: 'left',   //left,center,right
        textAlignBTN1_Mobile: 'left',   //left,center,right
        textMarginLeftBTN1_Desktop: '0px',   //0px
        textMarginLeftBTN1_Mobile: '0px',   //0px
        hrefBTN2_vitrina: '',
        contenidoBTN2_vitrina: '',
        imagen_vitrina: 'banner-home-lanzamiento-feb.png',
        taggeo: 'banner-home-lanzamiento-feb'
      },
      // // vitrinaSlide 6: Lo quiero, Lo Canjeo
      // {
      //   contenidoPrimerTexto_vitrina: 'Con mis \nRipley Puntos GO, \n¡lo quiero, lo canjeo!',
      //   mobile_PrimerTexto_vitrina: '',
      //   contenidoSegundoTexto_vitrina: '<br>Acumula Puntos Go con tu Tarjeta \nBanco Ripley y canjéalos \npor lo que más quieras.<br><br>',
      //   mobile_SegundoTexto_vitrina: '<br><br><br>',
      //   hrefBTN1_vitrina: 'canje-latam-pass', 
      //   //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
      //   href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
      //   verCondiciones_Mobile:'n',//s/n
      //   contenidoBTN1_vitrina: '',
      //   textAlignBTN1_Desktop: 'left',   //left,center,right
      //   textAlignBTN1_Mobile: 'left',   //left,center,right
      //   textMarginLeftBTN1_Desktop: '0px',   //0px
      //   textMarginLeftBTN1_Mobile: '0px',   //0px
      //   hrefBTN2_vitrina: '',
      //   contenidoBTN2_vitrina: '',
      //   imagen_vitrina: 'banner-home-lanzamiento-nov.png',
      //   taggeo: 'banner-home-lanzamiento-nov'
      // },

      // vitrinaSlide 6: RPass
      {
        contenidoPrimerTexto_vitrina: 'Información importante',
        mobile_PrimerTexto_vitrina: 'Información importante',
        contenidoSegundoTexto_vitrina: '<br>¡Ahora más fácil y rápido! Autoriza el canje de tus Ripley Puntos Go desde tu App.<br><br>',
        mobile_SegundoTexto_vitrina: '<br>¡Ahora más fácil y rápido! Autoriza el canje de tus Ripley Puntos Go desde tu App.<br><br>',
        hrefBTN1_vitrina: 'https://www.bancoripley.com.pe/r-pass/index.html',
        //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
        href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
        verCondiciones_Mobile:'n',//s/n
        contenidoBTN1_vitrina: 'Actívalo aquí',
        textAlignBTN1_Desktop: 'left',   //left,center,right
        textAlignBTN1_Mobile: 'left',   //left,center,right
        textMarginLeftBTN1_Desktop: '0px',   //0px
        textMarginLeftBTN1_Mobile: '0px',   //0px
        hrefBTN2_vitrina: '',
        contenidoBTN2_vitrina: '',
        imagen_vitrina: 'banner-home-rpass-feb.png',
        taggeo: 'banner-home-rpass-feb'
      },
      
    ];

    this.sliderHomeList = item_sliderHomeList;

  }
}

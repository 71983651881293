import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  //descripcionFecha = 'Ver Reglamento del Programa Ripley Puntos Go';
  //fechaUpdate = 'Actualizado desde el 01/06/2022';

  descripcionFecha = 'Términos y Condiciones';
  fechaUpdate = '(desde el  01/02/2024)';

  BasePromodescripcionFecha = 'Bases Promocionales';
  BasePromofechaUpdate = '(hasta el 31/01/2024)';

  terminosCondiciones = 'Términos y Condiciones';
  terminosCondicionesFecha = '(hasta el 01/02/2023)';  

  constructor() { }

  ngOnInit() {
  }

}

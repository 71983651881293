import { Component, OnInit, ViewEncapsulation, ViewChild, NgZone, Inject, ChangeDetectionStrategy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';

import { ICustomerRequest, ICustomerResponse } from 'src/app/common/interfaces/customer.interface';
import { IProductResponse } from 'src/app/common/interfaces/product.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CanjeMillasService } from 'src/app/services/canjes/canje-millas.service';
import { IProductTransactionsResponse } from '@common/interfaces/productTransactions.interface';
import { UtilsService } from '@services/utils/utils';

@Component({
  selector: 'app-miscanjes-latam',
  templateUrl: './miscanjes-latam.component.html',
  styleUrls: ['./miscanjes-latam.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default
})
export class MiscanjesLatamComponent implements OnInit {

  public listProductTransactionsLatam: any[] = [];
  
  identidadForm: FormGroup;

  customerData: ICustomerResponse;

  listPaquetesLatam: IProductResponse[];
  // listProductTransactionsLatam: IProductTransactionsResponse[];
  selectPaquete: IProductResponse;

  selectedTitle: string;
  termsAndConditionsPDF: string;
  haveEnoughPoints = false;
  pointMessage = '';

  progress: number = 0;
  label: string;

  pathCanjeLatamPassDNI = 'canje-latam-pass?type=miscanjes';
  pathCanjesCategoryLatam = 'canjes?category=latampass';
  pathCanjeLatamDetalle = 'detalle-canje?type=miscanjes';
  showLoader = false;

  //Ini - Config Pagination
  public filter: string = '';
  public maxSize: number = 7;
  public directionLinks: boolean = true;
  public autoHide: boolean = true;
  public responsive: boolean = true;
  public config: PaginationInstance = {
      id: 'advanced',
      itemsPerPage: 10,
      currentPage: 1
  };
  public labels: any = {
      previousLabel: 'Anterior',
      nextLabel: 'Siguiente',
      screenReaderPaginationLabel: 'Pagination',
      screenReaderPageLabel: 'page',
      screenReaderCurrentLabel: `You're on page`
  };
  //End - Config Pagination
  
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _ngZone: NgZone,
    private authService: AuthService,
    private canjeMillasService: CanjeMillasService,
    private utils: UtilsService,
    @Inject(DOCUMENT) private document: Document
  ) {

  }

  ngOnInit() {
    this.createForm();
    if (history.state.data === undefined) {
      // // // this.router.navigateByUrl(this.pathCanjeLatamPassDNI);
      this.document.location.href = '/' + this.pathCanjeLatamPassDNI;
      // const userId = "C-70037202";  //QA
      // // const userId = "C-25836096";   //Prd: Erika 
      // // const userId = "C-09797105";    //Prd: Erika 
      // this.getPaquetesMillas(userId);
    }
    else {
      this.customerData = history.state.data;
      const userId = this.customerData.customer.userId;
      this.getPaquetesMillas(userId);
    }
  }
  createForm() {
    this.identidadForm = this.formBuilder.group({
      codigoSMS: [{ value: null, disabled: false }, [Validators.required]],
    });
  }
  getPaquetesMillas(userId: string) {
    console.log('getPaquetesMillas');
    console.log(this.customerData);

    this.authService.getAuthentication0().subscribe(
      (response: any) => {
        // console.log(response);
        const status = response.status;
        const bodyResponse = response.body;
        const statusOk = 200;
        switch (status) {
          case statusOk:
            const token = bodyResponse.access_token;
            //console.log(token);

            this.canjeMillasService.getProductTransactionLatamList(token, userId)
              .then((dataResult) => {
                if (dataResult.length > 0) {
                  this.listProductTransactionsLatam = dataResult;
                  // this.listPaquetesLatam = dataResult;
                  console.log(this.listProductTransactionsLatam);
                } else {
                  // this.router.navigateByUrl('segmentacion');
                }
              })
              .catch(() => {
                console.log('catch');
              });

            break;

          default:

            break;
        }

      },
      (error) => {
        // const errorBody = error.error;
        // const status = error.status;
      }
    );
  }

  public async goToRedeem(product: any) {
    const sku = product.sku;
    const code = product.code;
    const category = product.category;
    const confirmation = false;
    const fullName=product.fullName;

    console.log("goToRedeem: ", product);
    this.canjeMillasService.setConsultaEstadoExito(product);
    // this.router.navigate([`/${this.pathCanjeLatamDetalle}`, { sku, code, category,fullName }]); //detalle-canje
    // this.router.navigateByUrl(this.pathCanjeLatamDetalle, { state: { ProductData: product } });
    this.router.navigateByUrl('/detalle-canje', { state: { ProductData: product } });

  }

  public formatDate(date: Date) {
    date = new Date(date);
    return this.utils.formatDate(date, 'dd/MM/yyyy');
  }

  public getExpirationDate(myCode: any) {
    if (myCode.expirationDate) {
      const currentTimestamp = new Date().getTime();
      let fechaExpiracion = null;
      if (myCode.expirationDate.hasOwnProperty('seconds')) {
        fechaExpiracion = new Date(myCode.expirationDate.toDate());
      } else {
        fechaExpiracion = new Date(myCode.expirationDate);
      }
      let markAsUsed: boolean = myCode.markAsUsed != null ? myCode.markAsUsed : false;
      if (fechaExpiracion.getTime() < currentTimestamp) {
        return "Vencido";
      } else if (markAsUsed) {
        return "Utilizado";
      }
      else {
        const diferenciaMilisegundos = fechaExpiracion.getTime() - currentTimestamp;
        const milisegundosPorMes = 1000 * 60 * 60 * 24 * 30.44; // Promedio de días por mes
        const diferenciaMeses = Math.floor(diferenciaMilisegundos / milisegundosPorMes);
        if (diferenciaMeses == 1) {
          return "Vence en " + diferenciaMeses + " mes";
        } else {
          return "Vence en " + diferenciaMeses + " meses";
        }
      }
    }
    return "";
  }

  public getStatusRedeem(status: string) {
    if (status) {
      if (status == "completed") {
        return "Canjeado";
      }
      else if (status == "validating") {
        return "Validando";
      }
      else if (status == "refunded") {
        return "Reembolsado";
      }
      else if (status == "limit-exceeded") {
        return "Error Límite exedido";
      }
      else if (status == "error-service-redeem") {
        return "Error Canje";
      }
      else if (status == "error-service-points") {
        return "Error puntos";
      }
      else {
        return "Error";
      }
    }
    else {
      return "";
    }    
  }

  onPageChange(number: number) {
    console.log(`pageChange(${number})`);
    this.config.currentPage = number;
  }

  onPageBoundsCorrection(number: number) {
    console.log(`pageBoundsCorrection(${number})`);
    this.config.currentPage = number;
  }

  onTerminosCondiciones() {
    window.open(this.termsAndConditionsPDF, '_blank');
  }

  onRetornarAlInicio() {
    this.showLoader = true;
    
    //console.log('onRetornarAlInicio');
    //console.log(clientData);
    // this.router.navigateByUrl(this.pathConfirmaPaquete, { state: { ClientData: clientData } });
    this.router.navigateByUrl(this.pathCanjesCategoryLatam);
    this.showLoader = false;

  }
  onCancelar() {
    // this.router.navigateByUrl(this.pathCanjeLatamPassDNI);
    this.showLoader = false;
    this.document.location.href = '/' + this.pathCanjeLatamPassDNI;
  }

}

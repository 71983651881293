import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-vitrina-slider-canjepromociones',
  templateUrl: './vitrina-slider-canjepromociones.component.html',
  styleUrls: ['./vitrina-slider-canjepromociones.component.scss'],
})
export class VitrinaSliderCanjepromocionesComponent implements OnInit {
  @ViewChild('mySliderCanjes') slides: IonSlides;
  mySlideOptions = {
    //autoplay: true,
    autoplay: {
      delay: 5000,
    },
    initialSlide: 0,
    slideShadows: true,
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
    spaceBetween: 0,
    speed: 1100,
    pager: true,
    paginationType: 'fraction',
    effect: 'slide',
    dir: 'rtl',
    //delay: 10000,
    slideinterval: 10000,
  };
  barra_nav_slider: boolean;
  numero_slides: string;
  rutaIMG: string;
  XS: string;
  heightVitrina: string;
  heightImgVitrina: string;
  widthImgVitrina: string;
  maxWidthVitrina: string;
  maxWidthImg: string;
  sliderCanjesList: any = [];


  constructor() { }

  ngOnInit() {
    this.inicializarVariables();
    this.getListaCanjesSlider();
    // this.slides.startAutoplay();
  }
  inicializarVariables() {
    this.numero_slides = '4';
    this.rutaIMG = 'assets/img/canjes_promociones/slider/';
    this.XS = 'xs-';
    this.heightVitrina = '480px';
    this.heightImgVitrina = '100%';
    this.widthImgVitrina = '100%';
    this.maxWidthVitrina = '1190px';
    this.maxWidthImg = '1190px';

  }
  slidePrev() {
    this.slides.slidePrev();
    this.slides.startAutoplay();
  }
  slideNext() {
    this.slides.slideNext();
    this.slides.startAutoplay();
  }

  getListaCanjesSlider() {
    const item_sliderCanjesList = [
      // {
      //   contenidoPrimerTexto_vitrina: '\n\n\n\n',
      //   mobile_PrimerTexto_vitrina: '\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n',
      //   contenidoSegundoTexto_vitrina: '<br><br><br>',
      //   mobile_SegundoTexto_vitrina: '<br><br><br>',
      //   hrefBTN1_vitrina: 'https://bancoripley.com.pe/formularios/index.html?plantilla=campana-2',
      //   //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
      //   href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
      //   verCondiciones_Mobile:'n',//s/n
      //   contenidoBTN1_vitrina: 'Inscríbete aquí',
      //   textAlignBTN1_Desktop: 'left',   //left,center,right
      //   textAlignBTN1_Mobile: 'right',   //left,center,right
      //   hrefBTN2_vitrina: '',
      //   contenidoBTN2_vitrina: '',
      //   imagen_vitrina: 'banner-home-ripley-game-ene.png',
      //   taggeo: 'banner-home-ripley-game-ene'
      // },
      // // vitrinaSlide 1: Acumulación x3
      // {
      //   contenidoPrimerTexto_vitrina: 'TODAS tus compras en SUPERMERCADOS \nmultiplican x3 tus Puntos Go',
      //   mobile_PrimerTexto_vitrina: 'TODAS tus compras en SUPERMERCADOS \nmultiplican x3 tus Puntos Go',
      //   contenidoSegundoTexto_vitrina: '<br>Exclusivo con Tarjeta de Crédito Banco Ripley hasta el 31 de enero.<br><br>',
      //   mobile_SegundoTexto_vitrina: '<br>Exclusivo con Tarjeta de Crédito Banco Ripley hasta el 31 de enero.<br><br>',
      //   hrefBTN1_vitrina: 'https://bancoripley.com.pe/formularios/index.html?plantilla=compra-en-supermercados',
      //   //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
      //   href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
      //   verCondiciones_Mobile:'n',//s/n
      //   contenidoBTN1_vitrina: 'Inscríbete aquí',
      //   textAlignBTN1_Desktop: 'left',   //left,center,right
      //   textAlignBTN1_Mobile: 'left',   //left,center,right
      //   hrefBTN2_vitrina: '',
      //   contenidoBTN2_vitrina: '',
      //   imagen_vitrina: 'banner-home-supermercados-ene.png',
      //   taggeo: 'banner-home-supermercados-ene'
      // },
      // // vitrinaSlide 2: primax
      // {
      //   contenidoPrimerTexto_vitrina: '\n\n',
      //   mobile_PrimerTexto_vitrina: '\n',
      //   contenidoSegundoTexto_vitrina: '<br><br><br>',
      //   mobile_SegundoTexto_vitrina: '<br><br>',
      //   hrefBTN1_vitrina: 'https://bancoripley.com.pe/formularios/index.html?plantilla=registro-primax',
      //   //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
      //   href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
      //   verCondiciones_Mobile:'n',//s/n
      //   contenidoBTN1_vitrina: 'Obtén el beneficio',
      //   textAlignBTN1_Desktop: 'right',   //left,center,right
      //   textAlignBTN1_Mobile: 'right',   //left,center,right
      //   hrefBTN2_vitrina: '',
      //   contenidoBTN2_vitrina: '',
      //   imagen_vitrina: 'banner-home-primax-ene.png',
      //   taggeo: 'banner-home-primax-ene'
      // },
      // vitrinaSlide 3: Super Canjes
      {        
        contenidoPrimerTexto_vitrina: '¡Empezó la Temporada Escolar con los Súper Canjes!',
        mobile_PrimerTexto_vitrina: '¡Empezó la Temporada Escolar con los Súper Canjes!',
        contenidoSegundoTexto_vitrina: '<br>Canjea tus Puntos Go en Tiendas Ripley por productos Escolares, Cómputo, Electro Menor, Pareos y mucho más.<br><br>',
        mobile_SegundoTexto_vitrina: 'Canjea tus Puntos Go en Tiendas Ripley por productos Escolares, Cómputo, Electro Menor, Pareos y mucho más.<br><br>',
        hrefBTN1_vitrina: 'https://firebasestorage.googleapis.com/v0/b/hbk-prd.appspot.com/o/RPGO%2FCATALOGOS%2FpdfCatalogoSC?alt=media',
        //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
        href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
        verCondiciones_Mobile:'n',//s/n
        contenidoBTN1_vitrina: 'Mira el catálogo',
        textAlignBTN1_Desktop: 'left',   //left,center,right
        textAlignBTN1_Mobile: 'left',   //left,center,right
        hrefBTN2_vitrina: '',
        contenidoBTN2_vitrina: '',
        imagen_vitrina: 'banner-home-supercanje-feb.png',
        taggeo: 'banner-home-supercanje-feb'
      },
      
      // vitrinaSlide 4: Experiencias
      {
        contenidoPrimerTexto_vitrina: '¡Aprovecha tus Puntos Go en San Valentín!<br>',
        mobile_PrimerTexto_vitrina: '¡Aprovecha tus Puntos Go en San Valentín!',
        contenidoSegundoTexto_vitrina: '<br>Canjea vales de consumo en Rosatel, el Pozito, Friday’s, La Nacional, Long Horn y más desde tu App Banco Ripley.<br><br>',
        mobile_SegundoTexto_vitrina: 'Canjea vales de consumo en Rosatel, el Pozito, Friday’s, La Nacional, Long Horn y más desde tu App Banco Ripley.<br><br>',
        hrefBTN1_vitrina: 'https://bancaenlinea.bancoripley.com.pe/home/ripley-points',
        //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
        href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
        verCondiciones_Mobile:'n',//s/n
        contenidoBTN1_vitrina: 'Canjea aquí',
        textAlignBTN1_Desktop: 'left',   //left,center,right
        textAlignBTN1_Mobile: 'left',   //left,center,right
        hrefBTN2_vitrina: '',
        contenidoBTN2_vitrina: '',
        imagen_vitrina: 'banner-home-experiencias-feb.png',
        taggeo: 'banner-home-experiencias-feb'
      },

      // vitrinaSlide 5: Gift Cards
      {
        contenidoPrimerTexto_vitrina: 'Canjea una Gift Card de S/30 por 2,000 Puntos Go<br>',
        mobile_PrimerTexto_vitrina: 'Canjea una Gift Card de S/30 por 2,000 Puntos Go<br>',
        contenidoSegundoTexto_vitrina: '<br>¡Solo en Febrero! Hazlo a un click desde tu App Banco Ripley y úsala en Tienda Ripley.<br><br>',
        mobile_SegundoTexto_vitrina: '<br>¡Solo en Febrero! Hazlo a un click desde tu App Banco Ripley y úsala en Tienda Ripley.<br><br>',
        hrefBTN1_vitrina: 'https://bancaenlinea.bancoripley.com.pe/home/ripley-points',
        //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
        href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
        verCondiciones_Mobile:'n',//s/n
        contenidoBTN1_vitrina: 'Canjea aquí',
        textAlignBTN1_Desktop: 'left',   //left,center,right
        textAlignBTN1_Mobile: 'left',   //left,center,right
        hrefBTN2_vitrina: '',
        contenidoBTN2_vitrina: '',
        imagen_vitrina: 'banner-home-giftcar-feb.png',
        taggeo: 'banner-home-giftcar-feb'
      },
      
      // vitrinaSlide 6: LATAM
      {
        contenidoPrimerTexto_vitrina: '¡Sigue acumulando Ripley Puntos Go por todas tus compras!',
        mobile_PrimerTexto_vitrina: '¡Sigue acumulando Ripley Puntos Go por todas tus compras!',
        contenidoSegundoTexto_vitrina: '<br>Y canjea Millas LATAM PASS desde 1,500 Puntos Go.<br><br>',
        mobile_SegundoTexto_vitrina: '<br>Y canjea Millas LATAM PASS desde 1,500 Puntos Go.<br><br>',
        hrefBTN1_vitrina: 'canje-latam-pass', 
        //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
        href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
        verCondiciones_Mobile:'n',//s/n
        contenidoBTN1_vitrina: 'Canjea aquí',
        textAlignBTN1_Desktop: 'left',   //left,center,right
        textAlignBTN1_Mobile: 'left',   //left,center,right
        hrefBTN2_vitrina: '',
        contenidoBTN2_vitrina: '',
        imagen_vitrina: 'banner-home-latam-feb.png',
        taggeo: 'banner-home-latam-feb'
      },
    ];

    this.sliderCanjesList = item_sliderCanjesList;

  }
}

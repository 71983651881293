import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SegmentationService } from 'src/app/services/segmentation/segmentation.service';
import { UtilsService } from 'src/app/services/utils/utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-prime',
  templateUrl: './client-prime.component.html',
  styleUrls: ['./client-prime.component.scss'],
})
export class ClientPrimeComponent implements OnInit {

  @Input() title: string;
  @Input() subtitle1: string;
  @Input() subtitle2: string;
  @Input() enlace: boolean;

  typeDoc = '01';
  isValid = true;
  mostrarPantallaCarga = false;

  enteredvalue = '';

  min_length_nroDocumento: number;
  max_length_nroDocumento: number;

  // @Input() identidadForm: FormGroup;
  identidadForm: FormGroup;

  public pathSegmentacion = 'segmentacion';
  CargandoOpen = false;
  constructor(
    // private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private segmentationService: SegmentationService,
    private utilsService: UtilsService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.identidadForm = this.formBuilder.group({
      // nroDocumentoPrestamo: [{ value: null, disabled: false }],
      nroDocumentoPrestamo: [{ value: null, disabled: false }, [Validators.required]],
    });

    this.max_length_nroDocumento = 8;

  }

  // get selectedMonth() { return this.identidadForm.controls.date; }
  get control() { return this.identidadForm.controls; }

  selectedTypeDoc(type) {
    this.typeDoc = type;
    this.isValid = true;

    // this.identidadForm.get('nroDocumentoPrestamo').clearValidators();
    this.identidadForm.get('nroDocumentoPrestamo').setValue('');
    if (type === '01') {
      this.identidadForm.get('nroDocumentoPrestamo').setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(8)]);
      this.min_length_nroDocumento = 5;
      this.max_length_nroDocumento = 8;
    } else {
      this.identidadForm.get('nroDocumentoPrestamo').setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(11)]);
      this.min_length_nroDocumento = 5;
      this.max_length_nroDocumento = 11;
    }

  }

  onChange() {
    if (this.isValid === false) {
      this.nroDocumentoValida();
    }
  }
  @HostListener('window:keydown', ['$event'])
  keyPressNumbers($event: KeyboardEvent) {
    const charCode = ($event.which) ? $event.which : $event.keyCode;
    if (($event.ctrlKey || $event.metaKey) && $event.keyCode === 86) {
      $event.preventDefault();
      return false;
    }
    // Only Numbers 0-9
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) {
      if ($event.ctrlKey && charCode === 67) {
        return true;
      } else {
        $event.preventDefault();
        return false;
      }
    } else {
      return true;
    }
  }
  OnlyNumeric(): boolean {
    if (Number(this.enteredvalue)) {
      // console.log('Its a numeric');
    } else {
      this.enteredvalue = '';
    }
    return false;
  }
  onCtrlV() {
    // console.log('ctrlV pressed')
  }

  onCtrlC() {
    // console.log('ctrlC pressed')
  }

  onConsultar() {

    const nroDocumentoValidate = this.nroDocumentoValida();
    if (!nroDocumentoValidate) {
      // marcacionErrorNroDocumento();
      this.CargandoOpen = false;
    }
    if (nroDocumentoValidate) {
      // grecaptcha.execute();

      // let RECAPTCHA_SITE_KEY = environment.RECAPTCHA_SITE_KEY;
      const token = '';
      this.CargandoOpen = true;
      this.consultarPointsClient(token);
    }
  }

  nroDocumentoValida() {
    this.isValid = true;
    const nroDocumento = this.identidadForm.get('nroDocumentoPrestamo').value;

    if (!(nroDocumento && nroDocumento !== '')) {
      this.isValid = false;
    } else {
      if (this.typeDoc === '01') {
        if (nroDocumento.length !== 8) {
          this.isValid = false;
        }
      } else {
        if (nroDocumento.length < 9 || nroDocumento.length > 11) {
          this.isValid = false;
        }
      }
    }
    return this.isValid;
  }

  consultarPointsClient(token) {
    const document = this.identidadForm.get('nroDocumentoPrestamo').value;
    const client = {
      typeDocument: this.typeDoc,
      numberDocument: document,
      flagClient: '1',
      captcha: token
    };

    localStorage.setItem('objData', '');
    this.pantallaDeCarga(true);

    this.getPointsClient(client);

  }
  pantallaDeCarga(booleano) {
    this.mostrarPantallaCarga = booleano;
    if (booleano) {
      // $(".app-opacity").show();
    } else {
      // $(".app-opacity").hide();+
    }
  }
  getPointsClient(client) {

    this.authService.getAuthentication0().subscribe(
      (response: any) => {
        const status = response.status;
        const bodyResponse = response.body;
        const statusOk = 200;
        switch (status) {
          case statusOk:
            const token = bodyResponse.access_token;
            //console.log(token);

            this.segmentationService.getSetmentantion(client, token)
              .then((data) => {
                //console.log('data');
                if (data.result != null) {
                  if (data.result[0] != null) {
                    if (data.result.length > 0) {
                      const list = data.result[0].data;
                      // for (let i = 0; i < list.length; i++)
                      for (const row of list) {
                        if (row.customFields.length > 0) {
                          // const listData = list[i].customFields;
                          const listData = row.customFields;
                          localStorage.setItem('objData', JSON.stringify(listData));
                          //console.log('caso 1');
                          this.ripleyPuntosAnalytics('Segmentacion_Ripleypuntos', 'Consulta', 'Consulta Exitosa');
                          // ripleyPuntosAnalytics.addEvent('Segmentacion_Ripleypuntos','Consulta','Consulta Exitosa',0);
                          this.RegistrarNumeroDocumentoConsultado(this.typeDoc, client.numberDocument, '01', token);
                          // this.router.navigateByUrl('segmentacion');
                          // this.document.location.href = '/' + this.pathSegmentacion;
                        }
                      }
                    } else {
                      //console.log('caso 2');
                      this.ripleyPuntosAnalytics('Segmentacion_Ripleypuntos', 'Consulta', 'Consulta No Exitosa');
                      // ripleyPuntosAnalytics.addEvent('Segmentacion_Ripleypuntos','Consulta','Consulta No Exitosa',0);
                      this.RegistrarNumeroDocumentoConsultado(this.typeDoc, client.numberDocument, '01', token);
                      // this.router.navigateByUrl('segmentacion');
                      // this.document.location.href = '/' + this.pathSegmentacion;
                    }
                  } else {
                    //console.log('caso 3');
                    this.ripleyPuntosAnalytics('Segmentacion_Ripleypuntos', 'Consulta', 'Consulta No Exitosa');
                    // ripleyPuntosAnalytics.addEvent('Segmentacion_Ripleypuntos','Consulta','Consulta No Exitosa',0);
                    this.RegistrarNumeroDocumentoConsultado(this.typeDoc, client.numberDocument, '01', token);
                    // this.router.navigateByUrl('segmentacion');
                    // this.document.location.href = '/' + this.pathSegmentacion;
                  }
                } else {
                  //console.log('caso 4');
                  this.ripleyPuntosAnalytics('Segmentacion_Ripleypuntos', 'Consulta', 'Consulta No Exitosa');
                  // ripleyPuntosAnalytics.addEvent('Segmentacion_Ripleypuntos','Consulta','Consulta No Exitosa',0);
                  this.RegistrarNumeroDocumentoConsultado(this.typeDoc, client.numberDocument, '01', token);
                  // this.router.navigateByUrl('segmentacion');
                  // this.document.location.href = '/' + this.pathSegmentacion;
                }
                this.pantallaDeCarga(false);
              })
              .catch(() => {
                this.CargandoOpen = false;
                console.log('catch getSetmentantion');
              });

            break;

          default:

            break;
        }

      },
      (error) => {
        // const errorBody = error.error;
        // const status = error.status;
        this.CargandoOpen = false;
      }
    );

  }

  ripleyPuntosAnalytics(event: string, description: string, action: string, additionalInformation?: any[]) {

    this.utilsService.addEventToTagManagerAndFirebase(event, description, action, additionalInformation);

  }

  RegistrarNumeroDocumentoConsultado(TypeDocument, document, TipoIdForm, token) {
    const _tipodocumento = (TypeDocument === '01' ? 'DNI' : 'CE');
    const _formid = (TipoIdForm === '01' ? 'ID_FORM_SEGMENTACION' : 'ID_FORM_RIPLEYPUNTOS');
    const trama = 'formId►' + _formid + '▲names►▲firstName►▲lastName►▲documentType►' + _tipodocumento + '▲documentNumber►' + document + '▲email►▲mobilePhone►▲promotionCode►▲purchaseNumber►▲turnBuy►▲businessName►▲dataTreatment►';

    const InscriptionFormCms = {
      trama: trama,
      captcha: '',
      sourcecaptcha: 'RP'
    };

    if (environment.ENV === 'SOLOQA') {
      console.log(environment.ENV);
      this.authService.getAuthenticationTokenCmsQ().subscribe(
        (response: any) => {
          const status = response.status;
          const bodyResponse = response.body;
          const statusOk = 200;
          switch (status) {
            case statusOk:
              const tokenCms = bodyResponse.access_token;

              this.segmentationService.RegisterInscripcionNroDocumentoFormCMS(InscriptionFormCms, token, tokenCms)
                .then((data) => {
                  //console.log(data);
                  if (data.result != null) {

                  } else {

                  }
                  this.document.location.href = '/' + this.pathSegmentacion;
                  this.pantallaDeCarga(false);
                })
                .catch(() => {
                  console.log('error al registrar documento');
                  this.CargandoOpen = false;
                });
              break;

            default:

              break;
          }

        },
        (error) => {
          // const errorBody = error.error;
          // const status = error.status;
          console.log("Error getAuthenticationTokenCmsQ");
          this.CargandoOpen = false;
        }
      );

    }
    else {
      this.segmentationService.RegisterInscripcionNroDocumentoFormCMS(InscriptionFormCms, token, "")
      .then((data) => {
        //console.log(data);
        if (data.result != null) {

        } else {

        }
        this.document.location.href = '/' + this.pathSegmentacion;
        this.pantallaDeCarga(false);
      })
      .catch(() => {
        console.log('error al registrar documento');
        this.CargandoOpen = false;
      });
    }

  }
}

// import '@firebase/messaging';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  DEFAULT_BROWSER_NOTIFICATION_PERMISSION,
  EXTERNAL_NOTIFICATION_TYPE,
  FIREBASE_SW_PATH,
  FIRESTORE_APP_TOKEN_KEY,
  FIRESTORE_PWA_TOKEN_KEY,
  GRANTED_BROWSER_NOTIFICATION_PERMISSION,
  INTERNAL_NOTIFICATION_TYPE,
  NOTIFICATIONS_APP_REDIRECTIONS,
} from 'src/app/common/constants/notifications.constants';
import { INotificationInformation, INotificationTapped } from 'src/app/common/interfaces/notifications.interface';
// import { environment } from '@environments/environment';
// import { firebase } from '@firebase/app';
import { Device } from '@ionic-native/device/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Platform } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils/utils';
import { BehaviorSubject, Subscription } from 'rxjs';


// import { FirebaseService } from '@services/firebase/firebase.service';
@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  public hasNewNotifications: BehaviorSubject<boolean>;
  public notificationList: BehaviorSubject<INotificationInformation[]>;
  private subscription: Subscription;
  private subscribedToNotifications: boolean;

  constructor(
    // public firebase: FirebaseService,
    public platform: Platform,
    public firebaseNative: FirebaseX,
    public utils: UtilsService,
    public device: Device,
    public http: HttpClient,
    public router: Router,
  ) {
    this.hasNewNotifications = new BehaviorSubject(false);
    this.notificationList = new BehaviorSubject([]);
    this.subscription = new Subscription();
    this.subscribedToNotifications = false;
  }

  public get isBrowserNotificationDefault(): boolean {
    return Notification.permission === DEFAULT_BROWSER_NOTIFICATION_PERMISSION;
  }

  public get isBrowserNotificationsSupported(): boolean {
    return !!(window as any).Notification;
  }

  public get isNative(): boolean {
    return this.platform.is('cordova');
  }

  // public async initializePWANotifications(): Promise<void> {
  //   if (firebase.messaging.isSupported()) {
  //     const firebaseServiceWorker = await navigator.serviceWorker.register(FIREBASE_SW_PATH);
  //     const messaging = firebase.messaging();
  //     messaging.useServiceWorker(firebaseServiceWorker);
  //     messaging.usePublicVapidKey(environment.FIREBASE_CONFIG.vapidKey);
  //   }
  // }

  // public async requestPermission(dType: string, dNumber: string): Promise<void> {
  //   try {
  //     if (this.isNative) {
  //       const token = await this.getNotificationTokenForNative();
  //       this.setNotificationTokenToAppUser(token, dType, dNumber);
  //     } else {
  //       if (this.isBrowserNotificationsSupported && this.isBrowserNotificationDefault) {
  //         const token = await this.getNotificationTokenForWeb();
  //         this.setNotificationTokenToPWAUser(token, dType, dNumber);
  //       }
  //     }
  //   } catch (err) {/**/}
  // }

  public async removeNotificationToken(dType: string, dNumber: string): Promise<void> {
    try {
      let platformKey = null;
      let token = null;
      if (this.isNative) {
        platformKey = FIRESTORE_APP_TOKEN_KEY;
        token = await this.getNotificationTokenForNative();
      } else {
        platformKey = FIRESTORE_PWA_TOKEN_KEY;
        token = await this.getNotificationTokenForWeb();
      }
      // if (token) { this.firebase.removeNotificationToken(dType, dNumber, token, platformKey); }
    } catch (err) {/**/ }
  }

  public removeNotificationSubscription() {
    this.subscription.unsubscribe();
    this.subscription = new Subscription();
    this.subscribedToNotifications = false;
  }

  public fetchNewNotifications(dType: string, dNumber: string) {
    if (!this.subscribedToNotifications) {
      this.subscribedToNotifications = true;
      // const notificationRef = this.firebase.fetchNewUserNotifications(dType, dNumber);
      // this.subscription.add(notificationRef.onSnapshot((userNotifications) => {
      //   const notificationList = [];
      //   userNotifications.forEach((doc) => {
      //     notificationList.push({ inboxMessageId: doc.id, ...doc.data() } as INotificationInformation);
      //   });
      //   notificationList.sort((firstNotification, secondNotification) =>
      //     secondNotification.sendDate.toDate() - firstNotification.sendDate.toDate()
      //   );
      //   this.updateUnreadNotificationStatus(notificationList);
      //   this.notificationList.next(notificationList);
      // }));
    }
  }

  // private updateUnreadNotificationStatus(notifications: INotificationInformation[]) {
  //   this.hasNewNotifications.next(notifications.some((notification) => notification.isRead === false));
  // }

  // public markNotificationAsRead(notification: INotificationInformation) {
  //   this.firebase.markNotificationAsRead(notification);
  // }

  // public deleteNotification(notification: INotificationInformation) {
  //   this.firebase.deleteNotification(notification);
  // }

  public listenToTappedNotifications() {
    this.firebaseNative.onMessageReceived().subscribe(
      (notification: INotificationTapped) => {
        if (notification.tap) { this.openTappedNotification(notification); }
      }
    );
  }


  public async grantPermission() {
    await this.firebaseNative.grantPermission();
  }

  private async getNotificationTokenForNative(): Promise<string> {
    let permission = await this.firebaseNative.hasPermission();
    if (!permission) {
      permission = await this.firebaseNative.grantPermission();
    }
    return permission ? this.firebaseNative.getToken() : null;
  }

  private openTappedNotification(notification: INotificationTapped) {
    const { redirectPath, redirectType } = notification;
    if (redirectPath) {
      if (redirectType === EXTERNAL_NOTIFICATION_TYPE) {
        this.utils.openRedirectPath(redirectPath);
      } else if (redirectType === INTERNAL_NOTIFICATION_TYPE) {
        const routePath = NOTIFICATIONS_APP_REDIRECTIONS[redirectPath] || 'home';
        this.router.navigateByUrl(routePath);
      }
    }
  }

  private async getNotificationTokenForWeb(): Promise<string> {
    const permission = await Notification.requestPermission();
    // return permission === GRANTED_BROWSER_NOTIFICATION_PERMISSION ? firebase.messaging().getToken() : null;
    return null;
  }

  // private async setNotificationTokenToAppUser(token: string, dType: string, dNumber: string): Promise<void> {
  //   if (token) {
  //     const [platformKey, extraPlatformKey] = [FIRESTORE_APP_TOKEN_KEY, FIRESTORE_PWA_TOKEN_KEY];
  //     const notificationData = this.createAppNotificationData(token);
  //     this.firebase.addNotificationToken(dType, dNumber, notificationData, platformKey, extraPlatformKey);
  //   }
  // }

  // private async setNotificationTokenToPWAUser(token: string, dType: string, dNumber: string): Promise<void> {
  //   if (token) {
  //     const [platformKey, extraPlatformKey] = [FIRESTORE_PWA_TOKEN_KEY, FIRESTORE_APP_TOKEN_KEY];
  //     const notificationData = this.createPWANotificationData(token);
  //     this.firebase.addNotificationToken(dType, dNumber, notificationData, platformKey, extraPlatformKey);
  //   }
  // }

  // private createPWANotificationData(token: string): IPWANotificationData {
  //   return {
  //     fcmToken: token,
  //     OS: UtilsService.OStype(),
  //     type: UtilsService.viewportLabel()
  //   };
  // }

  // private createAppNotificationData(token: string): IAppNotificationData {
  //   return {
  //     fcmToken: token,
  //     id: this.device.uuid,
  //     model: this.device.model,
  //     manufacturer: this.device.manufacturer
  //   };
  // }
}

import { Component, OnInit, ViewEncapsulation, ViewChild, NgZone, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ICustomerRequest, ICustomerResponse } from 'src/app/common/interfaces/customer.interface';
import { IProductResponse } from 'src/app/common/interfaces/product.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CanjeMillasService } from 'src/app/services/canjes/canje-millas.service';
import { IProductTransactionsResponse } from '@common/interfaces/productTransactions.interface';
import { UtilsService } from '@services/utils/utils';

@Component({
  selector: 'app-miscanjes-latam',
  templateUrl: './miscanjes-latam.component.html',
  styleUrls: ['./miscanjes-latam.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MiscanjesLatamComponent implements OnInit {

  public listProductTransactionsLatam: any[] = [];
  
  identidadForm: FormGroup;

  listPaquetes2 = [
    { index: 0, image: 'assets/img/svg/latam/Canje_Millas_01.svg', value: 1000, points: 4500 },
    { index: 1, image: 'assets/img/svg/latam/Canje_Millas_02.svg', value: 3000, points: 7000 },
    { index: 2, image: 'assets/img/svg/latam/Canje_Millas_03.svg', value: 9000, points: 20000 },
    { index: 3, image: 'assets/img/svg/latam/Canje_Millas_04.svg', value: 24000, points: 50000 },
    { index: 4, image: 'assets/img/svg/latam/Canje_Millas_05.svg', value: 50000, points: 100000 },
  ];

  customerData: ICustomerResponse;

  listPaquetesLatam: IProductResponse[];
  // listProductTransactionsLatam: IProductTransactionsResponse[];
  selectPaquete: IProductResponse;

  selectedTitle: string;
  termsAndConditionsPDF: string;
  haveEnoughPoints = false;
  pointMessage = '';

  progress: number = 0;
  label: string;

  pathCanjeLatamPassDNI = 'canje-latam-pass?type=miscanjes';
  pathConfirmaPaquete = 'confirma-paquete';
  showLoader = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _ngZone: NgZone,
    private authService: AuthService,
    private canjeMillasService: CanjeMillasService,
    private utils: UtilsService,
    @Inject(DOCUMENT) private document: Document
  ) {

  }

  ngOnInit() {
    this.createForm();
    if (history.state.data === undefined) {
      // // this.router.navigateByUrl(this.pathCanjeLatamPassDNI);
      this.document.location.href = '/' + this.pathCanjeLatamPassDNI;
      // const userId = "C-70037202";
      // this.getPaquetesMillas(userId);
    }
    else {
      this.customerData = history.state.data;
      // const userId = this.customerData.customer.userId;
      // this.getPaquetesMillas(userId);
    }
  }
  createForm() {
    this.identidadForm = this.formBuilder.group({
      codigoSMS: [{ value: null, disabled: false }, [Validators.required]],
    });
  }
  getPaquetesMillas(userId: string) {
    console.log('getPaquetesMillas');
    console.log(this.customerData);

    this.authService.getAuthentication0().subscribe(
      (response: any) => {
        // console.log(response);
        const status = response.status;
        const bodyResponse = response.body;
        const statusOk = 200;
        switch (status) {
          case statusOk:
            const token = bodyResponse.access_token;
            //console.log(token);

            this.canjeMillasService.getProductTransactionLatamList(token, userId)
              .then((dataResult) => {
                if (dataResult.length > 0) {
                  this.listProductTransactionsLatam = dataResult;
                  // this.listPaquetesLatam = dataResult;
                  console.log(this.listProductTransactionsLatam);
                } else {
                  // this.router.navigateByUrl('segmentacion');
                }
              })
              .catch(() => {
                console.log('catch');
              });

            break;

          default:

            break;
        }

      },
      (error) => {
        // const errorBody = error.error;
        // const status = error.status;
      }
    );
  }

  public async goToRedeem(product: any) {
    const sku = product.sku;
    const code = product.code;
    const category = product.category;
    const confirmation = false;
    const fullName=product.fullName;

    // console.log("goToRedeem: ", product);
    // this.router.navigate([`/${RIPLEY_POINTS_GIFTCARD_ROUTE}`, { sku, code, category,fullName }]);

  }

  public formatDate(date: Date) {
    date = new Date(date);
    return this.utils.formatDate(date, 'dd/MM/yyyy');
  }

  public getExpirationDate(myCode: any) {
    if (myCode.expirationDate) {
      const currentTimestamp = new Date().getTime();
      let fechaExpiracion = null;
      if (myCode.expirationDate.hasOwnProperty('seconds')) {
        fechaExpiracion = new Date(myCode.expirationDate.toDate());
      } else {
        fechaExpiracion = new Date(myCode.expirationDate);
      }
      let markAsUsed: boolean = myCode.markAsUsed != null ? myCode.markAsUsed : false;
      if (fechaExpiracion.getTime() < currentTimestamp) {
        return "Vencido";
      } else if (markAsUsed) {
        return "Utilizado";
      }
      else {
        const diferenciaMilisegundos = fechaExpiracion.getTime() - currentTimestamp;
        const milisegundosPorMes = 1000 * 60 * 60 * 24 * 30.44; // Promedio de días por mes
        const diferenciaMeses = Math.floor(diferenciaMilisegundos / milisegundosPorMes);
        if (diferenciaMeses == 1) {
          return "Vence en " + diferenciaMeses + " mes";
        } else {
          return "Vence en " + diferenciaMeses + " meses";
        }
      }
    }
    return "";
  }

  public getStatusRedeem(status: string) {
    if (status) {
      if (status == "completed") {
        return "Canjeado";
      }
      else if (status == "validating") {
        return "Validando";
      }
      else if (status == "refunded") {
        return "Reembolsado";
      }
      else if (status == "limit-exceeded") {
        return "Error Límite exedido";
      }
      else if (status == "error-service-redeem") {
        return "Error Canje";
      }
      else if (status == "error-service-points") {
        return "Error puntos";
      }
      else {
        return "Error";
      }
    }
    else {
      return "";
    }    
  }

  onTerminosCondiciones() {
    window.open(this.termsAndConditionsPDF, '_blank');
  }

  onConfirmar() {
    this.showLoader = true;
    const clientData = {
      processType: 'RPGO',
      imagen: this.selectPaquete.image,
      title: this.selectPaquete.selectedTitle,
      subtitle: this.selectPaquete.selectedSubtitle,
      latamcode: this.selectPaquete.selectedSubtitle,
      latamName: this.selectPaquete.selectedSubtitle,
      telefono: this.customerData.customer.mobileNumberMask,
      sessionId: this.customerData.customer.sessionId,
      userId: this.customerData.customer.userId,
      memberId: this.customerData.memberId,
      nOfPointsAvailable: this.customerData.points,
      packagePoints: this.selectPaquete.points,
      miles: this.selectPaquete.value,
      sku: this.selectPaquete.productId,
      productId: this.selectPaquete.productId,
      limit: this.selectPaquete.limit,
      origin: 'rpgo',
      documentType: this.customerData.customer.documentType,
      documentNumber: this.customerData.customer.documentNumber,
      backendID: this.selectPaquete.backendID.toString(),
      fullName: this.selectPaquete.fullName,
    };
    //console.log('onConfirmar');
    //console.log(clientData);
    this.router.navigateByUrl(this.pathConfirmaPaquete, { state: { ClientData: clientData } });
    this.showLoader = false;

    // // this.router.navigateByUrl(this.pathConfirmaPaquete);
    // this.authService.getAuthentication0().subscribe(
    //   (response: any) => {
    //     // console.log(response);
    //     const status = response.status;
    //     const bodyResponse = response.body;
    //     const statusOk = 200;
    //     switch (status) {
    //       case statusOk:
    //         // this.tokenService.setToken(bodyResponse.access_token);
    //         // this.localStorageService.set(AppConstants.Session.TOKEN_TYPE, bodyResponse.token_type);

    //         const token = bodyResponse.access_token;
    //         console.log(token);

    //         const client = {
    //           sessionId: this.customerData.customer.sessionId,
    //           processType: 'RPGO',
    //           captcha: ''
    //         };

    //         this.canjeMillasService.sendOTP(client, token)
    //           .then((dataResult) => {
    //             console.log('objDataSendOTP');
    //             console.log(dataResult);
    //             if (dataResult.code === 1) {
    //               this.showLoader = false;
    //               localStorage.setItem('objDataSendOTP', JSON.stringify(dataResult));

    //               const clientData = {
    //                 processType: 'RPGO',
    //                 imagen: this.selectPaquete.image,
    //                 title: this.selectPaquete.selectedTitle,
    //                 subtitle: this.selectPaquete.selectedSubtitle,
    //                 latamcode: this.selectPaquete.selectedSubtitle,
    //                 latamName: this.selectPaquete.selectedSubtitle,
    //                 telefono: this.customerData.customer.mobileNumberMask,
    //                 sessionId: this.customerData.customer.sessionId,
    //                 userId: this.customerData.customer.userId,
    //                 memberId: this.customerData.memberId,
    //                 nOfPointsAvailable: this.customerData.points,
    //                 packagePoints: this.selectPaquete.points,
    //                 miles: this.selectPaquete.value,
    //                 sku: this.selectPaquete.productId,
    //                 productId: this.selectPaquete.productId,
    //                 limit: this.selectPaquete.limit,
    //                 origin: 'rpgo',
    //                 documentType: this.customerData.customer.documentType,
    //                 documentNumber: this.customerData.customer.documentNumber,
    //                 backendID: this.selectPaquete.backendID.toString(),
    //                 fullName: this.selectPaquete.fullName,
    //               };

    //               this.router.navigateByUrl(this.pathConfirmaPaquete, { state: { DataOTP: dataResult, ClientData: clientData } });

    //             } else {
    //               // this.router.navigateByUrl('segmentacion');
    //             }
    //             // this.pantallaDeCarga(false);
    //           })
    //           .catch(() => {
    //             console.log('catch');
    //           });

    //         break;

    //       default:

    //         break;
    //     }

    //   },
    //   (error) => {
    //     // const errorBody = error.error;
    //     // const status = error.status;
    //   }
    // );
  }
  onCancelar() {
    // this.router.navigateByUrl(this.pathCanjeLatamPassDNI);
    this.showLoader = false;
    this.document.location.href = '/' + this.pathCanjeLatamPassDNI;
  }

}

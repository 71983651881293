import { Directive, HostListener, Input, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PeruvianCurrencyPipe } from 'src/app/pipes/peruvian-currency/peruvian-currency.pipe';
import { CurrencyPipe } from '@angular/common';

@Directive({
  selector: '[dynamicMoneyInput]'
})
export class DynamicMoneyInputDirective {

  @Input('dynamicMoneyInput') formGroup: FormGroup;
  element: HTMLInputElement;

  constructor(
    private el: ElementRef,
    private peruvianCurrency: PeruvianCurrencyPipe,
    private currencyPipe: CurrencyPipe,
  ) {
    this.element = this.el.nativeElement;
  }

  @HostListener('input', ['$event.target.value']) onChange(typedValue: string) {
    // tslint:disable-next-line: no-magic-numbers
    const numericValue = this.unformattCurrency(typedValue);

    if (numericValue) {
      this.amount.setValue( !isNaN(numericValue) ? numericValue : null);
      this.element.value = this.baseCurrency.value === 'PEN' ?
        this.peruvianCurrency.transform(numericValue, 2) : this.currencyPipe.transform(numericValue, '$', '$ ', '1.2-2');
    } else {
      this.element.value = this.baseCurrency.value === 'PEN' ? 'S/ 0.00' : '$ 0.00';

    }
  }

  @HostListener('blur', ['$event.target.value']) onBlur() {
    this.amount.markAsTouched();
  }

  private unformattCurrency(value: string): number {
    const clean = this.clearNumber(value);
    if (isNaN(clean)) { return 0; }
    // tslint:disable-next-line: no-magic-numbers
    return clean / 100;
  }

  private clearNumber(value: string): number {
    return Number(value.replace(/\D+/g, ''));
  }

  get amount() { return this.formGroup.controls.amount; }
  get baseCurrency() { return this.formGroup.controls.baseCurrency; }
}

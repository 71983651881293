import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';

import { AuthService } from 'src/app/services/auth/auth.service';
import { PromotionService } from 'src/app/services/promotion/promotion.service';

@Component({
  selector: 'app-promociones',
  templateUrl: './promociones.component.html',
  styleUrls: ['./promociones.component.scss'],
})
export class PromocionesComponent implements OnInit {

  public pathCanjes = 'canjes';

  utm_source = '';
  utm_medium = '';
  utm_campaign = '';

  typeDoc = '01';
  isValidDocumento = true;
  isValidCelular = true;
  isValidEmail = 0;
  mostrarPantallaCarga = false;
  min_length_nroDocumento: number;
  max_length_nroDocumento: number;

  identidadForm: FormGroup;

  public pathPromotions = 'promociones';
  RegisterOK = false;
  RegisterERROR = false;
  CargandoOpen = false;
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private promotionService: PromotionService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.RegisterOK = false;
    this.RegisterERROR = false;
    this.route.queryParams.subscribe(
      params => {
        this.utm_source = params['utm_source'];
        this.utm_medium = params['utm_medium'];
        this.utm_campaign = params['utm_campaign'];

      }
    );

    this.createForm();

  }
  createForm() {
    this.identidadForm = this.formBuilder.group({
      // txtdocument: [{ value: null, disabled: false }],
      typeDocument: [{ value: '01', disabled: false }, [Validators.required]],
      txtdocument: [{ value: null, disabled: false }, [Validators.required]],
      txtcelular: [{ value: null, disabled: false }, [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
      txtemail: [{ value: null, disabled: false }, [Validators.email]],
      datosPersonalesPromociones: [{ value: false, disabled: false }],
      fcampana: [{ value: '202004_Restaurantes_Triplica', disabled: false }],
      // txtdocument1: [{ value: null, disabled: false },[Validators.required]],
    });

    this.max_length_nroDocumento = 8;

  }

  // get selectedMonth() { return this.identidadForm.controls.date; }
  get control() { return this.identidadForm.controls; }

  selectedTypeDoc(type) {
    this.typeDoc = type;
    this.isValidDocumento = true;

    // this.identidadForm.get('txtdocument').clearValidators();
    this.identidadForm.get('txtdocument').setValue('');
    if (type === '01') {
      this.identidadForm.get('txtdocument').setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(8)]);
      this.min_length_nroDocumento = 5;
      this.max_length_nroDocumento = 8;
    } else {
      this.identidadForm.get('txtdocument').setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(11)]);
      this.min_length_nroDocumento = 5;
      this.max_length_nroDocumento = 11;
    }

    // let nroDocumento = this.identidadForm.get('txtdocument').value;
    // console.log(nroDocumento);
  }

  onChange() {
    if (this.isValidDocumento === false) {
      this.onValidaDocumento();
    }
  }
  keyPressNumbers(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  onValidaDocumento() {
    this.isValidDocumento = true;
    const nroDocumento = this.identidadForm.get('txtdocument').value;

    if (!(nroDocumento && nroDocumento !== '')) {
      this.isValidDocumento = false;
    } else {
      if (this.typeDoc === '01') {
        if (nroDocumento.length !== 8) {
          this.isValidDocumento = false;
        }
      } else {
        if (nroDocumento.length < 9 || nroDocumento.length > 11) {
          this.isValidDocumento = false;
        }
      }
    }
    return this.isValidDocumento;
  }
  onValidaCelular() {
    this.isValidCelular = true;
    const nroCelular = this.identidadForm.get('txtcelular').value;

    if (!(nroCelular && nroCelular !== '')) {
      this.isValidCelular = false;
    } else {
      if (nroCelular.length !== 9) {
        this.isValidCelular = false;
      }
    }
    return this.isValidCelular;
  }
  onValidaEmail() {
    this.isValidEmail = 0;
    const txtemail = this.identidadForm.get('txtemail').value;

    if (!(txtemail && txtemail !== '')) {
      this.isValidEmail = 1;
    } else {
      if (!this.validarCorreo(txtemail)) {
        this.isValidEmail = 2;
      }
    }
    return this.isValidEmail;
  }
  validarCorreo(correo) {
    const mailformat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return mailformat.test(correo);
  }
  validaTerminoYCondicionesOMP() {
    const isValid = true;

    //     let msg = "";
    //     let txtInput = $("#divRegistroPromociones #datosPersonalesPromociones");
    //     let inputMessage = $("#divRegistroPromociones #datosPersonalesPromociones-message");

    //     if (!(txtInput && txtInput[0] && txtInput[0].checked)){
    //         txtInput.addClass("is-invalid");
    //         msg = "Por favor, autoriza el tratamiento de datos.";
    //         isValid = false;
    //     }
    //     else{
    //         txtInput.removeClass("is-invalid");
    //     }
    //    if (msg){
    //         inputMessage.empty();
    //         inputMessage.html(msg);
    //     }
    return isValid;
  }

  onConsultar() {

    const validaciones = (this.onValidaDocumento() && this.onValidaCelular() &&
    this.onValidaEmail() === 0 && this.validaTerminoYCondicionesOMP());

    if (validaciones) {
      //console.log('grecaptcha;');
      // grecaptcha.execute();

      // let RECAPTCHA_SITE_KEY = environment.RECAPTCHA_SITE_KEY;
      this.CargandoOpen = true;
      const token = '';
      this.RegisterPromotion(token);
    }
  }
  RegisterPromotion(token) {

    let documento: string;
    let campana: string;
    let celular: string;
    let email: string;
    let checkTerminoYCondicionesPromociones: boolean;

    campana = this.identidadForm.get('fcampana').value;
    celular = this.identidadForm.get('txtcelular').value;
    email = this.identidadForm.get('txtemail').value;
    checkTerminoYCondicionesPromociones = this.identidadForm.get('datosPersonalesPromociones').value;
    let TerminoYCondiciones = 'OK';
    let tipoDocumento = 'DNI';
    if (!(checkTerminoYCondicionesPromociones && checkTerminoYCondicionesPromociones[0] &&
      checkTerminoYCondicionesPromociones[0].checked)) {
      TerminoYCondiciones = '';
    }

    documento = this.identidadForm.get('txtdocument').value;
    if (this.typeDoc === '01') {
      tipoDocumento = 'CE';
    } else {
      tipoDocumento = 'DNI';
    }
    const dataPromotion = {
      email: email,
      emailType: 'HTML',
      optedOutDate: '',
      optInDetails: '',
      leadSource: '',
      captcha: token,
      customFields: [
        {
          name: 'DOCUMENTO',
          value: documento
        },
        {
          name: 'TIPO_DOCUMENTO',
          value: tipoDocumento
        },
        {
          name: 'CELULAR',
          value: celular
        },
        {
          name: 'MARCA_PD',
          value: TerminoYCondiciones
        },
        {
          name: 'CAMPANA',
          value: campana
        }
      ]
    };
    localStorage.setItem('objDataPromotion', '');
    // localStorage.setItem("nombreRegistroPRO", "");
    this.pantallaDeCarga(true);

    this.authService.getAuthentication0().subscribe(
      (response: any) => {
        //console.log(response);
        const status = response.status;
        const bodyResponse = response.body;
        switch (status) {
          case 200:
            // this.tokenService.setToken(bodyResponse.access_token);
            const token = bodyResponse.access_token;
            //console.log(token);

            this.promotionService.postRegisterPromociones(dataPromotion, token)
              .then((data) => {
                //console.log('postRegisterPromociones');
                //console.log(data);
                this.CargandoOpen = false;
                if (data.status === 200) {
                  // ripleyPuntosAnalytics.addEvent('Registro Promocion', 'Registrar', 'Enviar', 0);
                  this.RegisterOK = true;
                  this.RegisterERROR = false;
                  localStorage.setItem('objDataPromotion', JSON.stringify(data));
                }
                // this.router.navigateByUrl('promotion');
                //this.document.location.href = '/' + this.pathPromotions;
                this.pantallaDeCarga(false);
              })
              .catch(() => {
                //console.log('catch');
                this.CargandoOpen = false;
                this.RegisterOK = false;
                this.RegisterERROR = true;
                this.pantallaDeCarga(false);
              });

            break;

          default:

            break;
        }

      },
      (error) => {
        // const errorBody = error.error;
        // const status = error.status;
        this.CargandoOpen = false;
        this.RegisterOK = false;
        this.RegisterERROR = true;
      }
    );

  }

  pantallaDeCarga(booleano) {
    this.mostrarPantallaCarga = booleano;
    if (booleano) {
      // $(".app-opacity").show();
    } else {
      // $(".app-opacity").hide();+
    }
  }

  onCanjes() {
    // this.router.navigateByUrl('canjes');
    this.document.location.href = '/' + this.pathCanjes;
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comoacumular-puntoscategoria',
  templateUrl: './comoacumular-puntoscategoria.component.html',
  styleUrls: ['./comoacumular-puntoscategoria.component.scss'],
})
export class ComoacumularPuntoscategoriaComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}

// tslint:disable:max-line-length
export const TOKEN_KEYBOARD_RESPONSE = {
  success: [
    {
      id: 777,
      keys: [
        {
          hashCode: 'test',
          numericalValue: 9,
          image: 'data:image/png;base64,test',
        },
        {
          hashCode: 'test',
          numericalValue: 7,
          image: 'data:image/png;base64,test',
        },
        {
          hashCode: 'test',
          numericalValue: 2,
          image: 'data:image/png;base64,test',
        },
        {
          hashCode: 'test',
          numericalValue: 4,
          image: 'data:image/png;base64,test',
        },
        {
          hashCode: 'test',
          numericalValue: 8,
          image: 'data:image/png;base64,test',
        },
        {
          hashCode: 'test',
          numericalValue: 3,
          image: 'data:image/png;base64,test',
        },
        {
          hashCode: 'test',
          numericalValue: 5,
          image: 'data:image/png;base64,test',
        },
        {
          hashCode: 'test',
          numericalValue: 1,
          image: 'data:image/png;base64,test',
        },
        {
          hashCode: 'test',
          numericalValue: 0,
          image: 'data:image/png;base64,test',
        },
        {
          hashCode: 'test',
          numericalValue: 6,
          image: 'data:image/png;base64,test',
        },
      ],
    },
  ],
  failures: [],
};

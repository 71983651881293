import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONTACT_DETAIL_MOCK } from 'src/app/common/mocks/account.mocks';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/services/http/http.service';
import { IRpgoCatalogResponse } from 'src/app/common/interfaces/rpgoCatalog.interface';
@Injectable({
  providedIn: 'root'
})
export class CmsService {
  constructor(
    private http: HttpService
  ) { }
  public getCatalog(idCatalog:string, token: string): Promise<IRpgoCatalogResponse> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json;')
      .set('Authorization', `Bearer ${token}`);
      
    const url = environment.BASE_BACKEND + '/latamwebapi/customer/getCatalog?catalogId='+idCatalog;    
    return this.http.get<IRpgoCatalogResponse>(url, CONTACT_DETAIL_MOCK, headers).toPromise();
  } 
    
}
import { Observable, Subscription } from 'rxjs';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ALREADY_ENROLLED_PROPS, CONTACT_WITH_CALL_CENTER_MODAL_PROPS, NOT_RIPLEY_USER_PROPS
} from 'src/app/common/constants/modal-props.constants';
import {
  ENROLL_CHECK_FOR_CARD, ENROLL_FORGOT_PASS, ENROLL_GO_RIPLEY, ENROLL_LOGIN
} from 'src/app/common/constants/tag/tag-enrollment.constants';
import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils/utils';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
})
export class GenericModalComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() message: string;
  @Input() primaryButtonText: string;
  @Input() secondaryButtonText: string;
  @Input() icon: string;
  @Input() dynamicSource: Observable<string>;
  @Input() extraClass?: string;
  subscription: Subscription;
  fadeOut: boolean;
  isActiveModal: boolean;
  hide: boolean;
  constructor(
    private viewController: ModalController,
    private utilsService: UtilsService,
  ) {
    this.title =  '';
    this.message = '';
    this.primaryButtonText = '';
    this.secondaryButtonText = '';
    this.icon = 'i-smartphone-purple';
  }

  ngOnInit() {
    this.subscription = new Subscription();
    if (this.dynamicSource) {
      this.subscription.add(
        this.dynamicSource.subscribe((message) => {
          this.message = message;
        })
      );
    }
  }

  dismissModal() {
    this.viewController.dismiss();
    const second = 1000;
    this.fadeOut = true;
    setTimeout(() => {
      this.hide = true;
    }, second);
    this.isActiveModal = false;
  }

  onPrimaryClick() {
    this.tag();
    this.viewController.dismiss('primaryButtonPressed');
  }

  onSecondaryClick() {
    this.viewController.dismiss('secondaryButtonPressed');
  }

  tag() {
      switch (this.primaryButtonText) {
        case CONTACT_WITH_CALL_CENTER_MODAL_PROPS.primaryButtonText:
          const informAditional: any[] = [];
          informAditional[0] = ENROLL_GO_RIPLEY.info1;
          informAditional[1] = ENROLL_GO_RIPLEY.info2;
          this.utilsService.addEventToTagManagerAndFirebase(
            ENROLL_GO_RIPLEY.event,
            ENROLL_GO_RIPLEY.variable,
            ENROLL_GO_RIPLEY.action,
            informAditional
          );
          break;
        case NOT_RIPLEY_USER_PROPS.primaryButtonText:
          this.utilsService.addEventToTagManagerAndFirebase(
            ENROLL_CHECK_FOR_CARD.event,
            ENROLL_CHECK_FOR_CARD.variable,
            ENROLL_CHECK_FOR_CARD.action
          );
          break;
        case ALREADY_ENROLLED_PROPS.primaryButtonText:
          this.utilsService.addEventToTagManagerAndFirebase(ENROLL_LOGIN.event, ENROLL_LOGIN.variable, ENROLL_LOGIN.action);
          break;
      }
  }
  tagSecond() {
      switch (this.secondaryButtonText) {
        case ALREADY_ENROLLED_PROPS.secondaryButtonText:
          this.utilsService.addEventToTagManagerAndFirebase(
            ENROLL_FORGOT_PASS.event,
            ENROLL_FORGOT_PASS.variable,
            ENROLL_FORGOT_PASS.action
          );
          break;
      }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-vitrina-slider-canjepromociones',
  templateUrl: './vitrina-slider-canjepromociones.component.html',
  styleUrls: ['./vitrina-slider-canjepromociones.component.scss'],
})
export class VitrinaSliderCanjepromocionesComponent implements OnInit {
  @ViewChild('mySliderCanjes') slides: IonSlides;
  mySlideOptions = {
    //autoplay: true,
    autoplay: {
      delay: 5000,
    },
    initialSlide: 0,
    slideShadows: true,
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
    spaceBetween: 0,
    speed: 1100,
    pager: true,
    paginationType: 'fraction',
    effect: 'slide',
    dir: 'rtl',
    //delay: 10000,
    slideinterval: 10000,
  };
  barra_nav_slider: boolean;
  numero_slides: string;
  rutaIMG: string;
  XS: string;
  heightVitrina: string;
  heightImgVitrina: string;
  widthImgVitrina: string;
  maxWidthVitrina: string;
  maxWidthImg: string;
  sliderCanjesList: any = [];


  constructor() { }

  ngOnInit() {
    this.inicializarVariables();
    this.getListaCanjesSlider();
    // this.slides.startAutoplay();
  }
  inicializarVariables() {
    this.numero_slides = '4';
    this.rutaIMG = 'assets/img/canjes_promociones/slider/';
    this.XS = 'xs-';
    this.heightVitrina = '480px';
    this.heightImgVitrina = '100%';
    this.widthImgVitrina = '100%';
    this.maxWidthVitrina = '1190px';
    this.maxWidthImg = '1190px';

  }
  slidePrev() {
    this.slides.slidePrev();
    this.slides.startAutoplay();
  }
  slideNext() {
    this.slides.slideNext();
    this.slides.startAutoplay();
  }

  getListaCanjesSlider() {
    const item_sliderCanjesList = [

      // vitrinaSlide 1: Super Canjes
      {
        contenidoPrimerTexto_vitrina: '¡Ya llega la Navidad! Decora tu hogar con las mejores categorías',
        mobile_PrimerTexto_vitrina: '¡Ya llega la Navidad! Decora tu hogar con las mejores categorías',
        contenidoSegundoTexto_vitrina: "<br>Canjea tus Puntos Go en Tiendas Ripley por productos de Navidad, Cama, Baño, menaje y mucho más.<br><br>",
        mobile_SegundoTexto_vitrina: "<br>Canjea tus Puntos Go en Tiendas Ripley por productos de Navidad, Cama, Baño, menaje y mucho más.<br><br>",
        hrefBTN1_vitrina: 'https://firebasestorage.googleapis.com/v0/b/hbk-prd.appspot.com/o/RPGO%2FCATALOGOS%2FpdfCatalogoSC?alt=media',
        //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
        href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
        verCondiciones_Mobile:'n',//s/n
        contenidoBTN1_vitrina: 'Mira el catálogo',
        hrefBTN2_vitrina: '',
        contenidoBTN2_vitrina: '',
        imagen_vitrina: 'banner-home-supercanje-nov.png',
        taggeo: 'banner-home-supercanje-nov'
      },      

      // vitrinaSlide 2: Experiencias Rebajadas
      {
        contenidoPrimerTexto_vitrina: '¡Adelanta la Navidad con los mejores canjes de experiencias!',
        mobile_PrimerTexto_vitrina: '¡Adelanta la Navidad con los mejores canjes de experiencias!',
        contenidoSegundoTexto_vitrina: '<br>Canjea vales para fast food, entretenimiento, regalos y más desde tu App Banco Ripley.<br><br>',
        mobile_SegundoTexto_vitrina: '<br>Canjea vales para fast food, entretenimiento, regalos y más desde tu App Banco Ripley.<br><br>',
        hrefBTN1_vitrina: 'https://bancaenlinea.bancoripley.com.pe/home/ripley-points', 
        //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
        href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
        verCondiciones_Mobile:'n',//s/n                         
        contenidoBTN1_vitrina: 'Canjea aquí',
        hrefBTN2_vitrina: '',
        contenidoBTN2_vitrina: '',
        imagen_vitrina: 'banner-home-experiencias-nov.png',
        taggeo: 'banner-home-experiencias-nov'
      },
      // vitrinaSlide 3: Gift Cards
      {
        contenidoPrimerTexto_vitrina: '¡Canjea una Gift Card y úsala en Tienda Ripley y la Ripley App!',
        mobile_PrimerTexto_vitrina: '¡Canjea una Gift Card y úsala en Tienda Ripley y la Ripley App!',
        contenidoSegundoTexto_vitrina: '<br>Hazlo desde tu App Banco Ripley y a partir de 3,000 Ripley Puntos Go.<br><br>',
        mobile_SegundoTexto_vitrina: '<br>Hazlo desde tu App Banco Ripley y a partir de 3,000 Ripley Puntos Go.<br><br>',
        hrefBTN1_vitrina: 'https://bancaenlinea.bancoripley.com.pe/home/ripley-points',
        //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
        href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
        verCondiciones_Mobile:'n',//s/n
        contenidoBTN1_vitrina: 'Canjea aquí',
        hrefBTN2_vitrina: '',
        contenidoBTN2_vitrina: '',
        imagen_vitrina: 'banner-home-giftcar-nov.png',
        taggeo: 'banner-home-giftcar-nov'
      },
      // // // vitrinaSlide 4: LATAM
      // {
      //   contenidoPrimerTexto_vitrina: '¡Del 28 de Octubre al 03 de Noviembre!',
      //   mobile_PrimerTexto_vitrina: '¡Del 28 de Octubre al 03 de Noviembre!',
      //   contenidoSegundoTexto_vitrina: '<br>Canjea tus Puntos Go con 40% dscto. por millas LATAM PASS.<br><br>',
      //   mobile_SegundoTexto_vitrina: '<br>Canjea tus Puntos Go con 40% dscto. por millas LATAM PASS.<br><br>',
      //   hrefBTN1_vitrina: 'canje-latam-pass', 
      //   //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
      //   href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
      //   verCondiciones_Mobile:'n',//s/n
      //   contenidoBTN1_vitrina: 'Quiero canjear',
      //   hrefBTN2_vitrina: '',
      //   contenidoBTN2_vitrina: '',
      //   imagen_vitrina: 'banner-home-latam-oct28.png',
      //   taggeo: 'banner-home-latam-oct28'
      // },
      // // vitrinaSlide 4: Acumulación
      // {
      //   contenidoPrimerTexto_vitrina: '¡Multiplica X4 tus Puntos Go en la categoría Viajes!',
      //   mobile_PrimerTexto_vitrina: '¡Multiplica X4 tus Puntos Go en la categoría Viajes!',
      //   contenidoSegundoTexto_vitrina: '<br>Realiza tus compras con tu TC Ripley en las categorías: Agencia de viajes, Aerolíneas, Hoteles y Compras presenciales en el exterior.<br><br>',
      //   mobile_SegundoTexto_vitrina: '<br>Realiza tus compras con tu TC Ripley en las categorías: Agencia de viajes, Aerolíneas, Hoteles y Compras presenciales en el exterior.<br><br>',
      //   hrefBTN1_vitrina: 'https://bancoripley.com.pe/formularios/index.html?plantilla=ripleypuntos-acumulacion-viajes', 
      //   //hrefBTN1_vitrina: 'canjes?category=supercanjes&banner=s',  //ver condiciones desktop
      //   href_VerCondiciones_Mobile: 'appModalCondicionesExperiencias',
      //   verCondiciones_Mobile:'n',//s/n
      //   contenidoBTN1_vitrina: 'Inscríbete aquí',
      //   hrefBTN2_vitrina: '',
      //   contenidoBTN2_vitrina: '',
      //   imagen_vitrina: 'banner-home-acumulacion-viajes-set.png',
      //   taggeo: 'banner-home-acumulacion-viajes-set'
      // }, 
    ];

    this.sliderCanjesList = item_sliderCanjesList;

  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-vitrina',
  templateUrl: './vitrina.component.html',
  styleUrls: ['./vitrina.component.scss'],
})
export class VitrinaComponent implements OnInit {
  //textoBanner = '¡Descuento de 60% para\nclientes Gold, 50% para\nclientes Silver y Plus!';
  @ViewChild('mySlider') slides: IonSlides;
  mySlideOptions = {
    //autoplay: true,
    autoplay: {
      delay: 5000,
    },
    initialSlide: 0,
    slideShadows: true,
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
    spaceBetween: 0,
    speed: 1100,
    pager: true,
    paginationType: 'fraction',
    effect: 'slide',
    dir: 'rtl',
    //delay: 10000,
    slideinterval: 10000,
  };
  rutaIMG = 'assets/img/categorias/slider/';  
  XS = 'xs-';
  sliderCategoriaList: any = [];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.getListCategoriaSlider();
    this.activatedRoute.fragment.subscribe(res => {
      if (res != null) {
        this.JumpTo(res);
      }
    });
  }

  JumpTo(section) {
    const timeout = 500;
    setTimeout(() => {
      // document.getElementById(section).scrollIntoView({behavior: 'smooth'});
      document.getElementById(section).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      // document.getElementById(section).focus();
      // const element = document.getElementById(section);
      // const offset = 45;
      // const bodyRect = document.body.getBoundingClientRect().top;
      // const elementRect = element.getBoundingClientRect().top;
      // const elementPosition = elementRect - bodyRect;
      // const offsetPosition = elementPosition - offset;
      // console.log(bodyRect);
      // console.log(elementRect);
      // window.scrollTo({
      //   top: offsetPosition,
      //   behavior: 'smooth'
      // });

    }, timeout);
  }

  onSectionBene() {
    window.location.hash = '';
    window.location.hash = "BeneficiosCategorias";

  }
  getListCategoriaSlider(){
    const item_sliderCategoriaList = [
      
      // vitrinaSlide 1: Despacho Gratis y Go
      {
        titulo : 'En el mes del CYBER, ¡aprovecha tus envíos GRATIS!',
        mobile_titulo : 'En el mes del CYBER, ¡aprovecha tus envíos GRATIS!',
        subTitulo:'<br>Usa tus cupones de envío gratis al mes por ser cliente Gold o Silver. Exclusivo con Tarjeta de Crédito Banco Ripley Mastercard.<br><br>',
        mobile_subTitulo:'<br>Usa tus cupones de envío gratis al mes por ser cliente Gold o Silver. Exclusivo con Tarjeta de Crédito Banco Ripley Mastercard.<br><br>',
        hrefBanner: 'https://simple.ripley.com.pe',
        contenido_boton: 'Úsalos aquí',
        imagen_vitrina: 'banner-categorias-despacho-nov.png',
        taggeo:'banner-categorias-despacho-nov'
      },
      
      // vitrinaSlide 2: Cumpleaños
      {
        titulo : '¡Disfruta del descuento especial que tenemos para ti en Tiendas Ripley!',
        mobile_titulo : '¡Disfruta del descuento especial que tenemos para ti en Tiendas Ripley!',
        subTitulo:'<br>Exclusivo en vestuario, calzado y deporte con Tarjeta de Crédito Banco Ripley.<br><br>',
        mobile_subTitulo:'<br>Exclusivo en vestuario, calzado y deporte con Tarjeta de Crédito Banco Ripley.<br><br>', 
        hrefBanner: 'clientes-prime',
        contenido_boton: 'Quiero ver más',
        imagen_vitrina: 'banner-categorias-cumpleanios-nov.png',
        taggeo:'banner-categorias-cumpleanios-nov'
      },    

      // vitrinaSlide 3: Restofans
      {
        titulo : '\n\n',
        mobile_titulo : '\n\n\n\n',
        subTitulo:'<br>\n\n\n\n<br><br>',
        mobile_subTitulo:'<br>\n\n\n\n<br><br>', 
        hrefBanner: 'https://www.bancoripley.com.pe/promociones/default.html',
        contenido_boton: 'Quiero ver más',
        imagen_vitrina: 'banner-categorias-restofans-nov.png',
        taggeo:'banner-categorias-restofans-nov'
      },

      // vitrinaSlide 4: Acum. Supermercados
      {
        titulo: '¡Llévate hasta 5,000 Puntos Go por tus compras en el Supermercado!',
        mobile_titulo: '¡Llévate hasta 5,000 Puntos Go por tus compras en el Supermercado!',
        subTitulo: '<br>Por cada compra desde S/150 cada sábado del mes llévate 1,000 Puntos Go con tu Tarjeta de Crédito Banco Ripley Mastercard.<br><br>',
        mobile_subTitulo: '<br>Por cada compra desde S/150 cada sábado del mes llévate 1,000 Puntos Go con tu Tarjeta de Crédito Banco Ripley Mastercard.<br><br>',
        hrefBanner: 'https://bancoripley.com.pe/formularios/index.html?plantilla=compra-en-supermercados',
        contenido_boton: 'Inscríbete aquí',
        imagen_vitrina: 'banner-home-acumulacion-nov.png',
        taggeo: 'banner-home-acumulacion-nov'
      },
    ]

    this.sliderCategoriaList = item_sliderCategoriaList;  
  }
  slidePrev() {
    this.slides.slidePrev();
    this.slides.startAutoplay();
  }
  slideNext() {
    this.slides.slideNext();
    this.slides.startAutoplay();
  }  

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-afiliacion-preguntas',
  templateUrl: './afiliacion-preguntas.component.html',
  styleUrls: ['./afiliacion-preguntas.component.scss'],
})
export class AfiliacionPreguntasComponent implements OnInit {


  btn1:boolean=false;
  btn2:boolean=false;
  btn3:boolean=false;
  btn4:boolean=false;


  constructor() { }

  ngOnInit() {}



  mostrar(valor:string){
    if(valor){
      switch(valor){
        case '1':

          this.btn1=this.btn1 ? false : true;
          this.btn2=false;
          this.btn3=false;
          this.btn4=false;


          this.ScrollIntoView("#preg-frec");

        break;

        case '2':

          this.btn1=false;
          this.btn2=this.btn2 ? false : true;
          this.btn3=false;
          this.btn4=false;


          this.ScrollIntoView("#btn-acor1PS-1");

        break;

        case '3':


          this.btn1=false;
          this.btn2=false;
          this.btn3=this.btn3 ? false : true;
          this.btn4=false;


          this.ScrollIntoView("#btn-acor1PS-2");

        break;

        case '4':


        
          this.btn1=false;
          this.btn2=false;
          this.btn3=false;
          this.btn4=this.btn4 ? false : true;


          this.ScrollIntoView("#btn-acor1PS-3");

          break;

        default:
                  
          this.btn1=false;
          this.btn2=false;
          this.btn3=false;
          this.btn4=false;

        break;
      }
    }
  };


  ScrollIntoView(elem: string) {
    console.log(elem);
    setTimeout(() => {
    document.querySelector(elem).scrollIntoView({ behavior: 'smooth', block: 'start',inline:'center' });
    }, 300);

  };

}
